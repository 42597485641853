import getApiUrl from "services/getApiUrl";

export  const automationAPI = {
    getAll: async (params) => getApiUrl.get('/automations/',{params}),

    getOne: async (id) =>  getApiUrl.get(`/automations/${id}`),

    deleteOne: async (id) =>  getApiUrl.delete(`/automations/${id}`),

    updateOne: async (id, data) =>  getApiUrl.patch(`/automations/${id}`, data),

    getWebhookDataModel: async (id) =>  getApiUrl.get(`/automations/${id}/webhook/scheme`),

    saveTrigger: async (id,config,type) =>  getApiUrl.post(`/automations/${id}/trigger/save`,{config,type}),

    saveActions: async (id,actions) =>  getApiUrl.post(`/automations/${id}/actions/save`,{actions})
};
