import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ConfigProvider,
  Layout,
  PageHeader,
  Row,
  Col,
  Select,
  Empty,
  Button,
  Tag,
  Tooltip,
  DatePicker,
  Input,
  Divider,
  Spin,
} from "antd";
import { ProTable } from "@ant-design/pro-components";
import {
  QuestionCircleOutlined,
  ClearOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";
import datePickerLocale from "antd/lib/locale/pt_BR";
import { CSVLink } from "react-csv";
import ptBRIntl from "antd/lib/locale/pt_BR";

/**
 * Actions
 */
import {
  allCategoriesObj,
  allCategoriesOfObj,
  allFieldsByName,
  allFieldsByNames,
  allFormsOfSavedObjects,
  allFormsOfSavedObjectsWithFields,
  allFormsOfSavedObjectsWithFieldsSearch,
  savedDataInObj,
} from "auth/actions/objActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import "reactjs-popup/dist/index.css";
import {useSelector} from "react-redux";

/**
 * Misc
 */
const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

function FormSearchResults() {
  const location = useLocation();
  const mounted = useRef(true);

  const [alert, setAlert] = useState(false);
  const [current, setCurrent] = useState([]);
  const [old, setOld] = useState([]);

  const [form, setForm] = useState([]);
  const [forms, setForms] = useState([]);
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [columns, setColumns] = useState([]);

  const [searchBy, setSearchBy] = useState("");
  const [searchIn, setSearchIn] = useState("");
  const [hideSearchBy, setHideSearchBy] = useState(true);

  const company = useSelector((state) => state.user.company);

  /**
   * Obtém todos dados salvos
   * @param {*} param0
   * @returns
   */
  const get = async ({ forms }) => {
    return savedDataInObj({ forms }).then((items) => {
      var flattens = _.uniq(_.flatten(items.message)),
        keys = [];
      // organiza os nomes dos campos
      _.mapKeys(_.uniq(_.flatten(items.message)), (value, key) => {
        keys.push(Object.keys(value));
      });
      // organiza e combina os campos
      const mergeObjs = (data) => {
        return data
          .reduce((result, item) => {
            // pega o campo _id e adiciona um novo objeto
            if (result.indexOf(item._id) < 0) {
              result.push(item._id);
            }
            return result;
          }, [])
          .reduce((result, _id) => {
            // pegue os dados com o mesmo _id e adicione um novo campo
            const children = data.filter((item) => item._id === _id);
            result = result.concat(
              children.map((item, index) => {
                const newObj = [];
                _.mapKeys(item, (value, key) => {
                  if (
                    value &&
                    value.length > 0 &&
                    Array.isArray(value) &&
                    value[0].hasOwnProperty("value")
                  ) {
                    // simples
                    newObj.push({
                      title: key,
                      dataIndex: key,
                      key: key,
                    });
                  } else {
                    if (
                      !value.hasOwnProperty("value") &&
                      typeof value !== "string"
                    ) {
                      newObj.push({
                        title: key,
                        dataIndex: key,
                        key: key,
                      });
                    } else {
                      if (typeof value === "string") {
                        newObj.push({
                          title: key,
                          dataIndex: key,
                          key: key,
                        });
                      } else {
                        // data e tempo
                        newObj.push({
                          title: key,
                          dataIndex: key,
                          key: key,
                        });
                        //console.log(key, value)
                      }
                    }
                  }
                });
                return newObj;
              })
            );
            return result;
          }, []);
      };
      // prepara as colunas da tabela
      var mergeds = mergeObjs(flattens),
        all = _.uniqWith(_.uniq(_.flatten(mergeds)), _.isEqual).map((x) => {
          switch (x.title) {
            case "_id":
              return {
                title: (
                  <>
                    ID
                    <Tooltip
                      placement="top"
                      title="Identificação do registro salvo"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </>
                ),
                dataIndex: "_id",
                key: "_id",
              };
            case "createdAt":
              return {
                title: (
                  <>
                    Criado em
                    <Tooltip placement="top" title="Data do registro salvo">
                      <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </>
                ),
                dataIndex: "createdAt",
                key: "createdAt",
                sorter: (a, b) =>
                  moment(a.createdAt, "DD/MM/YYYY HH:mm:ss").unix() -
                  moment(b.createdAt, "DD/MM/YYYY HH:mm:ss").unix(),
              };
            case "updatedAt":
              return {
                title: (
                  <>
                    Atualizado em
                    <Tooltip
                      placement="top"
                      title="Data do registro atualizado"
                    >
                      <QuestionCircleOutlined style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </>
                ),
                dataIndex: "updatedAt",
                key: "updatedAt",
                sorter: (a, b) =>
                  moment(a.updatedAt, "DD/MM/YYYY HH:mm:ss").unix() -
                  moment(b.updatedAt, "DD/MM/YYYY HH:mm:ss").unix(),
              };
            default:
              return {
                title: x.title,
                dataIndex: x.title,
                key: x.title,
                children: x.hasOwnProperty("children") ? x.children : [],
              };
          }
        });
      var aFields = _.flattenDeep(items.objects), nColumns = all,
        sColumns = new Array();
      // tenta obter todos os campos
      if (aFields) {
        const dField = _.filter(nColumns, (o) => _.includes(["_id"], o.key)),
          iField = _.filter(nColumns, (o) =>
            _.includes(["createdAt", "updatedAt"], o.key)
          );
        sColumns = nColumns
          .filter(
            (y) => !_.includes(["_id", "createdAt", "updatedAt"], y.key)
          )
          .map((y) => {
            var field = aFields.filter((cl) => cl.field === y.key);
            if (field.length > 0) {
              switch (field[0].fieldtype) {
                case "Text":
                  return {
                    ...y,
                    sorter: (a, b) =>
                      a[field[0].field].length - b[field[0].field].length,
                  };
                case "Numeric":
                  return {
                    ...y,
                    sorter: (a, b) => a[field[0].field] - b[field[0].field],
                  };
                case "Date":
                  return {
                    ...y,
                    sorter: (a, b) =>
                      moment(a[field[0].field], "DD/MM/YYYY").unix() -
                      moment(b[field[0].field], "DD/MM/YYYY").unix(),
                  };
                case "Time":
                  return {
                    ...y,
                    sorter: (a, b) =>
                      moment(a[field[0].field], "HH:mm").unix() -
                      moment(b[field[0].field], "HH:mm").unix(),
                  };
                default:
                  if (
                    field[0].fieldtype !== "Text" &&
                    field[0].fieldtype !== "Numeric" &&
                    field[0].fieldtype !== "Date" &&
                    field[0].fieldtype !== "Time"
                  ) {
                    return {
                      ...y,
                    };
                  }
              }
            }
            return y;
          });
        // adiciona campos no inicio e final
        sColumns = sColumns.concat(iField);
        sColumns.unshift(...dField);
        // atualiza colunas
        setColumns(sColumns);
      }
      const tableKeys = all.map((t) => t.title),
        output = [];
      // prepara os dados da tabela
      flattens.forEach((obj) => {
        const data = [];
        Object.keys(obj)
          .filter((key) => tableKeys.includes(key))
          .forEach((key) => {
            if (
              obj[key] &&
              obj[key].length > 0 &&
              Array.isArray(obj[key]) &&
              obj[key][0].hasOwnProperty("value")
            ) {
              // simples
              data.push({ [key]: obj[key][0].value });
            } else {
              if (
                !obj[key].hasOwnProperty("value") &&
                typeof obj[key] !== "string"
              ) {
                data.push({ [key]: _.toString(obj[key]) });
              } else {
                // data e tempo
                var date = moment(obj[key].value, "DD/MM/YYYY", true).isValid(),
                  time = moment(obj[key].value, "HH:mm", true).isValid();
                if (date) {
                  data.push({
                    [key]: obj[key].value,
                  });
                }
                if (time) {
                  data.push({ [key]: obj[key].value });
                }
              }
            }
          });
        // adicione o id e atualize os objetos coletados
        output.push({
          _id: obj._id,
          ...data.reduce((map, elem) => ({ ...map, ...elem }), {}),
          createdAt: obj.createdAt[0].value,
          updatedAt: obj.updatedAt ? obj.updatedAt[0].value : [{}],
        });
      });
      // retorna os dados da tabela
      return output;
    });
  };

  useEffect(() => {
    if (forms.length === 0 && categories.length === 0) {
      /**
       * Pesquisa em dados salvos
       * @param {*} location.state.typed
       */
      allFormsOfSavedObjects(null,company.unitId).then((items) => {
        setForms(items.message);
        // Tenta obter os dados salvos
        get({
          forms: items.message.map((x) => `obj_${x.name}`),
        }).then((a) => {
          if (a !== undefined) {
            var results = a.filter((x) =>
              _.values(x).some((b) =>
                _.toLower(b).includes(_.toLower(location.state.typed))
              )
            );
            setCurrent(results);
            setOld(results);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms, categories, category]);

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        if (mounted.current) {
          setAlert(false);
        }
      }, 1000);
    }
  }, [alert]);

  useEffect(() => {
    if (columns.length > 0) {
      /**
       * Todas categorias
       */
      allCategoriesObj({ companyId: company.companyId,
        unitId: company.unitId}).then((items) => {
        setCategories(items.message);
      });
      /**
       * Formularios detalhado
       */
      allFormsOfSavedObjectsWithFields().then((items) => {
        columns.forEach((x) => {
          return items.message.forEach((h) => {
            if (h.fields.length > 0) {
              // match entre categorias e colunas
              var all = _.find(h.fields, { field: x.title });
              if (all) {
                // seta categorias selecionadas
                tryGetCategories(h.name);
                // seta forms selecionadas
                setForm(h.name);
              }
            }
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  function tryGetCategories(name) {
    allCategoriesOfObj({
      formName: name,
    }).then((values) => setCategory(values.message));
  }

  /**
   * Barra de pesquisa para tabela simples
   * @param {*} query
   */
  function onSearch(query) {
    setSearchBy(query);
    // procura campo do objeto baseado na escolha
    var filtered = current.filter((a) => {
      var b = a[searchIn];
      return b !== undefined ? b.includes(query) : null;
    });
    setCurrent(filtered);
  }
  function onSearchClear() {
    // limpa campo e desabilita campo
    setHideSearchBy(true);
    setSearchBy("");
    // limpa e atualiza a tabela
    setCurrent(old);
  }

  /**
   * Filtra por data e horas
   * @param {*} value
   * @param {*} dateString
   */
  function filterByDate(value, dateString) {
    // console.log("Selected Time: ", value);
    // console.log("Formatted Selected Time: ", dateString);
    var startDate = dateString[0];
    var endDate = dateString[1];
    // filtra os resultados
    var result = current.filter((a) => {
      var date = moment(a.createdAt, "DD/MM/YYYY HH:mm:ss").unix();
      return (
        date >= moment(startDate, "DD/MM/YYYY HH:mm:ss").unix() &&
        date <= moment(endDate, "DD/MM/YYYY HH:mm:ss").unix()
      );
    });
    if (value !== null) {
      setCurrent(result);
    } else {
      setCurrent(old);
    }
  }

  /**
   * Filtra por formulário
   * @param {*} selecteds
   */
  function filterByForms(selecteds) {
    get({
      forms: selecteds,
    }).then((a) => {
      if (a !== undefined) {
        var results = a.filter((x) =>
            _.values(x).some((b) =>
              _.toLower(b).includes(_.toLower(location.state.typed))
            )
          ),
          cleaned = _.uniqWith(results, _.isEqual);
        setCurrent(cleaned);
        setOld(cleaned);
      }
    });
  }

  /**
   * Filtra por categoria
   * @param {*} selecteds
   */
  function filterByCategory(selecteds) {
    allFormsOfSavedObjectsWithFieldsSearch(selecteds).then((items) => {
      var filtered = _.uniq(_.flatten(items.message.map((x) => x.forms))).map(
        (y) => y.id
      );
      // tenta obter dados salvos
      get({
        forms: filtered,
      }).then((a) => {
        if (a !== undefined) {
          var results = a.filter((x) =>
              _.values(x).some((b) =>
                _.toLower(b).includes(_.toLower(location.state.typed))
              )
            ),
            cleaned = _.uniqWith(results, _.isEqual);
          setCurrent(cleaned);
          setOld(cleaned);
        }
      });
    });
  }

  /**
   * Categoria do formulário
   */
  const categoryRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={
          categories.find((x) => x._id === value).color !== undefined
            ? categories.find((x) => x._id === value).color.hex
            : "#03A9F4"
        }
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label !== undefined ? label : "#03A9F4"}
      </Tag>
    );
  };

  return (
    <Layout>
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 53px 53px", height: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0px 5px", paddingLeft: 0 }}
              title="Resultados da pesquisa"
            />
            <Row
              gutter={32}
              style={{
                padding: "24px 15px 24px 15px",
                margin: 0,
                background: "white",
              }}
            >
              <Col span={6}>
                {forms.length > 0 &&
                category.length > 0 &&
                category.length > 0 ? (
                  <Fragment>
                    <div style={{ padding: "0 0 8px" }}>
                      <span>Formulários</span>
                    </div>
                    <Select
                      mode="multiple"
                      style={{
                        width: "100%",
                      }}
                      options={forms?.map((form) => ({
                        value: `obj_${form.name}`,
                        label: form.name,
                      }))}
                      defaultValue={form}
                      onChange={(ids) => {
                        filterByForms(ids);
                      }}
                      maxTagCount="responsive"
                    />
                    <div style={{ padding: "16px 0 8px" }}>
                      <span>Categorias</span>
                    </div>
                    <Select
                      mode="multiple"
                      style={{
                        width: "100%",
                      }}
                      options={categories?.map((category) => ({
                        value: category._id,
                        label: category.name,
                      }))}
                      onChange={(ids) => {
                        filterByCategory(ids);
                      }}
                      defaultValue={category?.map((a) => a._id.toString())}
                      tagRender={categoryRender}
                      maxTagCount="responsive"
                    />
                  </Fragment>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px 0px 4px",
                    }}
                  >
                    <Spin tip="Carregando formulários, categorias..." />
                  </div>
                )}

                <Divider />
                <div style={{ padding: "0 0 8px" }}>
                  <span>Pesquisar na tabela</span>
                </div>
                <Input
                  value={searchBy}
                  disabled={hideSearchBy}
                  onChange={(e) => onSearch(e.target.value)}
                  suffix={[
                    <ClearOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                        marginRight: 5,
                      }}
                      onClick={() => onSearchClear()}
                      disabled={hideSearchBy}
                    />,
                  ]}
                />
                <div style={{ padding: "16px 0 8px" }}>
                  <span>Campos da tabela</span>
                </div>
                <Select
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setSearchIn(value);
                    setHideSearchBy(false);
                  }}
                  notFoundContent="Nenhum campo adicionado."
                  dropdownMatchSelectWidth={300}
                  suffixIcon={[
                    <Tooltip title="Escolha a coluna para qual deseja procurar por registros">
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>,
                  ]}
                >
                  <OptGroup label="Padrões">
                    {columns.map((column) =>
                      !column.hasOwnProperty("children") ? (
                        <Option
                          value={column.dataIndex}
                          key={Math.random()
                            .toString(36)
                            .replace(/[^a-z]+/g, "")
                            .substr(2, 10)}
                        >
                          {column.title}
                        </Option>
                      ) : null
                    )}
                  </OptGroup>
                  <OptGroup label="Diversos">
                    {columns.map((column) =>
                      column.hasOwnProperty("children") &&
                      column.children.length === 0 ? (
                        <Option
                          value={column.dataIndex}
                          key={Math.random()
                            .toString(36)
                            .replace(/[^a-z]+/g, "")
                            .substr(2, 10)}
                        >
                          {column.title}
                        </Option>
                      ) : null
                    )}
                  </OptGroup>
                  <OptGroup label="Parentes">
                    {columns.map((column) =>
                      column.hasOwnProperty("children")
                        ? column.children.map((a) => (
                            <Option
                              value={a.dataIndex}
                              key={Math.random()
                                .toString(36)
                                .replace(/[^a-z]+/g, "")
                                .substr(2, 10)}
                            >
                              {a.title}
                            </Option>
                          ))
                        : null
                    )}
                  </OptGroup>
                </Select>
                <div style={{ padding: "16px 0 8px" }}>
                  <span>Data da tabela</span>
                </div>
                <ConfigProvider locale={datePickerLocale}>
                  <RangePicker
                    ranges={{
                      Hoje: [moment(), moment()],
                      "Este mês": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    format="DD/MM/YYYY HH:mm:ss"
                    onChange={filterByDate}
                    showTime
                  />
                </ConfigProvider>
                <div style={{ height: 13 }} />
              </Col>
              <Col span={18}>
                <ConfigProvider locale={ptBRIntl}>
                  <ProTable
                    headerTitle={`${current.length} ${
                      current.length === 0 ? "registro" : "registros"
                    } ${current.length === 0 ? "encontrado" : "encontrados"}`}
                    tooltip="Resultados encontrado baseado em pesquisa"
                    cardBordered
                    columns={columns}
                    dataSource={current}
                    rowKey="key"
                    search={false}
                    dateFormatter={"number"}
                    scroll={{ x: true }}
                    style={{ padding: 0 }}
                    // para cada nova protable um localStorage diferente!
                    columnsState={{
                      persistenceKey: "clm-pro-table-search-results",
                      persistenceType: "localStorage",
                    }}
                    options={{
                      reload: false,
                    }}
                    toolbar={{
                      actions: [
                        <Button key="primary">
                          <CSVLink
                            filename={`${new Date()}-resultados-pesquisa.csv`}
                            data={current}
                            onClick={() => {}}
                          >
                            Exportar CSV
                          </CSVLink>
                        </Button>,
                      ],
                    }}
                    locale={{
                      emptyText: (
                        <Empty description="Nenhum registro adicionado." />
                      ),
                      triggerDesc: "Ordem decrescente",
                      triggerAsc: "Ordem crescente",
                    }}
                  />
                </ConfigProvider>
              </Col>
            </Row>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

export default FormSearchResults;
