import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ProCard} from "@ant-design/pro-components";
import {Alert, Button, Drawer, message, Modal, Select, Spin, Table} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {userApi as api} from "../../services/actions/users";
import {PermissionConfig} from "../PermissionConfig";

export const RoleDetails = ({roleId, isOpen, closeDrawer}) => {
    const {company} = useSelector((state) => state.user);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [openModalAddUserToRole, setOpenModalAddUserToRole] = useState(false);
    const [userToAdd, setUserToAdd] = useState(null);
    const [listUserToAdd, setListUserToAdd] = useState([]);

    const fetchRoleDetails = useCallback(async () => {
        if (!roleId) return;
        try {
            setLoading(true);
            const {data} = await api.getRoleDetails(roleId,company.unitId);
            setData(data);
            setError(null); // Define erro explicitamente como `null` apenas em sucesso.
        } catch (err) {
            setError(err?.message || "Erro ao carregar detalhes do papel.");
        } finally {
            setLoading(false);
        }
    }, [roleId]);

    const fetchPermissions =  async () =>{
        if (!roleId) return;
        try {
            const {data} = await api.getRoleDetails(roleId,company.unitId);
            setData(data);
        } catch (err) {
            setError(err?.message || "Erro ao carregar detalhes do papel.");
        }
    }
    const [tab, setTab] = useState('description');

    useEffect(() => {
        fetchRoleDetails();
    }, [fetchRoleDetails]);


    const handleConfirmRemoveUserFromRole = (firstName, userId) => {
        if (!data?.role) return;
        Modal.confirm({
            title: "Remover Usuário",
            icon: <ExclamationCircleOutlined/>,
            content: (
                <p>
                    Tem certeza que deseja remover o usuário <b>{firstName}</b> do papel{" "}
                    <b>{data.role.name}</b>?
                </p>
            ),
            onOk: async () => {
                try {
                    const response = await api.removeUserFromRole(userId, data.role.id, null, company.unitId);
                    if (response.status === 201) {
                        fetchRoleDetails();
                    }
                } catch {
                    message.error("Erro ao remover usuário do papel.");
                }
            },
        });
    };

    const handleAddUserToRole = async () => {
        if (!company) return;
        try {
            const response = await api.getUsersByUnit(company.unitId, company.companyId);
            const dataUsers = response.data;
            if (dataUsers) {
                const currentUserIds = data.users.map((user) => user.id);
                const availableUsers = dataUsers
                    .filter((user) => !currentUserIds.includes(user.id))
                    .map((user) => ({
                        label: `${user.firstName} ${user.lastName} (${user.email})`,
                        value: user.id,
                    }));
                setListUserToAdd(availableUsers);
                setUserToAdd(null);
                setOpenModalAddUserToRole(true);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleOkAddUserToRole = async () => {
        if (!userToAdd) return message.info("Selecione um usuário.");
        try {
            setLoading(true);
            await api.addUserToRole(userToAdd, roleId, null,company.unitId);
            setUserToAdd(null);
            setListUserToAdd([]);
            setOpenModalAddUserToRole(false);
            fetchRoleDetails();
        } catch (err) {
            message.error(err.message || "Erro ao adicionar usuário ao papel.");
        } finally {
            setLoading(false);
        }
    };

    const handleCancelAddUserToRole = () => {
        setListUserToAdd([]);
        setUserToAdd(null);
        setOpenModalAddUserToRole(false);
    };

    const userColumns = [
        {
            title: "Nome",
            dataIndex: "name",
            render: (dom, record) => `${record.firstName} ${record.lastName}`,
        },
        {
            title: "E-mail",
            dataIndex: "email",
        },
        {
            title: "Remover",
            render: (dom, record) => (
                <Button
                    icon={<DeleteOutlined/>}
                    type="danger"
                    onClick={() => handleConfirmRemoveUserFromRole(record.firstName, record.id)}
                >
                    Remover
                </Button>
            ),
        },
    ];

    const renderContent = () => {
        if (loading) return <Spin tip="Carregando..."/>;
        if (error) return <Alert message={error} type="error" showIcon/>;
        if (!data?.role) return <Alert message="Papel não encontrado." type="warning" showIcon/>;
        return (
            <ProCard tabs={{
                tabPosition: "top", type: "card", activeKey: tab, onChange: (key) => {setTab(key)},
            }}>
                <ProCard.TabPane tab="Descrição" key="description">
                    {data.role.description || "Sem descrição disponível."}
                </ProCard.TabPane>
                <ProCard.TabPane tab="Usuários" key="users">
                    <Button type="primary" style={{width: "100%"}} onClick={handleAddUserToRole}>
                        Adicionar Usuário
                    </Button>
                    <Table dataSource={data.users} columns={userColumns} rowKey="id"/>
                </ProCard.TabPane>
                <ProCard.TabPane tab="Permissões" key="permissions">
                    <PermissionConfig type="role" id={roleId} assignedPermissions={data.permissions}
                                      reload={fetchPermissions}/>
                </ProCard.TabPane>
            </ProCard>
        );
    };

    return (
        <>
            <Modal
                title={`Adicionar usuário ao papel: ${data?.role?.name || ""}`}
                visible={openModalAddUserToRole}
                onOk={handleOkAddUserToRole}
                onCancel={handleCancelAddUserToRole}
                okText="Adicionar"
                cancelText="Cancelar"
                confirmLoading={loading}
            >
                <Select
                    value={userToAdd}
                    onChange={(value) => setUserToAdd(value)}
                    showSearch
                    placeholder="Selecione um usuário"
                    style={{width: "100%"}}
                    options={listUserToAdd}
                />
            </Modal>
            <Drawer
                destroyOnClose
                title={data?.role?.name || "Detalhes do Papel"}
                placement="right"
                visible={isOpen}
                width={'70%'}
                onClose={closeDrawer}
            >
                {renderContent()}
            </Drawer>
        </>
    );
};
