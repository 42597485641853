import { useSelector } from "react-redux";
import { hasAnyPermission } from "../../utils/permission";
import { Redirect } from "react-router-dom"; // Usando Redirect para versões anteriores
import React from "react";

const PermissionWrapper = ({ permissions, children, ...rest }) => {

    const permissionsFromUser = useSelector((state) => state.user.permissions);
    const company = useSelector((state) => state.user.company);

    if (hasAnyPermission(permissionsFromUser, permissions, company.unitId)) {
        return children;
    } else {
        return <Redirect to="/403" />;
    }
};

export default PermissionWrapper;
