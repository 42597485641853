import React from "react";
import {connect, useSelector} from "react-redux";
import {
    Layout,
    PageHeader,
    Breadcrumb,
    Button,
    ConfigProvider,
    Avatar,
    Card,
    Tooltip, notification, Dropdown,
} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {FileSyncOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import ptBRIntl from "antd/lib/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import {allActivitiesForms} from "auth/actions/activitieActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import {LayoutTopSide} from "components/NavBar/styles";

/**
 * Utils
 */
import {getAutomations} from "../../../auth/actions/automationsActions";
import PermissionWrapperComponent from "../../../components/PermissionWrapperComponent";

function AllAutomations({user}) {
    const history = useHistory();

    const routes = [
        {
            breadcrumbName: "Automações",
            href: "/automacoes",
        },
        {
            breadcrumbName: "todas",
        },
    ];

    const company = useSelector((state) => state.user.company);


    const columns = [
        {
            sorter: true,
            title: "Nome",
            dataIndex: "name",
            key: "name",
            render: (dom, record, index, action, schema) => {
                const text = record[schema.dataIndex];
                if (text) {
                    return (
                        <Button
                            type="link"
                            style={{
                                color: "#12b398",
                            }}
                            onClick={() => history.push(`/automacoes/editar/${record.id}`)}
                        >
                            {text.replace("obj_", "")}
                        </Button>
                    );
                }

            },
            width: "auto",
            ellipsis: true,
            valueType: "text",
            fieldProps: {
                placeholder: "",
            },
        },
        {
            sorter: true,
            title: "Descriçao",
            dataIndex: "description",
            key: "description",
            valueType: "text",
            fieldProps: {
                placeholder: "",
            },
            render: (dom, record, index, action, schema) => {
                const text = record[schema.dataIndex];
                if (text) {
                    return (<Tooltip placement="bottom" title={text}>
                        <Button>{text.substring(0,35) + "..."}</Button>
                    </Tooltip>)
                }

            },
            width: "auto",
            ellipsis: true,
            search: false
        },
        {
            sorter: true,
            title: "Status",
            dataIndex: "active",
            key: "active",
            valueEnum: {
                null: "Todos",
                true: "Ativo",
                false: "Inativo"
            },
            valueType: "select",
            fieldProps: {
                placeholder: "",
            },
            width: "auto",
            ellipsis: true,
        },
        {
            sorter: true,
            title: "Criado em",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
                }
            },
            width: "auto",
            ellipsis: true,
            search: false
        },
        {
            sorter: true,
            title: "Atualizado em",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
                }
            },
            width: "auto",
            ellipsis: true,
            search: false
        }

    ];


    return (
        <Layout>
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout
                        style={{
                            padding: "0 24px 24px",
                            minHeight: "100vh",
                        }}
                    >
                        <PageHeader
                            style={{margin: "16px 0 5px 0"}}
                            title="Lista de Automações"
                            onBack={() => window.history.back()}
                            breadcrumb={
                                <Breadcrumb>
                                    {routes.map((a) =>
                                        a.hasOwnProperty("href") ? (
                                            <Breadcrumb.Item key={Math.random()}>
                                                <a href onClick={() => history.push(a.href)}>
                                                    {a.breadcrumbName}
                                                </a>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                                        )
                                    )}
                                </Breadcrumb>
                            }
                        />
                        <Card>
                            <ConfigProvider locale={ptBRIntl}>
                                <ProTable
                                    rowKey="_id"
                                    type="table"
                                    request={async (params = {}, sorter, filter) => {
                                        let {active = null, name} = params;
                                        const data = {
                                            unitId: company.unitId
                                        };
                                        active = JSON.parse(active);
                                        if (active !== null) {
                                            data['active'] = Number(active);
                                        }

                                        if (name) {
                                            data['name'] = name;
                                        }

                                        const sortEntries = Object.entries(sorter);
                                        if(Object.entries(sortEntries).length > 0) {
                                            const [field, value] = sortEntries[0];
                                            data["orderByField"] = field;
                                            data["orderBySort"] = value.replace("end","");
                                        }


                                        const response = await getAutomations({
                                            ...data,
                                            page: params.current,
                                            perPage: params.pageSize
                                        });

                                        return {
                                            data: response.data,
                                            success: true,
                                            total: response.total,
                                        };
                                    }}
                                    columns={columns}
                                    dateFormatter={"number"}
                                    scroll={{x: true}}
                                    style={{padding: 0}}
                                    toolbar={{
                                        actions: [
                                            <PermissionWrapperComponent permissions={['CREATE_AUTOMATION']}>
                                                <Button type="primary"
                                                        onClick={() =>
                                                            history.push(`/automacoes/nova`)
                                                        } >
                                                    Adicionar
                                                </Button>
                                            </PermissionWrapperComponent>
                                        ],
                                    }}
                                />
                            </ConfigProvider>
                        </Card>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}

const mapStateToProps = ({session}) => ({
    user: session.user,
});

export default connect(mapStateToProps)(AllAutomations);
