import {ProCard, ProTable} from "@ant-design/pro-components";
import ptBRIntl from "antd/lib/locale/pt_BR";
import {Button, ConfigProvider, Spin, Alert, Modal, Drawer, Select,Tooltip } from "antd";
import React, {useEffect, useState} from "react";
import {userApi as api, userApi} from "../../../services/actions/users";
import {ExclamationCircleOutlined, DeleteOutlined, PlusCircleOutlined,LockOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

export function Unit({user}) {

    const {company} = useSelector(state => state.user);
    const [units, setUnits] = useState([]);
    const [unit, setUnit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [openModalAddUnitToUser, setOpenModalAddUnitToUser] = useState(false);
    const [listUnitsSelected, setListUnitsSelected] = useState(null);
    const [unitSelected, setUnitSelected] = useState(null);
    const [loadUnits, setLoadUnits] = useState(false);
    const [loadAddUnits, setLoadAddUnits] = useState(false);

    const fetchUnitsByUser = async () => {
        if(user && user.uuid){
            try {
                setError(null);
                setLoading(true);
                const response = await userApi.getUnitsFromUser(user.uuid);
                setUnits(response.data);
            } catch (error) {
                setError(error.message || "Erro ao carregar unidades");
            } finally {
                setLoading(false);
            }
        }
    };



    useEffect(() => {
        fetchUnitsByUser();
    }, [user])

    const columns = [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Descrição",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Remover Usuário",
            render: ({id, name}) => {
                const button = <Button  icon={units.length === 1 ? <LockOutlined /> : <DeleteOutlined/>} onClick={() => handleConfirmRemoveUserFromUnit(id, name)}>Remover</Button>;
                const item = units.length === 1 ?  <Tooltip title={"É necessário uma unidade pelo menos"}>{button}</Tooltip> : button;
                return item;
            },
        },
    ];


    const handleConfirmRemoveUserFromUnit = (unitId, unitName) => {
        if(units.length === 1){
            return Modal.warning({title: "Atenção", content: "Não é possível remover a última unidade do usuário. Ele precisa estár atrelado a uma unidade pelo menos"});
        }else{
            Modal.confirm({
                title: "Remover usuário da unidade?",
                icon: <ExclamationCircleOutlined/>,
                content: <p>Tem certeza que deseja remover este usuário <b>{user.firstName}</b> da unidade <b>{unitName}</b>?
                </p>,
                onOk: async () => {
                    try {
                        setError(null);
                        setLoading(true);
                        const response = await userApi.removeUserFromUnit(user.uuid, unitId,company.unitId);
                        if (response.status === 201) {
                            fetchUnitsByUser()
                        }
                    } catch (ex) {
                        setError(ex.message);
                    } finally {
                        setLoading(false);
                    }
                },
            });
        }

    }

    const  handleOkAddUserToUnit = async ()  => {
        try {
            setLoadAddUnits(true)
            const unitToAdd = unitSelected;
            if (unitToAdd) {
                const response = await userApi.addUserToUnit(user.uuid, unitToAdd, company.unitId);
                if(response && response.status === 201) {
                    await fetchUnitsByUser()
                }
            }
        }catch (e){
            setError(e.message);
        }finally {
            setLoadAddUnits(false)
            setOpenModalAddUnitToUser(false);
            setUnitSelected(null)
        }
    }

    const  handleCancelAddUserToUnit = ()  => {
        setOpenModalAddUnitToUser(false);
        setUnitSelected(null)
    }

    async function openModalToAddUserToUnit() {
        if(company){
            try {
                setLoadUnits(true);
                const response = await api.getUnitsAvailableByCompany(company.companyId,false);
                const {data} = response;
                if(data){
                    const idUnits = units.map(item => item.id);
                    const list = data.filter(item => {
                        return !idUnits.includes(item.id);
                    }).map(item =>{
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    })
                    setListUnitsSelected(list);
                    setOpenModalAddUnitToUser(true)
                }

            }catch (e) {
                console.log(e)
            }finally {
                setLoadUnits(false)
            }
        }


    }

    return (
        <ConfigProvider locale={ptBRIntl}>
            <Modal
                centered={true}
                title={`Adicionando ${user.firstName} a uma unidade`}
                visible={openModalAddUnitToUser}
                onOk={handleOkAddUserToUnit}
                onCancel={handleCancelAddUserToUnit}
                okText="Finalizar"
                cancelText="Cancelar"
                confirmLoading={loadAddUnits}
            >
                <Select
                    value={unitSelected}
                    onChange={(value) => setUnitSelected(value)}
                    showSearch={true}
                    placeholder={"Selecione uma unidade"}
                    style={{ width: '100%' }}
                    options={listUnitsSelected}
                />
                <br />
            </Modal>

            <ProCard extra={
                <Button loading={loadUnits} disabled={loadUnits} icon={<PlusCircleOutlined/>} onClick={() => openModalToAddUserToUnit()}> Adicionar
                    Unidade</Button>
            }>
                {loading ? (
                    <Spin tip="Carregando...">
                        <div style={{minHeight: "200px"}}></div>
                    </Spin>
                ) : null}

                {!loading && error ? (
                    <Alert message={error} type="error" showIcon/>
                ) : null}
                <ProTable
                    loading={loading}
                    search={false}
                    columns={columns}
                    dataSource={units}
                    rowKey="id"
                    pagination={{pageSize: 5}}
                />
            </ProCard>
        </ConfigProvider>
    );
}
