import getApiUrl from "services/getApiUrl";

export  const reportAPI = {
  getUserData : async () => getApiUrl.get("/reports/metabase/user/current"),

  getMyReports: async () => getApiUrl.get('/reports/metabase/dashboard', { params: {f : "mine"} }),

  getSharedReports: async () => getApiUrl.get('/reports/metabase/dashboard', { params: {f : "all"} }),

  getReportById: async (id) => getApiUrl.get(`/reports/metabase/dashboard/${id}`),

  createReport: async (data) => getApiUrl.post('/reports/metabase/dashboard', data),

  getEmbedReport: async (id) => getApiUrl.get(`/reports/forms/${id}/embed/`),

  getEmbedDefaultReport: async () => getApiUrl.get(`/reports/forms/default/embed/`),
};


