import { UserManager, WebStorageStateStore } from 'oidc-client';

const userManagerConfig = {
    authority: `${process.env.REACT_APP_URL_AUTH}/auth/realms/clm`,
    client_id: "clm-client",
    redirect_uri: `${process.env.REACT_APP_URL}/login/callback`,
    response_type: "code",
    scope: "openid profile email",
    post_logout_redirect_uri: `${process.env.REACT_APP_URL}/logout`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    loadUserInfo: true,
    silent_redirect_uri: `${process.env.REACT_APP_URL_AUTH}/silent-renew`,
};


const userManager = new UserManager(userManagerConfig);

export const login = async () => {
    try {
        await userManager.signinRedirect();
    } catch (error) {
        console.error("Erro ao fazer login:", error);
    }
};

// Função para fazer logout
export const logout = async () => {
    try {
        await userManager.revokeAccessToken();
    } catch (error) {
        console.error("Erro ao fazer logout:", error);
    }
};


export const getUser = async () => {
    try {
        const user = await userManager.getUser();
        return user;
    } catch (error) {
        console.error("Erro ao obter usuário:", error);
        return null;
    }
};


export const handleCallback = async () => {
    try {
        const user = await userManager.signinRedirectCallback();
        return user;
    } catch (error) {
        console.error("Erro ao lidar com callback de login:", error);
    }
};



