import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { routes } from "./routes";
import AuthRoute from "./components/Services/AuthRoute";
import { useEffect } from "react";
import { initializeApp } from "./auth/actions/userActions";
import { READY_APP } from "./ducks/user";
import { getUser } from "./utils/user-manager";

const App = () => {
    const { checked, authenticated, user } = useSelector((state) => state.session);
    const { ready } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const initialize = async () => {
            if (checked && !ready) {
                if (authenticated && user?.sub) {
                    const userData = await getUser();
                    if (userData && !userData.expired) {
                        dispatch(initializeApp(user.sub));
                    } else {
                        dispatch({ type: READY_APP });
                    }
                } else if (!authenticated) {
                    dispatch({ type: READY_APP });
                }
            }
        };

        initialize();
    }, [checked, authenticated, ready, dispatch, user]);

    if (!ready) {
        return null;
    }

    return (
        <BrowserRouter>
            <Switch>
                {routes.map((route, index) => (
                    <AuthRoute key={index} {...route} />
                ))}
            </Switch>
        </BrowserRouter>
    );
};

export default App;
