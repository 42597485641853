import React, {useEffect, useState} from "react";
import {allFormsOfSavedObjectsPagination, getMetadataOfObj} from "../../../../../../auth/actions/objActions";
import {Button, Select,Input} from "antd";
import IconTriggerModal from "../../Snippets/IconTriggerModal";
import {useSelector} from "react-redux";

export const SelectForm = ({value,onChange,onBlur, withFields = false,getFirstOptionsFields = null}) => {
    const company = useSelector((state) => state.user.company);
    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPages: 1,
    });

    const fetchOptions = async (searchValue, currentPage, first = false) => {
        setLoading(true);
        const response = await allFormsOfSavedObjectsPagination({
            formName: searchValue,
            unitId:company.unitId,
            page: currentPage,
            perPage: pagination.pageSize,
        });

        const transformedData = transformData(response.message);
        setData(prevData =>
            currentPage === 1 ? transformedData : [...prevData, ...transformedData]
        );

        setPagination(prev => ({
            ...prev,
            currentPage: response.pagination.currentPage,
            totalPages: response.pagination.totalPages,
        }));

        setLoading(false);
        if(first && value && getFirstOptionsFields){
            try {
                const data = await getMetadataOfObj({formName:value.name})
                if(data && data.success){
                    payload.fields = data.message;
                    getFirstOptionsFields(data.message);
                }
            }catch (e) {
                console.log(e)
            }

        }
    };

    useEffect(() => {
        fetchOptions(searchTerm, 1, true);
    }, [value]);

    const handleSearchTermChange = value => {
        setSearchTerm(value);
        setPagination({...pagination, currentPage: 1});
        fetchOptions(value, 1);
    };

    const handleScroll = event => {
        const {target} = event;
        if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 100 && !loading && pagination.currentPage < pagination.totalPages) {
            const nextPage = pagination.currentPage + 1;
            setPagination(prev => ({...prev, currentPage: nextPage}));
            fetchOptions(searchTerm, nextPage);
        }
    };

    const transformData = data => {
        return data.map(item => ({
            label: item.name,
            value: item.id,
        }));
    };

    const handleSelectChange = async (value, option) => {
        const item = data.find(item => item.value === value);
        setSelectedOption(item);

        const payload = {
            name: item.label,
            id: item.value,
        };

        if(withFields && item){
            const data = await getMetadataOfObj({formName:payload.name})
            if(data && data.success){
                payload.fields = data.message;
            }else{
                payload.fields = null;
            }
        }

        if (item) {
            onChange(payload)
        }
    };

    const options = [
        {
            value: '',
            label: 'Pesquise um formulário',
            disabled: true,
        },
        ...data,
    ];

    const payload =  value ? {
        label: value.name,
        value: value.id,
    } : {};
    return (
        <Input.Group compact style={{display: 'flex', alignItems:"center"}}>
            <Select
                onBlur={ (e) => (onBlur) ? onBlur(e): null }
                loading={loading}
                style={{width: '100%'}}
                showSearch
                onSearch={handleSearchTermChange}
                onChange={handleSelectChange}
                value={payload}
                onPopupScroll={handleScroll}
                options={options}
                filterOption={false}
            >
            </Select>
        </Input.Group>

    );
}
