import React from "react";
import {useSelector} from "react-redux";
import {Form, Input, message, Modal} from "antd";
import "moment/locale/pt-br";
import {userApi} from "../../services/actions/users";

const NewRoleModal = ({isOpen, closeModal}) => {
    const {company} = useSelector(state => state.user);

    const [form] = Form.useForm();

    const handleCancel = () => {
        closeModal();
        form.resetFields();
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            await userApi.createRole({
                ...values,
                unitId: company.unitId
            });

            message.success("Papel criado com sucesso!");
            closeModal(false);
            form.resetFields();
            closeModal();
        } catch (error) {
            message.error(error.message || "Erro ao criar papel.");
        }
    };

    return (
        <Modal
            title="Criar Papel"
            visible={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Criar"
            cancelText="Cancelar"
            width={700}
        >
            <Form form={form} layout="horizontal">
                <Form.Item
                    label="Nome do papel"
                    name="name"
                    rules={[{required: true, message: "Por favor, insira o nome do papel!"}]}
                >
                    <Input placeholder="Nome do papel"/>
                </Form.Item>

                <Form.Item
                    label="Descrição:"
                    name="description"
                    rules={[{required: true, message: "Por favor, insira a descrição!"}]}
                >
                    <Input.TextArea rows={4} placeholder="Descrição do papel"/>
                </Form.Item>
            </Form>
        </Modal>
    );
};



export default NewRoleModal;
