import React, { useEffect, useState } from "react";
import { getUser, login } from "../../utils/user-manager-app";
import { Alert, Spin } from "antd";
import { sendEventLoginData } from "./sendEventUserData";

const LoginApp = () => {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [sendRedirect, setSendRedirect] = useState(false);
    useEffect(() => {
        const getUserData = async () => {
            setError(null);
            setLoading(true);
            try {
                const data = await getUser();
                if (data) {
                    await sendEventLoginData(data);
                    setUser(data);
                }
            } catch (err) {
                console.error("Erro ao obter dados do usuário:", err);
                setError(err.message || "Erro ao carregar dados do usuário");
            } finally {
                setLoading(false);
            }
        };
        getUserData();
    }, []);

    const redirect =  () => {
        if(sendRedirect) return;
        login()
        setSendRedirect(true);
    }
    return (
        <div style={styles.centerContainer}>
            {error && !loading && <Alert type="error" message={error} />}
            {loading && <Spin tip="Carregando..." />}
            {user && <div>Usuário autenticado com sucesso!</div>}
            {!user && !loading && redirect()}
        </div>
    );
};

const styles = {
    centerContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "90vw",
    },
};

export default LoginApp;
