import React, { useEffect, useState } from "react";
import { handleCallback } from "../../utils/user-manager-app";
import { sendEventLoginData } from "./sendEventUserData";
import { Alert, Spin } from "antd";

const CallbackApp = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const processCallback = async () => {
            try {
                const user = await handleCallback();
                if (user) {
                    await sendEventLoginData(user);
                    setUser(user);
                }
            } catch (err) {
                console.error("Erro no callback:", err);
                setError(err.message || "Erro ao processar login.");
            } finally {
                setLoading(false);
            }
        };

        processCallback();
    }, []);

    if (loading) return <Spin tip="Processando login..." />;
    if (!loading && error) return <Alert type="error" message={error} />;
    if (user) return <div>Usuário autenticado com sucesso!</div>;

    return null;
};

export default CallbackApp;
