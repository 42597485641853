import React, {useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Layout, Menu} from "antd";
import {
    HomeOutlined, UserOutlined, TagOutlined, FileTextOutlined,
    AreaChartOutlined, SnippetsOutlined, RobotOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {getGlobalPermissions, getUnitAccessPermissions} from "../../../utils/permission";


const {Sider} = Layout;

function get(key) {
    try {
        const data = JSON.parse(global.localStorage?.getItem("colp-69")) || {};
        return data[key];
    } catch (e) {
        return undefined;
    }
}

function save(key, value) {
    global.localStorage?.setItem(
        "colp-69",
        JSON.stringify({
            [key]: value,
        })
    );
}

const KEY_MAP = {
    0: "/dashboard/resumo",
    1: "/usuarios-e-permissoes",
    2: "/formularios/categoria/todas",
    3: "/formularios/todos",
    4: "/relatorios",
    5: "/atividades/todas",
    6: "/automacoes"
};

const ITEMS = [
    {
        key: "0",
        icon: <HomeOutlined/>,
        label: "Dashboard",
        permission: ["VIEW_MAIN_DASHBOARD"]
    },
    {
        key: "1",
        icon: <UserOutlined/>,
        label: "Usuários e Permissões",
        permission: ["MANAGE_ROLES"]
    },
    {
        key: "2",
        icon: <TagOutlined/>,
        label: "Categorias",
        permission: [
            "CREATE_CATEGORY",
            "UPDATE_CATEGORY",
            "DELETE_CATEGORY"
        ]
    },
    {
        key: "3",
        icon: <FileTextOutlined/>,
        label: "Formulários",
        permission: [
            'CREATE_FORM',
            'DELETE_FORM',
            'CREATE_FIELD_IN_FORM',
            'EDIT_FIELD_IN_FORM',
            'DELETE_FIELD_IN_FORM',
            'ORDER_FIELDS_IN_FORM',
            'INSERT_DATA_IN_FORM',
            'EDIT_DATA_IN_FORM',
            'IMPORT_DATA_IN_FORM',
            'DELETE_DATA_IN_FORM',
            'VIEW_DATA_FROM_FORM',
            'VIEW_DATA_ARCHIVED_FROM_FORM'
        ]
    },
    {
        key: "4",
        icon: <AreaChartOutlined/>,
        label: "Relatórios",
        permission: [
            // 'CREATE_REPORT',
            'VIEW_REPORT_EMBED'
        ]
    },
    {
        key: "5",
        icon: <SnippetsOutlined/>,
        label: "Atividades de dados",
        permission: ["VIEW_ACTIVITY_LOG"]
    },
    {
        key: "6", icon: <RobotOutlined/>,
        label: "Automações",
        permission: [
            'CREATE_AUTOMATION',
            'EDIT_AUTOMATION',
            'DELETE_AUTOMATION',
            'VIEW_LOG_AUTOMATION'
        ]
    }
];

export default function DrawerSider() {
    const history = useHistory();
    const location = useLocation();
    const permissions = useSelector((state) => state.user.permissions);
    const company = useSelector((state) => state.user.company);

    const [itemsAllowed, setItemsAllowed] = useState([]);
    const [selectedKey, setSelectedKey] = useState();
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        setCollapsed(get("collapsed") || false);
    }, []);

    useEffect(() => {
        if (company && permissions) {
            const unitPermissions = getUnitAccessPermissions(permissions, company.unitId);
            const globalPermissions = getGlobalPermissions(permissions);
            const allPermissions = [
                ...unitPermissions.map(({type}) => type),
                ...globalPermissions.map(({type}) => type)
            ];

            const filteredItems = ITEMS.filter((item) =>
                item.permission.some((perm) => allPermissions.includes(perm))
            );

            setItemsAllowed(filteredItems);
        }
    }, [permissions, company]);

    const handleOnSelect = ({key}) => {
        if (KEY_MAP[key] !== location.pathname) {
            setSelectedKey(key);
            history.push(KEY_MAP[key]);
        } else {
            setSelectedKey("");
        }
    };

    const handleCollapse = (collapsed) => {
        setCollapsed(!collapsed);
        save("collapsed", !collapsed);
    };

    return (
        <Sider
            className="site-layout-background"
            collapsible
            collapsed={!collapsed}
            onCollapse={handleCollapse}
            theme="light"
        >
            <Menu
                style={{height: "100%", borderRight: 0}}
                selectedKeys={[selectedKey]}
                onSelect={handleOnSelect}
                mode="inline"
                items={itemsAllowed.map(({key, icon, label}) => ({
                    key,
                    icon,
                    label,
                }))}
            />
        </Sider>
    );
}
