import React from "react";
import { connect, useSelector } from "react-redux";
import {
    Layout,
    PageHeader,
    Breadcrumb,
    Card,
    Tabs
} from "antd";
import { useHistory } from "react-router-dom";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import AllUsers from "./All";
import Role from "./Role";
import Group from "./Group";
import Unit from "./Unit";
import { checkPermissions } from "../../utils/permission";

function UserAndPermission({ user }) {
    const history = useHistory();
    const permissionsFromUser = useSelector((state) => state.user.permissions);
    const company = useSelector((state) => state.user.company);

    const routes = [
        {
            breadcrumbName: "Usuarios e Permissões",
            href: "/usuarios-e-permissoes",
        },
    ];

    const renderUnitTab = checkPermissions(
        permissionsFromUser,
        company,
        ['MANAGE_COMPANIES'],
        <Tabs.TabPane tab="Unidades" key="4">
            <Unit />
        </Tabs.TabPane>
    );

    return (
        <Layout>
            <LayoutTopSide>
                <TopBar />
                <Layout>
                    <DrawerSider />
                    <Layout
                        style={{
                            padding: "0 24px 24px",
                            minHeight: "100vh",
                        }}
                    >
                        <PageHeader
                            style={{ margin: "16px 0 5px 0" }}
                            onBack={() => window.history.back()}
                            breadcrumb={
                                <Breadcrumb>
                                    {routes.map((a) =>
                                        a.hasOwnProperty("href") ? (
                                            <Breadcrumb.Item key={Math.random()}>
                                                <a href onClick={() => history.push(a.href)}>
                                                    {a.breadcrumbName}
                                                </a>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                                        )
                                    )}
                                </Breadcrumb>
                            }
                        />
                        <Card>
                            <Tabs>
                                <Tabs.TabPane tab="Usuarios" key="1">
                                    <AllUsers />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Grupos" key="2">
                                    <Group />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Papéis" key="3">
                                    <Role />
                                </Tabs.TabPane>
                                {renderUnitTab}
                            </Tabs>
                        </Card>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}

const mapStateToProps = ({ session }) => ({
    user: session.user,
});

export default connect(mapStateToProps)(UserAndPermission);
