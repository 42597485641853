import React, { useEffect, useRef, useState } from "react";
import {connect, useSelector} from "react-redux";
import {
  Layout,
  PageHeader,
  Row,
  Col,
} from "antd";
import {useHistory, useParams} from "react-router-dom";
import "reactjs-popup/dist/index.css";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import BinByForm from "components/Forms/Records/Bin/Table";
import {checkPermission} from "../../../../utils/permission";

function BinOfForm() {
  const user = useSelector((state) => state.session.user);
  const permissions = useSelector((state) => state.user.permissions);
  const company = useSelector((state) => state.user.company);
  const { name } = useParams();
  const history = useHistory();

  useEffect(() => {
    if(company && name && permissions &&  !checkPermission(permissions, 'VIEW_DATA_ARCHIVED_FROM_FORM', company.unitId, 'form', null,name.replace("obj_", ""))){
      history.push("/403")
    }
  }, [user, permissions, company, name, history]);

  const hasPermissionToEdit = company && permissions && checkPermission(permissions, 'EDIT_DATA_IN_FORM', company.unitId, 'form',null,name.replace("obj_", ""));
  const hasPermissionToDelete = company && permissions && checkPermission(permissions, 'DELETE_DATA_IN_FORM', company.unitId, 'form',  null,name.replace("obj_", ""));
  return (
    <Layout>
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 53px 53px", height: "235vh" }}>
            <PageHeader
              style={{ margin: "16px 0px 5px", paddingLeft: 0 }}
              title="Registros arquivados"
              onBack={() => window.history.back()}
            />
            <Row>
              <Col span={24}>
                {user && (
                  <BinByForm
                    name={name}
                    useMatchAll={true}
                    useBin={true}
                    user={user}
                    hasPermissionToEdit={hasPermissionToEdit}
                    hasPermissionToDelete={hasPermissionToDelete}
                  />
                )}
              </Col>
            </Row>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
});

export default connect(mapStateToProps)(BinOfForm);
