import axios from 'axios';
import {getUser} from "../utils/user-manager";

const getApiUrl = axios.create({
  baseURL: process.env.REACT_APP_API,
});

getApiUrl.interceptors.request.use(
    async (config) => {
        const user = await getUser();
        if (user && user.access_token && !user.expired) {
            config.headers['Authorization'] = `Bearer ${user.access_token}`;
        }else{
            window.location.href = "/logout";
            return;
        }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

export default getApiUrl;
