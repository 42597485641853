import { useSelector } from "react-redux";
import { hasAnyPermission } from "../../utils/permission";

const PermissionWrapperComponent = ({ permissions, children }) => {
    const permissionsFromUser = useSelector((state) => state.user.permissions);
    const company = useSelector((state) => state.user.company);

    if (!permissionsFromUser || !permissions || !company?.unitId) {
        return null;
    }

    return hasAnyPermission(permissionsFromUser, permissions, company.unitId) ? children : null;
};

export default PermissionWrapperComponent;
