import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { handleCallback } from "../../utils/user-manager";
import { sessionService } from "redux-react-session";
import {initializeApp, syncReportUser} from "../../auth/actions/userActions";
import {END_GET_USER_REPORT_DATA, ERROR_GET_USER_REPORT_DATA} from "../../ducks/report";


const Callback = () => {
    const authenticated = useSelector((state) => state.session.authenticated);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!authenticated) {
            handleCallback().then(async (response) => {
                const { access_token, profile } = response;
                try {

                    await sessionService.saveSession(access_token);
                    await sessionService.saveUser(profile);

                    dispatch(initializeApp(profile.sub))

                    const responseReport = await syncReportUser(profile.sub)
                    const {  data:dataReport, status:statusReport } = responseReport;
                    if (statusReport === 201) {
                        dispatch({ type: END_GET_USER_REPORT_DATA, payload: dataReport.user });
                        localStorage.setItem("user-report", JSON.stringify(dataReport.user));
                    } else {
                        throw new Error(dataReport);
                    }
                    history.push("/bem-vindo");
                } catch (error) {
                    console.error("Erro ao salvar sessão ou usuário:", error);
                    dispatch({ type: ERROR_GET_USER_REPORT_DATA, payload: error.message });
                }
            }).catch((err) => {
                console.error("Erro ao lidar com callback de login:", err);
            });
        }
    }, [authenticated, history, dispatch]);

    if (authenticated) {
        return <Redirect to="/bem-vindo" />;
    }

    return null;
};

export default Callback;
