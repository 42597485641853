import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearSessionReport } from "../../ducks/report";
import { sessionService } from "redux-react-session";
import { logout } from "../../utils/user-manager";
import { useEffect } from "react";

const Logout = () => {
    const authenticated = useSelector((state) => state.session.authenticated);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const handleLogout = async () => {
            dispatch(clearSessionReport());
            await sessionService.deleteSession();
            await sessionService.deleteUser();
            await logout();
            history.push("/login");
        };

        handleLogout();
    }, [authenticated, dispatch, history]);

    return null;
};

export default Logout;
