import {ProCard, ProTable} from "@ant-design/pro-components";
import ptBRIntl from "antd/lib/locale/pt_BR";
import {Button, ConfigProvider, Spin, Alert, Modal, Drawer, Select,Tooltip } from "antd";
import React, {useEffect, useState} from "react";
import {userApi as api, userApi} from "../../../services/actions/users";
import {ExclamationCircleOutlined, DeleteOutlined, PlusCircleOutlined,LockOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {GroupDetails} from "../../../components/GroupDetails";

export function Groups({user}) {

    const {company} = useSelector(state => state.user);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [openModalAddGroupToUser, setOpenModalAddGroupToUser] = useState(false);
    const [listGroupsSelected, setListGroupsSelected] = useState(null);
    const [groupSelected, setGroupSelected] = useState(null);
    const [loadGroups, setLoadGroups] = useState(false);
    const [loadAddGroups, setLoadAddGroups] = useState(false);

    const fetchGroupsByUser = async () => {
        try {
            setError(null);
            setLoading(true);
            const response = await userApi.getGroupsByUser(user.uuid);
            setGroups(response.data);
        } catch (error) {
            setError(error.message || "Erro ao carregar grupos");
        } finally {
            setLoading(false);
        }
    };

    const fetchGroupDetails = async (id) => {
        setOpen(true);
        setGroup(id)
    }

    const handleCloseDrawer = () => {
        setOpen(false);
        setGroup(null);
    }

    useEffect(() => {
        fetchGroupsByUser();
    }, [user.id])

    const columns = [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Descrição",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Detalhes",
            render: ({id}) => <Button onClick={() => fetchGroupDetails(id)}>Visualizar</Button>,
        },
        {
            title: "Remover Usuário",
            render: ({id, name}) => {
                const button = <Button  icon={groups.length === 1 ? <LockOutlined /> : <DeleteOutlined/>} onClick={() => handleConfirmRemoveUserFromGroup(id, name)}>Remover</Button>;
                const item = groups.length === 1 ?  <Tooltip title={"É necessário um grupo pelo menos"}>{button}</Tooltip> : button;
                return item;
            },
        },
    ];


    const handleConfirmRemoveUserFromGroup = (groupId, groupName) => {
        if(groups.length === 1){
            return Modal.warning({title: "Atenção", content: "Não é possível remover o último grupo do usuário. Ele precisa estár atrelado a um grupo pelo menos"});
        }else{
            Modal.confirm({
                title: "Remover Usuário",
                icon: <ExclamationCircleOutlined/>,
                content: <p>Tem certeza que deseja remover este usuário <b>{user.firstName}</b> do grupo <b>{groupName}</b>?
                </p>,
                onOk: async () => {
                    try {
                        setError(null);
                        setLoading(true);
                        const response = await userApi.removeUserFromGroup(null, groupId,user.uuid,company.unitId);
                        if (response.status === 201) {
                            fetchGroupsByUser()
                        }
                    } catch (ex) {
                        setError(ex.message);
                    } finally {
                        setLoading(false);
                    }
                },
            });
        }

    }

    const  handleOkAddUserToGroup = async ()  => {
        try {
            setLoadAddGroups(true)
            const groupToAdd = groupSelected;
            if (groupToAdd) {
                const response = await userApi.addUserToGroup(null, groupToAdd,user.uuid,company.unitId);
                if(response && response.status === 201) {
                    await fetchGroupsByUser()
                }
            }
        }catch (e){
            setError(e.message);
        }finally {
            setLoadAddGroups(false)
            setOpenModalAddGroupToUser(false);
            setGroupSelected(null)
        }
    }

    const  handleCancelAddUserToGroup = ()  => {
        setOpenModalAddGroupToUser(false);
        setGroupSelected(null)
    }

    async function openModalToAddUserToGroup() {
        if(company){
            try {
                setLoadGroups(true);
                const response = await api.getGroupsByUnit(company.unitId);
                const {data } = response;
                if(data){
                    const idGroups = groups.map(item => item.id);
                    const list = data.filter(item => {
                        return !idGroups.includes(item.id);
                    }).map(item =>{
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    })
                    setListGroupsSelected(list);
                    setOpenModalAddGroupToUser(true)
                }

            }catch (e) {
                console.log(e)
            }finally {
                setLoadGroups(false)
            }
        }


    }

    return (
        <ConfigProvider locale={ptBRIntl}>
            <Modal
                centered={true}
                title={`Adicionando ${user.firstName} a um grupo`}
                visible={openModalAddGroupToUser}
                onOk={handleOkAddUserToGroup}
                onCancel={handleCancelAddUserToGroup}
                okText="Finalizar"
                cancelText="Cancelar"
                confirmLoading={loadAddGroups}
            >
                <Select
                    value={groupSelected}
                    onChange={(value) => setGroupSelected(value)}
                    showSearch={true}
                    placeholder={"Selecione um grupo"}
                    style={{ width: '100%' }}
                    options={listGroupsSelected}
                />
                <br />
            </Modal>
            {group && <GroupDetails groupId={group} isOpen={open} closeDrawer={handleCloseDrawer}/>}
            <ProCard extra={
                <Button loading={loadGroups} disabled={loadGroups} icon={<PlusCircleOutlined/>} onClick={() => openModalToAddUserToGroup()}> Adicionar
                    Grupo</Button>
            }>
                {loading ? (
                    <Spin tip="Carregando...">
                        <div style={{minHeight: "200px"}}></div>
                    </Spin>
                ) : null}

                {!loading && error ? (
                    <Alert message={error} type="error" showIcon/>
                ) : null}
                <ProTable
                    loading={loading}
                    search={false}
                    columns={columns}
                    dataSource={groups}
                    rowKey="id"
                    pagination={{pageSize: 5}}
                />
            </ProCard>
        </ConfigProvider>
    );
}
