import React, {useEffect, useRef, useState} from "react";
import {Menu, Dropdown, List, Badge, Popover, Avatar, Button} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {connect} from "react-redux";
import {
    deleteNotificationById,
    getAllNotifications, nextPageNotifications,
    readAllNotificationByUserId,
    readNotificationById,
    unreadNotificationById
} from "../ducks/notification-center";

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined,
    BellOutlined,
    EllipsisOutlined,
    CheckOutlined
} from '@ant-design/icons';
import getApiUrl from "../services/getApiUrl";
import io from "socket.io-client";
import moment from "moment";
import socketInstance from "../services/socket";

const NotificationIcon = ({type}) => {
    const iconStyle = {fontSize: 20};

    switch (type) {
        case 'success':
            return <CheckCircleOutlined style={{...iconStyle, color: '#52c41a'}} h/>;
        case 'error':
            return <CloseCircleOutlined style={{...iconStyle, color: '#f5222d'}}/>;
        case 'info':
            return <InfoCircleOutlined style={{...iconStyle, color: '#1890ff'}}/>;
        case 'warning':
            return <WarningOutlined style={{...iconStyle, color: '#faad14'}}/>;
        default:
            return null;
    }
};

const notificationMenu = (id, handleMarkAsUnread, handleDeleteNotification) => (
    <Menu>
        <Menu.Item key="markAsUnread" onClick={() => handleMarkAsUnread(id)}>
            Marcar como Não Lida
        </Menu.Item>
        <Menu.Item key="delete" onClick={() => handleDeleteNotification(id)}>
            Deletar
        </Menu.Item>
    </Menu>
);

const NotificationItem = ({
                              id,
                              type,
                              title,
                              content,
                              read,
                              action,
                              handleMarkAsUnread,
                              handleDeleteNotification,
                              handleMarkAsRead,
                              createdAt,
                              firstReadAt
                          }) => {
    useEffect(() => {
        moment.locale('pt-br');  // Defina o local para o idioma desejado
    }, []);

    const timeAgoCreatedAt = moment(createdAt).fromNow();
    const timeAgoFirstReadAt = moment(firstReadAt).fromNow();
    return (
        <List.Item
            key={id}
            className={`notification-item ${read ? 'read' : 'unread'}`}
            actions={[
                <Dropdown overlay={notificationMenu(id, handleMarkAsUnread, handleDeleteNotification)}
                          trigger={['click']}>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                        <EllipsisOutlined/>
                    </a>
                </Dropdown>,
            ]}
            style={{backgroundColor: read ? '#f2f2f2' : '#e6f7ff', padding: "10px", marginBottom: '3px',}}
        >
            <a role="button" href={(action ? action : "#")} style={{display: "flex", flex: 1}}  onClick={() => !read ? handleMarkAsRead(id) : null}>
                <List.Item.Meta
                    avatar={<NotificationIcon type={type}/>}
                    title={title}
                    description={
                        <>
                            <div dangerouslySetInnerHTML={{__html: content}}/>
                            {read ? <span  style={{ fontSize: 'smaller', marginTop: '4px' }}>Lido em {moment(firstReadAt).format('LLL')} ({timeAgoFirstReadAt})</span> : <span  style={{ fontSize: 'smaller', marginTop: '4px' }}>Em {moment(createdAt).format('LLL')} ({timeAgoCreatedAt})</span>}
                        </>
                    }
                />
            </a>
        </List.Item>
    );
};

const HeaderNotificationCenter = ({handleMarkAllAsRead, disableButtonReadAll = false}) => {
    return (
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px"}}>
            <span style={{marginRight: '8px'}}>Notificações</span>
            <Button type="primary" onClick={handleMarkAllAsRead} ghost disabled={disableButtonReadAll}>
                Marcar Todas Como Lidas
            </Button>
        </div>
    );
}

const NotificationCenter = ({
                                user,
                                notificationCenter,
                                onReadNotification,
                                onUnreadNotification,
                                onDeleteNotification,
                                onGetAllNotifications,
                                onReadAllNotificationByUserId,
                                onGeNextPageNotifications
                            }) => {
    const [visible, setVisible] = useState(false);
    const socketRef = useRef(null);
    const scrollContainerRef = useRef();

    const handleVisibleChange = (flag) => {
        setVisible(flag);
    };

    useEffect(() => {
        const initializeSocket = async () => {
            if (!socketRef.current) {
                socketRef.current = await socketInstance();

                socketRef.current.on(`${user.sub}:newNotification`, async (data) => {
                    await onGetAllNotifications({ recipient: user.sub, page: 1 });
                    if (scrollContainerRef.current) {
                        scrollContainerRef.current.scrollTop = 0;
                    }
                });
            }

            // Chama as notificações iniciais
            await onGetAllNotifications({ recipient: user.sub, page: 1 });
        };

        initializeSocket();

        // Cleanup para desconectar o socket ao desmontar o componente
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                socketRef.current = null; // Limpa a referência do socket
            }
        };
    }, [onGetAllNotifications, user]);

    const handleRead = (id) => {
        onReadNotification(id);
    };

    const handleUnread = (id) => {
        onUnreadNotification(id);
    };

    const handleDelete = (id) => {
        onDeleteNotification(id);
    };

    const handleReadAll = () => {
        onReadAllNotificationByUserId(user.sub);
    };
    const [isLoadMore, setIsLoadMore] = useState(false)


    const getMoreData = async () => {
        if (!isLoadMore && notificationCenter.page < notificationCenter.totalPages) {
            setIsLoadMore(true);
            await onGeNextPageNotifications({recipient: user.sub, page: notificationCenter.page + 1});
            setIsLoadMore(false);
        }
    };
    const content = (
        <div id="notificaitonScrollContainer"
             ref={scrollContainerRef}
             style={{maxHeight: '400px', overflowY: 'auto', width: '450px', overflow: "auto"}}>
            <InfiniteScroll
                useWindow={false}

                dataLength={notificationCenter.notifications.length}
                next={getMoreData}
                hasMore={notificationCenter.page < notificationCenter.totalPages}
                loader={<h4 style={{textAlign:"center"}}>Carregando mais notificações!</h4>}
                endMessage={<p style={{textAlign:"center"}}>Não há mais notificações!</p>}
                scrollableTarget="notificaitonScrollContainer"
            >
                <List
                    loading={isLoadMore}
                    locale={{
                        emptyText: "Não há notificações!"
                    }}
                    size={"small"}
                    dataSource={notificationCenter.notifications}
                    renderItem={(item) => (
                        <NotificationItem
                            key={item.id}
                            {...item}
                            handleMarkAsUnread={handleUnread}
                            handleDeleteNotification={handleDelete}
                            handleMarkAsRead={handleRead}
                        />
                    )}
                />
            </InfiniteScroll>
        </div>
    );

    return (
        <Popover content={content}
                 title={<HeaderNotificationCenter handleMarkAllAsRead={handleReadAll} disableButtonReadAll={notificationCenter.unreadCount <= 0}/>}
                 trigger="click"
                 onVisibleChange={handleVisibleChange}
                 visible={visible}
        >
            <Badge count={notificationCenter.unreadCount} style={{cursor: 'pointer'}} color={"#12b398"}>
                <BellOutlined style={{cursor: 'pointer', color: "#12b398", fontSize: "30px"}} size={"large"}/>
            </Badge>
        </Popover>
    );
};

const mapStateToProps = ({session, notificationCenter}) => ({
    user: session.user,
    notificationCenter
});
const mapDispatchToProps = (dispatch) => {
    return {
        onReadNotification: (id) => dispatch(readNotificationById(id)),
        onUnreadNotification: (id) => dispatch(unreadNotificationById(id)),
        onDeleteNotification: (id) => dispatch(deleteNotificationById(id)),
        onGetAllNotifications: (params) => dispatch(getAllNotifications(params)),
        onReadAllNotificationByUserId: (user_id) => dispatch(readAllNotificationByUserId(user_id)),
        onGeNextPageNotifications: (params) => dispatch(nextPageNotifications(params)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);

