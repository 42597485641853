import {ProCard, ProTable} from "@ant-design/pro-components";
import ptBRIntl from "antd/lib/locale/pt_BR";
import {Button, ConfigProvider, Spin, Alert, Modal, message, Select} from "antd";
import React, {useEffect, useState, useCallback} from "react";
import {userApi, userApi as api} from "../../../services/actions/users";
import {ExclamationCircleOutlined, DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {RoleDetails} from "../../../components/RoleDetails";
import {useSelector} from "react-redux";

export function Roles({user}) {
    const {company} = useSelector(state => state.user);

    const [roles, setRoles] = useState(null);
    const [role, setRole] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [openModalAddRoleToUser, setOpenModalAddRoleToUser] = useState(false);
    const [roleSelected, setRoleSelected] = useState(null);
    const [listRolesSelected, setListRolesSelected] = useState(null);
    const [loadAddRoleToUser, setLoadAddRoleToUser] = useState(false);

    const openModalToAddRoleToUser = async ()  => {
        if(company){
            try {
                setLoadAddRoleToUser(true);
                const response = await api.getRolesByUnit(company.unitId);
                const {data } = response;
                if(data){
                    const idRoles = roles.map(item => item.id);
                    const list = data.filter(item => {
                        return !idRoles.includes(item.id);
                    }).map(item =>{
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    })
                    setListRolesSelected(list);
                    setOpenModalAddRoleToUser(true)
                }

            }catch (e) {
                console.log(e)
            }finally {
                setLoadAddRoleToUser(false)
            }
        }

    }

    const  handleCancelAddRoleToUser = ()  => {
        setOpenModalAddRoleToUser(false);
        setRoleSelected(null)
    }

    const  handleOkAddRoleToUser = async ()  => {
        try {
            setLoadAddRoleToUser(true)
            const roleToAdd = roleSelected;
            if (roleToAdd) {
                const response = await userApi.addUserToRole(null,roleToAdd,user.uuid,company.unitId);
                if(response && response.status === 201) {
                    await fetchRoles()
                }
            }
        }catch (e){
            setError(e.message);
        }finally {
            setLoadAddRoleToUser(false)
            setOpenModalAddRoleToUser(false);
            setRoleSelected(null)
        }
    }

    const fetchRoles = useCallback(async () => {
        try {
            setError(null);
            setLoading(true);
            const response = await api.getRolesByUser(user.uuid);
            setRoles(response.data);
        } catch (error) {
            setError(error.message || "Erro ao carregar papéis");
        } finally {
            setLoading(false);
        }
    }, [user.id]);

    const fetchRoleDetails = useCallback((id) => {
        setRole(id);
        setOpen(true);
    }, []);

    const handleCloseDrawer = useCallback(() => {
        setOpen(false);
        setRole(null);
    }, []);

    const handleConfirmRemoveUserFromRole = useCallback((roleName, roleId) => {
        Modal.confirm({
            title: "Remover Usuário",
            icon: <ExclamationCircleOutlined />,
            content: (
                <p>
                    Tem certeza que deseja remover o usuário <b>{user.firstName}</b> do papel{" "}
                    <b>{roleName}</b>?
                </p>
            ),
            onOk: async () => {
                try {
                    const response = await api.removeUserFromRole(null, roleId, user.uuid,company.unitId);
                    if (response.status === 201) {
                        message.success("Usuário removido do papel com sucesso!");
                        fetchRoles();
                    }
                } catch {
                    message.error("Erro ao remover usuário do papel.");
                }
            },
        });
    }, [user.firstName, user.id, fetchRoles]);

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    const columns = [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Descrição",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Detalhes",
            render: ({id}) => <Button onClick={() => fetchRoleDetails(id)}>Visualizar</Button>,
        },
        {
            title: "Remover Papel",
            render: ({id, name}) => (
                <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleConfirmRemoveUserFromRole(name, id)}
                >
                    Remover
                </Button>
            ),
        },
    ];

    return (
        <ConfigProvider locale={ptBRIntl}>
            <Modal
                centered={true}
                title={`Adicionando ${user.firstName} a um papel`}
                visible={openModalAddRoleToUser}
                onOk={handleOkAddRoleToUser}
                onCancel={handleCancelAddRoleToUser}
                okText="Finalizar"
                cancelText="Cancelar"
                confirmLoading={loadAddRoleToUser}
            >
                <Select
                    value={roleSelected}
                    onChange={(value) => setRoleSelected(value)}
                    showSearch={true}
                    placeholder={"Selecione um grupo"}
                    style={{ width: '100%' }}
                    options={listRolesSelected}
                />
                <br />
            </Modal>

            {role && <RoleDetails roleId={role} isOpen={open} closeDrawer={handleCloseDrawer} />}
            <ProCard extra={<Button icon={<PlusCircleOutlined />} onClick={() => openModalToAddRoleToUser()}> Adicionar Papel</Button>}>
                {loading ? (
                    <Spin tip="Carregando...">
                        <div style={{minHeight: "200px"}}></div>
                    </Spin>
                ) : null}

                {!loading && error ? (
                    <Alert message={error} type="error" showIcon />
                ) : null}

                {!loading && (
                    <ProTable
                        search={false}
                        columns={columns}
                        dataSource={roles}
                        rowKey="id"
                        pagination={{pageSize: 5}}
                    />
                )}
            </ProCard>
        </ConfigProvider>
    );
}
