import React from "react";
import {connect, useSelector} from "react-redux";
import {
    Layout,
    PageHeader,
    Breadcrumb,
    Button,
    ConfigProvider,
    Avatar,
    Card,
} from "antd";
import { ProTable } from "@ant-design/pro-components";
import { FileSyncOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ptBRIntl from "antd/lib/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import { allActivitiesForms } from "auth/actions/activitieActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Utils
 */
import { getUsers } from "../../../auth/actions/userActions";
import { allFormsOfSavedObjects } from "../../../auth/actions/objActions";
import { ItemActivityForm } from "./ItemActivityForm";

const actions = {
    created: "Criou",
    updated: "Atualizou",
    archived: "Arquivou",
    deleted: 'Deletou',
    sorted: "Ordenou"
};

const entities = {
    'field': "Campo",
    'form': "Formulário",
    'category': "Categoria"
};

const getActionText = (text) => {
    return actions[text] || text;
};

const getEntityText = (text) => {
    return entities[text] || text;
};


function AllActivitiesForms({ user }) {
    const company = useSelector((state) => state.user.company);
    const history = useHistory();

    const routes = [
        {
            breadcrumbName: "Atividades de Formulários",
            href: "/atividades/formularios/todas",
        },
        {
            breadcrumbName: "todas",
        },
    ];

    const requestUsers = async (values) => {
        let data;
        if (values.keyWords) {
            data = await getUsers(values.keyWords, company.unitId);
        } else {
            data = await getUsers(null, company.unitId);
        }
        data = data.users.map((item) => ({
            label: item.firstName,
            value: item.uuid,
        }));

        return data;
    }

    const requestForms = async (values) => {
        let data;
        if (values.keyWords) {
            data = await allFormsOfSavedObjects(values.keyWords,company.unitId);
        } else {
            data = await allFormsOfSavedObjects(null, company.unitId);
        }
        data = data.message.map((item) => ({
            label: item.name,
            value: item.name,
        }));
        return data;
    }

    const columns = [
        {
            sorter: true,
            title: "Formulário",
            dataIndex: "form",
            key: "form",
            render: (dom, record, index, action, schema) => {
                const text = record[schema.dataIndex];
                if (text) {
                    return (
                        <>
                            <Avatar
                                size="small"
                                icon={<FileSyncOutlined />}
                                style={{
                                    backgroundColor: "#12b398",
                                }}
                            />
                            <Button
                                type="link"
                                style={{
                                    color: "#12b398",
                                }}
                                onClick={() => history.push(`/formularios/registros/obj_${text}`)}
                            >
                                {text.replace("obj_", "")}
                            </Button>
                        </>
                    );
                }

            },
            fieldProps: {
                mode: "multiple",
                placeholder: "",
                showSearch: true,
            },
            width: "auto",
            ellipsis: true,
            valueType: "select",
            request: requestForms
        },
        {
            sorter: true,
            title: "Açāo",
            dataIndex: "action",
            key: "action",
            render: (text) => getActionText(text),
            valueEnum: actions,
            valueType: "select",
            fieldProps: {
                mode: "multiple",
                placeholder: "",
            },
            width: "auto",
            ellipsis: true,
        },
        {
            sorter: true,
            title: "Entidade",
            dataIndex: "entity",
            key: "entity",
            render: (text) => getEntityText(text),
            valueEnum: entities,
            valueType: "select",
            fieldProps: {
                mode: "multiple",
                placeholder: "",
            },
            width: "auto",
            ellipsis: true,
        },
        {
            sorter: true,
            title: "Autor",
            dataIndex: "createdBy",
            key: "createdBy",
            valueType: "select",
            fieldProps: {
                mode: "multiple",
                placeholder: "",
                showSearch: true,
            },
            width: "auto",
            ellipsis: true,
            request: requestUsers
        },
        {
            sorter: true,
            title: "Criado em",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
                }
            },
            valueType: "dateTimeRange",
            fieldProps(form, config) {
                return {
                    ...config,
                    format: "DD/MM/YYYY HH:mm",
                    placeholder: "",
                };
            },
            width: "auto",
            ellipsis: true,
        }

    ];


    return (
        <Layout>
            <LayoutTopSide>
                <TopBar />
                <Layout>
                    <DrawerSider />
                    <Layout
                        style={{
                            padding: "0 24px 24px",
                            minHeight: "100vh",
                        }}
                    >
                        <PageHeader
                            style={{ margin: "16px 0 5px 0" }}
                            title="Lista de atividades"
                            subTitle="Acompanhe o lançamento, edição e exclusão de qualquer formulário ou campo."
                            onBack={() => window.history.back()}
                            breadcrumb={
                                <Breadcrumb>
                                    {routes.map((a) =>
                                        a.hasOwnProperty("href") ? (
                                            <Breadcrumb.Item key={Math.random()}>
                                                <a href onClick={() => history.push(a.href)}>
                                                    {a.breadcrumbName}
                                                </a>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                                        )
                                    )}
                                </Breadcrumb>
                            }
                        />
                        <Card>
                            <ConfigProvider locale={ptBRIntl}>
                                <ProTable
                                    rowKey="_id"
                                    type="table"
                                    request={async (params = {}, sorter, filter) => {
                                        const items = await allActivitiesForms({
                                            form: params.form,
                                            action: params.action,
                                            entity: params.entity,
                                            createdAt: params.createdAt,
                                            createdBy: params.createdBy,
                                            page: params.current,
                                            pageSize: params.pageSize,
                                            sorter
                                        });
                                        return {
                                            data: items.message,
                                            success: true,
                                            total: items.pagination.totalHits,
                                        };
                                    }}
                                    columns={columns}
                                    dateFormatter={"number"}
                                    scroll={{ x: true }}
                                    expandable={{
                                        expandedRowRender: ItemActivityForm
                                    }}
                                    style={{ padding: 0 }}
                                />
                            </ConfigProvider>
                        </Card>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}

const mapStateToProps = ({ session }) => ({
    user: session.user,
});

export default connect(mapStateToProps)(AllActivitiesForms);
