import React, { useEffect, useState } from "react";
import { ProCard } from "@ant-design/pro-components";
import axios from "axios";
import getApiUrl from "../../../../../services/getApiUrl";
import {Collapse, Tabs} from "antd";
import uuid from "react-uuid";
import {getNameFromActionByType, getNameFromTriggerByType} from "../Actions/utils/functions";
import _ from "lodash";
import moment from "moment/moment";
import PaginatedJsonView from "./PaginatedJsonViewer";
const { Panel } = Collapse;
export const LogViewer = ({ automation, logId = null }) => {
    const [load, setLoad] = useState(false);
    const [automationLogData, setAutomationLogData] = useState(null);
    const loadData = async () => {
        if (logId !== null) {
            try {
                setLoad(true);
                setAutomationLogData(null);
                const { data, status } = await getApiUrl.get(`/automations/logs/${logId}`);
                if (status === 200) {
                    setAutomationLogData(data);
                }
                setLoad(false);
            } catch (ex) {
                setLoad(false);
            }
        }
    };

    useEffect(() => {
        loadData();
    }, [logId]);

    function renderPanelTriggerLog() {
        if(automationLogData){
            const {trigger} = automationLogData;
            return (<Panel header={`Gatilho: ${getNameFromTriggerByType(trigger.type)}`} key={uuid()}>
                <Tabs defaultActiveKey={1}>
                    <Tabs.TabPane tab="Configurações" key="1">
                        <PaginatedJsonView key={uuid()} data={_.omit(trigger, ['payload']) ?? {}} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Entrada" key="2">
                        <PaginatedJsonView key={uuid()} data={trigger.payload ?? {}} />
                    </Tabs.TabPane>
                </Tabs>
            </Panel>)
        }
        return null;
    }

    const renderPanelActionLog = () => {
        if (automationLogData) {
            return automationLogData.steps.map((step, index) => {
                const {action, data,endAction,startAction} = step;

                return (
                    <Panel key={uuid()} header={`Ação ${index + 1}: ${getNameFromActionByType(action.type)}`}>
                        {automationLogData && !load && <div style={{
                            display: 'flex',
                            flexDirection:"column"
                        }}>
                            <p>
                                <label>Data de início: </label>
                                <b>{moment(startAction).format('DD/MM/YYYY HH:mm:ss.SSS')}</b>
                            </p>
                            <p>
                                <label>Data de finalização: </label>
                                <b>{moment(endAction).format('DD/MM/YYYY HH:mm:ss.SSS')}</b>
                            </p>
                            <p>
                                <label>Duração: </label>
                                <b>{moment.utc(moment(endAction).diff(moment(startAction))).format('HH:mm:ss.SSS')}</b>
                            </p>
                        </div>}
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Configurações" key="1">
                                <PaginatedJsonView key={uuid()} data={action.config ?? {}} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Saída" key="2">
                                <PaginatedJsonView key={uuid()} data={(data) ?? {}} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Panel>
                );
            });
        }
        return [];
    };

    const panels = [
        renderPanelTriggerLog(),
        ...renderPanelActionLog()
    ]

    return (
        <ProCard title={"Detalhe"} loading={load}>
            {automationLogData && !load && <div style={{
                display: 'flex',
                flexDirection:"column"
            }}>
                <p>
                    <label>Data de início: </label>
                    <b>{moment(automationLogData.startDate).format('DD/MM/YYYY HH:mm:ss.SSS')}</b>
                </p>
                <p>
                    <label>Data de finalização: </label>
                    <b>{moment(automationLogData.endDate).format('DD/MM/YYYY HH:mm:ss.SSS')}</b>
                </p>
                <p>
                    <label>Duração: </label>
                    <b>{moment.utc(moment(automationLogData.endDate).diff(moment(automationLogData.startDate))).format('HH:mm:ss.SSS')}</b>
                </p>
            </div>}

            <Collapse>
                {panels}
            </Collapse>
        </ProCard>
    );
};
