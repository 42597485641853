import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeCompanySelection } from "../../../auth/actions/userActions";
import { useHistory } from "react-router-dom";

const SelectorUnit = () => {
    const companies = useSelector((state) => state.user.companies);
    const company = useSelector((state) => state.user.company);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleChangeUnit = useCallback(
        (item) => {
            if (item.unitId !== company.unitId) {
                dispatch(changeCompanySelection(item, history));
            }
        },
        [company, dispatch, history]
    );

    const menu = (
        <Menu>
            <Menu.Item key="title" disabled>
                Selecione sua unidade
            </Menu.Item>
            {companies.map((item) => (
                <Menu.Item key={item.unitId}>
                    <button
                        type="button"
                        style={{ all: "unset", cursor: "pointer",color:'black' }}
                        onClick={() => handleChangeUnit(item)}
                    >
                        <b>{item.companyName}</b> - <b>{item.unitName}</b>
                    </button>
                </Menu.Item>
            ))}
        </Menu>
    );

    return companies.length > 1 ? (
        <Dropdown overlay={menu} placement="bottomLeft">
            <button type="button" style={{ all: "unset", cursor: "pointer",color:'white' }}>
                <Space>
                    {company ? (
                        <>
                            <b>{company.companyName}</b> - <b>{company.unitName}</b>
                        </>
                    ) : (
                        <p>Selecione sua unidade</p>
                    )}
                    <DownOutlined />
                </Space>
            </button>
        </Dropdown>
    ) : (
        <p>
            {company ? (
                <span style={{color:"white"}}>
                    <b>{company.companyName}</b> - <b>{company.unitName}</b>
                </span>
            ) : (
                "Nenhuma unidade disponível"
            )}
        </p>
    );
};

export default SelectorUnit;
