import React, {useEffect, useState, useRef, Fragment} from "react";
import {useParams, useHistory} from "react-router-dom";
import {createStandaloneToast} from "@chakra-ui/react";
import {
    Layout,
    PageHeader,
    Tabs,
    Button,
    Statistic,
    Descriptions,
    List,
    Modal,
    Tooltip,
    Skeleton,
    Input,
    Form,
    Select,
    Table,
    Row,
    Col,
    Empty,
    Dropdown,
    Menu,
    Tag,
    Radio,
    notification,
    Space,
    Checkbox,
    Avatar,
    Divider,
    Switch, Card,
} from "antd";
import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";
import {
    EditOutlined,
    DeleteOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    WarningOutlined,
    MenuOutlined,
    PlusOutlined,
    CloudSyncOutlined,
} from "@ant-design/icons";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import CheckBoxForm from "components/Forms/SignIn/Fields/New/CheckBoxForm";
import {LayoutTopSide} from "components/NavBar/styles";
import TreeTransfer from "components/Forms/SignIn/Fields/Ref/TransferTree.ref";
import {default as TreeTransferLookup} from "components/Forms/SignIn/Fields/Lookup/TransferTree.lookup";


/**
 * Actions
 */
import {
    dropObj,
    dropObjField,
    dropArchivedObjField,
    editfieldObj,
    addFields,
    allFieldsByName,
    alterpos,
    allCategoriesObj,
    allCategoriesOfObj,
    pushCategoryInObj,
    pullCategoryInObj,
    findObject,
    changeConfirmationInObj,
    getRefFieldsByData,
    updateMetadataOfObj,
    getMetadataOfObj,
    verifyCanDeleteField
} from "auth/actions/objActions";

/**
 * Utils
 */
import {
    handleCkHidden,
    handleRfHidden,
    handleDtHidden,
    handleLkHidden,
    handleDtRules,
    removeRef,
    removeLk, handleFormulaHidden,
} from "utils/form-params";
import {filesHistoryByForm} from "auth/actions/importActions";
import uuid from "react-uuid";
import {useSelector} from "react-redux";
import {Formula} from "../../../components/Forms/SignIn/Fields/Formula";
import {validateFormula} from "../../../ducks/dataOfForm";
import {checkPermission, getAllPermissionsByUnit} from "../../../utils/permission";

/**
 * Misc
 */
const {TabPane} = Tabs;
const {confirm} = Modal;
const {Option, OptGroup} = Select;
export default function Details() {
    return <SortableTable/>;
}
const DragHandle = sortableHandle(({active}) => (
    <MenuOutlined style={{cursor: "grab", color: active ? "blue" : "#999"}}/>
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

// Função de pausa assíncrona para aguardar 1 segundo
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function SortableTable() {
    const history = useHistory();
    const {name, name: objFormName} = useParams();
    const user = useSelector((state) => state.session.user);
    const permissions = useSelector((state) => state.user.permissions);
    const company = useSelector((state) => state.user.company);

    const [formEditHasError, setFormEditHasError] = useState(false);
    const [formEdit] = Form.useForm();
    const [form] = Form.useForm();
    const formValues = Form.useWatch("fields", form);
    const [formDetails, setFormDetails] = useState([]);
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [selectedForm, setSelectedForm] = useState([]);
    const [dataSource, setDataSource] = useState(data);
    const [editingField, setEditingField] = useState(null);
    const [addisVisible, setaddisVisible] = useState(false);
    const [isEditing, setisEditing] = useState(false);
    const [isHaveData, setIsHaveData] = useState(false);
    const [ischeckbox, setischeckbox] = useState(false);
    const [isref, setisref] = useState(false);
    const [islookup, setislookup] = useState(false);
    const [visible, setVisible] = useState(false);
    const [permissionsFromForm, setPermissionsFromForm] = useState(null);


    const [isModalCanNotDeleteVisible, setModalCanNotDeleteVisible] = useState(false);
    const [fieldCanNotDelete, setFieldCanNotDelete] = useState([]);
    const [fieldOrFOrmCanNotDelete, setFieldOrFOrmCanNotDelete] = useState(1);
    // editar formulario
    const [isEditingForm, setisEditingForm] = useState(false);
    const [category, setCategory] = useState([]);
    const [categories, setCategories] = useState([]);

    // importações
    const [isImportsForm, setIsImportsForm] = useState(false);
    const [filesHistory, setFilesHistory] = useState([]);

    /**
     * Traduz nome do tipo do campo
     * @param {*} type
     * @returns
     */
    function getFieldType(type) {
        if (type || type) {
            switch (type) {
                case "pieSimple":
                    return "Gráfico de setores circular";
                case "Text":
                    return "Texto";
                case "Numeric":
                    return "Númerico";
                case "Date":
                    return "Data";
                case "Time":
                    return "Tempo";
                case "uniqueSelection":
                    return "Seleção única";
                case "multipleSelection":
                    return "Múltipla seleção";
                case "refUniqueSelection":
                    return "Seleção única referenciada";
                case "refMultipleSelection":
                    return "Múltipla seleção referenciada";
                case "refLookup":
                    return "Consulta";
                case "formula":
                    return "Fórmula";
                default:
                    return "Padrão";
            }
        }
    }

    /**
     * Tenta encontrar o nome do campo com base no ID do filho
     * @param {*} id
     * @returns
     */
    function findFieldName(fields, id) {
        for (const father of fields) {
            if (father.checkValueIn) {
                for (const child of father.checkValueIn) {
                    if (child.field === id) {
                        return `(${father.field.replace(" (Arquivado)", "")})`;
                    }
                }
            }
        }
        return "N/A";
    }

    /**
     * Tabela de formulários
     * @returns
     */
    const hasPermissionToEditField = company && formDetails.length > 0 && checkPermission(permissionsFromForm,'EDIT_FIELD_IN_FORM',company.unitId,'form',formDetails[0]._id)
    const hasPermissionToRemoveField = company && formDetails.length > 0 && checkPermission(permissionsFromForm,'DELETE_FIELD_IN_FORM',company.unitId,'form',formDetails[0]._id)
    const hasPermissionToOrderFieldsInForm = company && formDetails.length > 0 && checkPermission(permissionsFromForm,'ORDER_FIELDS_IN_FORM',company.unitId,'form',formDetails[0]._id)
    const hasPermissionToEditForm = company && formDetails.length > 0 && checkPermission(permissionsFromForm,'EDIT_FORM',company.unitId,'form',formDetails[0]._id)
    const hasPermissionToDeleteForm = company && formDetails.length > 0 && checkPermission(permissionsFromForm,'DELETE_FORM',company.unitId,'form',formDetails[0]._id)
    const hasPermissionToImportDataInForm = company && formDetails.length > 0 && checkPermission(permissionsFromForm,'IMPORT_DATA_IN_FORM',company.unitId,'form',formDetails[0]._id)
    const hasPermissionToViewDataArchivedInForm = company && formDetails.length > 0 && checkPermission(permissionsFromForm,'VIEW_DATA_ARCHIVED_FROM_FORM',company.unitId,'form',formDetails[0]._id)

    const getColumns = () => {
        return [
            ...(hasPermissionToOrderFieldsInForm ? [{
                title: "Posição",
                dataIndex: "",
                width: 30,
                className: "drag-visible",
                render: (d, dd, i) => (
                    <>
                        <DragHandle active={selectedItems.includes(i)}/>
                    </>
                ),
            }] : []),
            {
                title: "Nome",
                dataIndex: "field",
                key: "field",
                className: "drag-visible",
                render: (text, record) => {
                    const fatherFieldName = findFieldName(data, record._id);
                    if (record.isReadOnly && fatherFieldName) {
                        return (
                            <a>
                                {text} {fatherFieldName}
                            </a>
                        );
                    } else {
                        return <a>{text}</a>;
                    }
                },
            },
            {
                title: "Dica",
                dataIndex: "dica",
                key: "dica",
                render: (text) => <span>{text}</span>,
            },
            {
                title: "Tipo",
                dataIndex: "fieldtype",
                key: "fieldtype",
                className: "drag-visible",
                render: (text) => <span>{getFieldType(text)}</span>,
            },
            {
                title: "Editar",
                dataIndex: "field",
                key: "field",
                className: "drag-visible",
                render: (text, record, index) => {
                    if(!hasPermissionToEditField){
                        return null;
                    }
                    return (
                        <div>
                            <Tooltip
                                title={
                                    record.isReadOnly || record.isDeleted
                                        ? null
                                        : "Deseja alterar este campo?"
                                }
                                placement="left"
                            >
                                <Button
                                    shape="circle"
                                    icon={<EditOutlined/>}
                                    onClick={() => {
                                        if (record.refFields || record.getValueOf) {
                                            if (_.size(record.refFields) > 0) {
                                                setisref(true);
                                            } else {
                                                setisref(false);
                                            }
                                            if (_.size(record.getValueOf) > 0) {
                                                setislookup(true);
                                            } else {
                                                setislookup(false);
                                            }
                                        } else {
                                            setisref(false);
                                            setislookup(false);
                                        }
                                        editField(record);
                                    }}
                                    disabled={
                                        record.isReadOnly || record.isDeleted
                                            ? record.isReadOnly || record.isDeleted
                                            : false
                                    }
                                    style={{marginRight: 10}}
                                />
                            </Tooltip>
                        </div>
                    );
                },
            },
            {
                title: "Excluir",
                dataIndex: "field",
                key: "field",
                className: "drag-visible",
                render: (text, record, index) => {
                    if(!hasPermissionToRemoveField){
                        return null;
                    }
                    return (
                        <div>
                            <Tooltip
                                title={record.isReadOnly ? null : "Deseja remover este campo?"}
                                placement="left"
                            >
                                <Button
                                    shape="circle"
                                    icon={<DeleteOutlined/>}
                                    style={{marginRight: 10}}
                                    onClick={() => {
                                        // Verifica se campo está arquivado
                                        if (record.isDeleted) {
                                            confirm({
                                                title: "Deseja realmente excluir esse campo arquivado?",
                                                icon: <ExclamationCircleOutlined/>,
                                                content:
                                                    "Se confirmado essa açāo é irreversível e todos os dados nessa coluna serão excluidos.",
                                                onOk() {
                                                    removeArchivedField(record);
                                                },
                                            });
                                        } else {
                                            removeField(record);
                                        }
                                    }}
                                    disabled={record.isReadOnly ? record.isReadOnly : false}
                                />
                            </Tooltip>
                        </div>
                    );
                },
            },
        ];
    };

    const merge = (a, b, i = 0) => {
        let aa = [...a];
        return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        let tempDataSource = dataSource;
        if (oldIndex !== newIndex) {
            if (!selectedItems.length) {
                let movingItem = tempDataSource[oldIndex];
                tempDataSource.splice(oldIndex, 1);
                tempDataSource = merge(tempDataSource, [movingItem], newIndex);
            } else {
                let filteredItems = [];
                selectedItems.forEach((d) => {
                    filteredItems.push(tempDataSource[d]);
                });
                let newData = [];
                tempDataSource.forEach((d, i) => {
                    if (!selectedItems.includes(i)) {
                        newData.push(d);
                    }
                });
                tempDataSource = [...newData];
                tempDataSource = merge(tempDataSource, filteredItems, newIndex);
            }
            const updatedValues = tempDataSource.map((campos, idx) => {
                return {...campos, index: idx};
            });
            // Mantem os dados limpos no indice de campos do formulário
            alterpos({
                formName: decodeURIComponent(name),
                fields: updatedValues,
                sortedBy: user.sub
            });
            // Salva todos os campos no metadata do formulário
            updateMetadataOfObj({formName: name, fields: updatedValues});
            setDataSource(updatedValues);
            setSelectedItems([]);
        }
    };

    const DraggableContainer = (props) => (
        <SortableContainer

            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(
            (x) => x.index === restProps["data-row-key"]
        );
        return (
            <SortableItem
                index={index}
                {...restProps}
                selected={selectedItems.length}
                onClick={(e) => {
                    if (e.ctrlKey || e.metaKey) {
                        selectedItems.includes(index)
                            ? selectedItems.splice(selectedItems.indexOf(index), 1)
                            : selectedItems.push(index);
                        setSelectedItems(selectedItems);
                    } else {
                        setSelectedItems([]);
                    }
                }}
            />
        );
    };

    /**
     * Tipo de campo:
     * Checkbox: multipleSelection || uniqueSelection
     */
    function handleEditChange(e) {
        if (e === "multipleSelection" || e === "uniqueSelection") {
            setischeckbox(true);
        } else {
            setischeckbox(false);
        }
        setEditingField((pre) => {
            return {...pre, fieldtype: e};
        });
    }

    /**
     * Classifica e organiza objetos
     * @param {*} values
     * @returns
     */
    function trySortObjects(values) {
        return _(values)
            .map((value, key) => {
                switch (value.fieldtype) {
                    case "refLookup":
                        return {
                            ...value,
                            index: Number(value.index),
                            field: value.field,
                            fieldtype: value.fieldtype,
                            checkBox: value.checkBox
                                ? value.checkBox.reduce(
                                    (obj, item) =>
                                        Object.assign(obj, {
                                            [Object.keys(obj).length]: item.currentValue,
                                        }),
                                    {}
                                )
                                : null,
                            getValueOf: value.getValueOf,
                            checkValueIn: value.checkValueIn,
                            dica: value.dica,
                            sufixo: value.sufixo,
                            autoFill: value.autoFill,
                            marker: value.marker,
                            returntype: value.returntype
                        };
                    default:
                        return {
                            ...value,
                            index: Number(value.index),
                            field: value.field,
                            fieldtype: value.fieldtype,
                            checkBox: value.checkBox
                                ? value.checkBox.reduce(
                                    (obj, item) =>
                                        Object.assign(obj, {
                                            [Object.keys(obj).length]: item.currentValue,
                                        }),
                                    {}
                                )
                                : null,
                            refFields: value.refOpt,
                            dica: value.dica,
                            sufixo: value.sufixo,
                            autoFill: value.autoFill,
                            marker: value.marker,
                            returntype: value.returntype
                        };
                }
            })
            .value();
    }

    /**
     * Adiciona campo ao formulário
     * @param {*} values
     */
    const onFinish = async (values) => {
        function sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }

        if (!isEditing) {
            let posicao = data.length + 1;
            const updatedValues = values.fields.map((campos, idx) => {
                posicao++;
                return {...campos, index: posicao};
            });

            const filteredValues = trySortObjects(updatedValues);
            if (values.fields.length > 0) {
                confirm({
                    title: "Confirmar criação desse(s) campo(s)?",
                    icon: <ExclamationCircleOutlined/>,
                    content:
                        "Revise seus campos antes da confirmação de sua criação, lembrando que não é possível alterá-los depois de criado!",
                    okText: "Confirmar",
                    cancelText: "Cancelar",
                    onOk: () => {
                        addFields({
                            formName: decodeURIComponent(name),
                            fields: filteredValues,
                            createdBy: user.sub
                        }).then(async () => {
                            await sleep(1500);
                            window.location.reload();
                        });
                        setaddisVisible(false);
                    }
                });
            }
        } else if (isEditing) {
            values = formEdit.getFieldValue();
            var oldValues = editingField;
            const checkbox = values.checkBox;
            const checkboxvalues = checkbox?.map((item) => {
                return item.currentValue;
            });
            const objetoBox = checkboxvalues?.reduce(
                (obj, item) =>
                    Object.assign(obj, {
                        [Object.keys(obj).length]: item,
                    }),
                {}
            );
            // TODO: Encontrar melhor forma de tratar as alteracões do referenciado
            oldValues.refFields = values.refOpt;
            const updatedvalues = {
                ...oldValues,
                index: oldValues.index,
                checkBox: objetoBox,
                oldProps: {
                    getValueOf: null,
                    checkValueIn: null,
                },
                //para campos que não possui essa propiedade seja tratada como false
                requiredField: !!oldValues.requiredField
            };
            // Verifica se campo é lookup
            if (updatedvalues.fieldtype === "refLookup") {
                // Verifica se foi alterado os campos de retorno
                if (values.getValueOf && _.size(values.getValueOf) > 0) {
                    updatedvalues.getValueOf = values.getValueOf;
                } else {
                    updatedvalues.getValueOf = oldValues.getValueOf;
                }

                if (values.checkValueIn && _.size(values.checkValueIn) > 0) {
                    updatedvalues.checkValueIn = values.checkValueIn;
                } else {
                    updatedvalues.checkValueIn = oldValues.checkValueIn;
                }

                updatedvalues.oldProps.getValueOf = oldValues.getValueOf;
                updatedvalues.oldProps.checkValueIn = oldValues.checkValueIn;

                if (values.getValueOf || values.checkValueIn) {
                    // Sinaliza que os campos de retorno foram alterados
                    updatedvalues.isChanged = true;
                }

                // TODO: Após encontrar melhor forma de tratar as alteracões do referenciado, remova isso
                delete updatedvalues.refFields;
            }

            editfieldObj(decodeURIComponent(name), updatedvalues, user.sub).then(async () => {
                // Finaliza edição
                setisEditing(false);
                form.setFieldsValue({checkBox: [], refOpt: []}); // Limpa os campos
                formEdit.setFieldsValue({checkBox: [], refOpt: []});
                setEditingField(null);
                // Atualiza
                await sleep(600);
                window.location.reload();
            });
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 5,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 1,
                offset: 0,
            },
            sm: {
                span: 12,
                offset: 7,
            },
        },
    };

    /**
     * Lança modal de edição de categoria/confirmação
     */
    const launchEditForm = () => {
        setisEditingForm(true);
    };

    /**
     * Scroll até áncora de todos os campos
     */
    const elementRef = useRef(null);
    const scrollToBottom = () => {
        elementRef.current.scrollIntoView({behavior: "smooth"});
    };

    /**
     * Âncora para todos os campos
     */
    const AlwaysScrollToBottom = () => {
        return <div ref={elementRef}/>;
    };
    const {ToastContainer} = createStandaloneToast();

    /**
     * Detalhes básicos do formulário
     * @param {*} column
     * @returns
     */
    const renderContent = (column = 2) => (
        <Descriptions size="small" column={column}>
            <Descriptions.Item label="Nome do formulário">
                {decodeURIComponent(name).replace("obj_", "")}
            </Descriptions.Item>
            <Descriptions.Item
                label={category.length > 0 ? "Categorias" : "Categoria"}
            >
                {
                    hasPermissionToEditForm ? (<a href={() => false} onClick={launchEditForm}>
                        {category.length > 0
                            ? `${Object.values(category.map((a) => a.name)).join(", ")}`
                            : "N/A"}
                    </a>): (Object.values(category.map((a) => a.name)).join(", "))
                }
            </Descriptions.Item>
            <Descriptions.Item label="Confirmaçāo">
                {
                    hasPermissionToEditForm ? (
                        <a href={() => false} onClick={launchEditForm}>
                            {formDetails[0]?.needConfirmation ? "Sim" : "Nāo"}
                        </a>
                    ):(formDetails[0]?.needConfirmation ? "Sim" : "Nāo")
                }

            </Descriptions.Item>
        </Descriptions>
    );

    /**
     * Detalhes extras do formulário
     */
    const extraContent = (
        <div
            style={{
                display: "flex",
                width: "max-content",
                justifyContent: "flex-end",
            }}
        >
            <Statistic
                title={dataSource?.length > 0 ? "Campos" : "Campo"}
                value={`${dataSource?.length}`}
                style={{
                    marginRight: 32,
                }}
            />
            <Statistic title="Status" value="Ativo"/>
        </div>
    );

    /**
     * Detalhes gerais do formulário
     * @param {*} param
     * @returns
     */
    const Content = ({children, extra}) => (
        <div className="content">
            <div className="main">{children}</div>
            <div className="extra">{extra}</div>
        </div>
    );

    /**
     * Categoria do formulário
     */
    const categoryRender = (props) => {
        const {label, value, closable, onClose} = props;
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        const item = categories.find((x) => x._id === value);
        return (
            <Tag
                color={
                        item && item.color !== undefined
                        ? item.color.hex
                        : "#03A9F4"
                }
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginRight: 3,
                }}
            >
                {label !== undefined ? label : "#03A9F4"}
            </Tag>
        );
    };

    const [loading, setLoading] = useState({
        allFields: true,
    });

    useEffect(() => {
        if (company && permissions) {
            const formName = decodeURIComponent(name).replace("obj_", "");
            const permissionFromFormData = getAllPermissionsByUnit(permissions, company.companyId, [
                'EDIT_FORM',
                'DELETE_FORM',
                'CREATE_FIELD_IN_FORM',
                'EDIT_FIELD_IN_FORM',
                'DELETE_FIELD_IN_FORM',
                'ORDER_FIELDS_IN_FORM',
                'IMPORT_DATA_IN_FORM',
                'VIEW_DATA_ARCHIVED_FROM_FORM'
            ]).filter(item => item.context && item.context.entity_type === "form" && item.context.entity_name === formName)

            if (permissionFromFormData.length === 0) {
                history.push("/403")
            }
            setPermissionsFromForm(permissionFromFormData);
            if(checkPermission(permissionFromFormData,'IMPORT_DATA_IN_FORM',company.unitId,'form', null,formName)){
                filesHistoryByForm(decodeURIComponent(name), company.unitId).then((items) => {
                    setFilesHistory(items.message);
                });
            }
        }

    }, [permissions, company, name, history]);

    useEffect(() => {
        if (company) {
            let mounted = true;
            let loadingStates = loading;
            if (selectedForm.length && data.length > 0) {
                return;
            }
            /**
             * Campos do formulário
             */
            getMetadataOfObj({formName: name}).then((items) => {
                if (selectedForm.length && data.length > 0) {
                    return;
                }
                if (mounted) {
                    loadingStates.allFields = false;
                    if (items.message && items.message.length > 0) {
                        // Procura por campos excluídos
                        const currentFields = items.message.map((x) => {
                            if (x.isDeleted) {
                                return {
                                    ...x,
                                    field: `${x.field} (Arquivado)`,
                                };
                            } else {
                                return {
                                    ...x,
                                };
                            }
                        });
                        // Atualiza tabela
                        setData(currentFields || []);
                        setDataSource(currentFields || []);
                    }
                    setLoading(false);
                }
            });
            allCategoriesObj({
                companyId: company.companyId,
                unitId: company.unitId
            }).then((items) => {
                setCategories(items?.message);
                tryGetCategories();
                tryGetNeedConfirmation(decodeURIComponent(name).replace("obj_", ""));
            });

            return () => (mounted = false);
        }
    }, [company, selectedForm]);

    /**
     * Modal de edição do campo
     */
    function editField(record) {
        if (
            record?.fieldtype === "multipleSelection" ||
            record?.fieldtype === "uniqueSelection"
        ) {
            setischeckbox(true);
        } else {
            setischeckbox(false);
        }

        setEditingField({...record});
        setFormEditHasError(false)
        setisEditing(true);
        formEdit.setFieldsValue({
            ...record,
            checkBox: _.values(record.checkBox).map((value) => ({
                currentValue: value,
            })),
            refOpt: record.refFields,
        });
    }

    /**
     * Atualizar campo do formulário
     */
    function sendEditField() {
        form.submit();
    }

    /**
     * Remove campo do formulário
     */
    async function removeField(info) {

        const resp = await verifyCanDeleteField({index: info._index, fields: [info._id]});
        if (resp.validation) {
            confirm({
                title: "Você realmente deseja excluir esse campo?",
                icon: <ExclamationCircleOutlined/>,
                content:
                    "O campo será arquivado e se houver dados na coluna serão mantidos até que você os revise e exclua permanentemente o campo.",
                okText: "Confirmar",
                onOk() {
                    dropObjField(decodeURIComponent(name), info._id, user.sub).then(async () => {
                        await sleep(3000);
                        window.location.reload();
                    });
                },
                cancelText: "Cancelar",
            });
        } else {
            setFieldOrFOrmCanNotDelete(1);
            setModalCanNotDeleteVisible(!resp.validation)
            setFieldCanNotDelete(resp.fields.reduce((prev, next) => {
                return [
                    ...prev,
                    ...next.relationships.map(item => ({...item, fieldOrigin: next.field}))
                ];
            }, []))
        }

    }

    /**
     * Remove campos arquivados
     */
    function removeArchivedField(info) {
        dropArchivedObjField({
            formName: name,
            fields: dataSource,
            recordId: info._id,
            deletedBy: user.sub
        }).then(async () => {
            await sleep(2000);
            window.location.reload();
        });
    }

    /**
     * Remover formulário
     */
    async function removeForm() {
        const functionDelete = () => {
            confirm({
                title: "Você realmente deseja excluir este item?",
                icon: <ExclamationCircleOutlined/>,
                content: "Essa ação não pode ser desfeita.",
                okText: "Confirmar",
                onOk() {
                    dropObj(decodeURIComponent(name), user.sub).then(() => {
                        // remove categorias
                        pullCategoryInObj({
                            formName: decodeURIComponent(name),
                            categories: category.map((cat) => cat._id),
                        }).then(async () => {
                            function sleep(ms) {
                                return new Promise((resolve) => setTimeout(resolve, ms));
                            }

                            await sleep(2000);
                            history.push("/formularios/todos");
                        });
                    });
                },
                cancelText: "Cancelar",
            });
        }
        const fields = dataSource.filter(item => !item.hasOwnProperty('isReadOnly') && !item.hasOwnProperty('isReadOnly')).map(item => item._id);
        if (fields.length > 0) {
            const resp = await verifyCanDeleteField({index: name, fields});
            if (resp.validation) {
                functionDelete()
            } else {
                setFieldOrFOrmCanNotDelete(2);
                setModalCanNotDeleteVisible(!resp.validation)
                setFieldCanNotDelete(resp.fields.reduce((prev, next) => {
                    return [
                        ...prev,
                        ...next.relationships.map(item => ({...item, fieldOrigin: next.field}))
                    ];
                }, []))
            }
        } else {
            functionDelete()
        }


    }

    function tryGetCategories() {
        allCategoriesOfObj({
            formName: decodeURIComponent(name),
        }).then((values) => setCategory(values.message));
    }

    function tryGetNeedConfirmation(form) {
        findObject({
            name: form,
        }).then(async (response) => {
            setFormDetails(response.message);
        });
    }

    return (
        <Layout>
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout
                        style={{
                            padding: "0 24px 24px",
                            minHeight: "150vh",
                            maxHeight: "1000vh",
                        }}
                    >
                        <PageHeader
                            style={{margin: "10px 0 15px 0", background: "#fff"}}
                            onBack={() => window.history.back()}
                            title="Detalhes do formulário"
                            extra={[
                                <Dropdown.Button
                                    overlay={
                                        <Menu
                                            onClick={(e) => {
                                                switch (e.key) {
                                                    case "1":
                                                        setisEditingForm(true);
                                                        break;
                                                    case "2":
                                                        removeForm();
                                                        break;
                                                    case "3":
                                                        history.push(
                                                            `/formularios/lixeira/${decodeURIComponent(name)}`
                                                        );
                                                        break;
                                                    case "4":
                                                        setIsImportsForm(true);
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                            items={[
                                                ...(hasPermissionToEditForm ? [ {
                                                    key: 1,
                                                    label: "Editar",
                                                }] : []),
                                                ...(hasPermissionToViewDataArchivedInForm ? [  {
                                                    key: 3,
                                                    label: "Lixeira",
                                                }] : []),
                                                ...(hasPermissionToImportDataInForm ? [  {
                                                    key: 4,
                                                    label: "Importações",
                                                }] : []),
                                                ...(hasPermissionToDeleteForm ? [
                                                    {
                                                        key: 2,
                                                        label: "Remover",
                                                        style: {
                                                            color: "white",
                                                            backgroundColor: "red",
                                                            fontWeight: "bolder",
                                                        },
                                                    }
                                                ] : []),
                                            ]}
                                        />
                                    }
                                >
                                    Opções
                                </Dropdown.Button>,
                            ]}
                        >
                            <Content extra={extraContent}>{renderContent()}</Content>
                        </PageHeader>
                        <Skeleton loading={loading.allFields || permissionsFromForm === null} active avatar>
                            <Row gutter={[1, 16]}>
                                <Col span={24} style={{background: "#fff"}}>
                                    {/* https://github.com/ant-design/ant-design/blob/6dae4a7e18ad1ba193aedd5ab6867e1d823e2aa4/components/locale/default.tsx#L19-L37 */}
                                    <Table
                                        style={{padding: "24px"}}
                                        pagination={false}
                                        dataSource={dataSource.sort((a, b) => a.index - b.index)}
                                        columns={getColumns()}
                                        locale={{
                                            emptyText: (
                                                <Empty description="Nenhum campo adicionado."/>
                                            ),
                                        }}
                                        rowKey="index"
                                        components={hasPermissionToOrderFieldsInForm ? {
                                            body: {
                                                wrapper: DraggableContainer,
                                                row: DraggableBodyRow,
                                            },
                                        }: {}}
                                        bordered
                                    />
                                    {selectedItems.length ? (
                                        <>{selectedItems.length} items selecionados </>
                                    ) : (
                                        ""
                                    )}
                                </Col>
                                <Col span={24} style={{background: "#fff"}}>
                                    <Form.Provider
                                        onFormFinish={(name, {values, forms}) => {
                                            if (name === "addCheckBoxValue") {
                                                const {basicCheckbox} = forms;
                                                const allCheckboxes =
                                                    basicCheckbox.getFieldValue("allCheckboxes") || [];
                                                basicCheckbox.setFieldsValue({
                                                    allCheckboxes: [...allCheckboxes, values],
                                                });
                                                setVisible(false);
                                            }
                                        }}
                                    >
                                        <Form
                                            {...formItemLayout}
                                            form={form}
                                            name="basicCheckbox"
                                            onFinish={onFinish}
                                            scrollToFirstError
                                            type="flex"
                                            justify="center"
                                        >
                                            <Form.List name="fields">
                                                {(fields, {add, remove}) => (
                                                    <>
                                                        {fields.map(
                                                            ({key, name, fieldKey, ...restField}) => (
                                                                <React.Fragment key={key}>
                                                                    <Form.Item
                                                                        style={{marginTop: "3rem"}}
                                                                        {...restField}
                                                                        name={[name, "field"]}
                                                                        label="Nome do campo"
                                                                        fieldKey={[fieldKey, "field"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "Por favor insira um nome para este campo!",
                                                                            },
                                                                            {
                                                                                pattern: /[^\s]$/,
                                                                                message: "O nome não deve terminar com um espaço!",
                                                                            },
                                                                            {
                                                                                pattern: /^[^.%]*$/,
                                                                                message:
                                                                                    "Este campo não pode conter o caractere ponto (.) ou porcentagem (%)",
                                                                            },
                                                                            {
                                                                                validator: (rule, value) => {
                                                                                    if (
                                                                                        value &&
                                                                                        value.includes("(Arquivado)")
                                                                                    ) {
                                                                                        return Promise.reject(
                                                                                            "(Arquivado) não é permitido no nome do campo."
                                                                                        );
                                                                                    }
                                                                                    if (
                                                                                        value &&
                                                                                        value.includes("(arquivado)")
                                                                                    ) {
                                                                                        return Promise.reject(
                                                                                            "(arquivado) não é permitido no nome do campo."
                                                                                        );
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                },
                                                                            },
                                                                            {
                                                                                validator: (rule, value) => {
                                                                                    const nameFileds = form.getFieldsValue().fields
                                                                                        .filter(item => item.hasOwnProperty('field'))
                                                                                        .map(item => item.field.toLowerCase());

                                                                                    if (_.some(_.countBy(nameFileds), count => count > 1) && !_.isEmpty(value)) {
                                                                                        return Promise.reject(
                                                                                            "Os nomes destes campos devem ser únicos!"
                                                                                        );
                                                                                    }

                                                                                    if (data.filter(item => item.hasOwnProperty('field') && value && item.field.toLowerCase() === value.toLowerCase()).length > 0) {
                                                                                        return Promise.reject(
                                                                                            "Este campo não pode ter o nome repetido"
                                                                                        );
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="Ex: Dia de nascimento"/>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "dica"]}
                                                                        label="Dica"
                                                                        tooltip="Este campo será usado como dica de preenchimento para o usuário no aplicativo!"
                                                                        fieldKey={[fieldKey, "dica"]}
                                                                    >
                                                                        <Input
                                                                            disabled={formValues[name]?.fieldtype === "formula"}/>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "sufixo"]}
                                                                        label="Sufixo"
                                                                        tooltip="Unidade de medida para o dado adicionado pelo usuário de campo!"
                                                                        fieldKey={[fieldKey, "sufixo"]}
                                                                    >
                                                                        <Input
                                                                            disabled={formValues[name]?.fieldtype === "formula"}
                                                                            placeholder="Ex: Oz, L, Kg..."/>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "fieldtype"]}
                                                                        label="Tipo"
                                                                        fieldKey={[fieldKey, "fieldtype"]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "Precisamos saber tipo de campo!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select placeholder="Selecione tipo de campo">
                                                                            <OptGroup label="Simples">
                                                                                <Option value="Text">Texto</Option>
                                                                                <Option value="Numeric">
                                                                                    Númerico
                                                                                </Option>
                                                                                <Option value="Date">
                                                                                    Seleção de data
                                                                                </Option>
                                                                                <Option value="Time">
                                                                                    Seleção de tempo
                                                                                </Option>
                                                                                <Option value="multipleSelection">
                                                                                    Seleção multipla
                                                                                </Option>
                                                                                <Option value="uniqueSelection">
                                                                                    Seleção única
                                                                                </Option>
                                                                                <Option value="formula">
                                                                                    Fórmula
                                                                                </Option>
                                                                            </OptGroup>
                                                                            <OptGroup label="Referenciado">
                                                                                <Option value="refLookup">
                                                                                    Consultas
                                                                                </Option>
                                                                                <Option value="refMultipleSelection">
                                                                                    Seleção multipla
                                                                                </Option>
                                                                                <Option value="refUniqueSelection">
                                                                                    Seleção única
                                                                                </Option>
                                                                            </OptGroup>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "returntype"]}
                                                                        label="Tipo de Retorno"
                                                                        fieldKey={[fieldKey, "returntype"]}
                                                                        hidden={
                                                                            formValues
                                                                                ? formValues[name]?.fieldtype
                                                                                    ? handleFormulaHidden(formValues[name].fieldtype)
                                                                                    : true
                                                                                : true
                                                                        }
                                                                        rules={[
                                                                            {
                                                                                required: ["formula"].includes(formValues[name]?.fieldtype),
                                                                                message: "Por favor precisamos saber o tipo de retorno desse campo!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            placeholder="Selecione tipo de retorno do campo">
                                                                            <Option value="Text">
                                                                                Texto
                                                                            </Option>
                                                                            <Option value="Numeric">
                                                                                Númerico
                                                                            </Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name={[name, "formula"]}
                                                                        label="Fórmula"
                                                                        fieldKey={[fieldKey, "formula"]}
                                                                        rules={[
                                                                            {
                                                                                required: formValues[name]?.fieldtype === "formula",
                                                                                message: "Por favor insira uma formula para este campo!",
                                                                            },
                                                                            {
                                                                                validateTrigger: 'onSubmit',
                                                                                validator: async (_, value) => {
                                                                                    if (formValues[name]?.fieldtype === "formula") {
                                                                                        const data = await validateFormula(value, decodeURIComponent(objFormName));
                                                                                        if (data.error) {
                                                                                            let errors = [data.error];
                                                                                            if (data.data) {
                                                                                                errors = [...errors, data.data.join(", ")];
                                                                                            }
                                                                                            return Promise.reject(errors);
                                                                                        }
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                }
                                                                            }
                                                                        ]}
                                                                        hidden={
                                                                            formValues
                                                                                ? formValues[name]?.fieldtype
                                                                                    ? handleFormulaHidden(formValues[name].fieldtype)
                                                                                    : true
                                                                                : true
                                                                        }
                                                                    >
                                                                        {formValues ? (
                                                                            formValues[name]?.fieldtype ? (
                                                                                <Formula
                                                                                    fields={data.map(item => ({
                                                                                        field: item.field,
                                                                                        fieldtype: getFieldType(item.fieldtype)
                                                                                    }))}
                                                                                    form={form}
                                                                                    formName={decodeURIComponent(objFormName)}
                                                                                    getFieldType={getFieldType}
                                                                                />
                                                                            ) : null
                                                                        ) : null}
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        name={[name, "refOpt"]}
                                                                        label="Formulários e campos"
                                                                        fieldKey={[fieldKey, "refOpt"]}
                                                                        hidden={
                                                                            formValues
                                                                                ? formValues[name]?.fieldtype
                                                                                    ? handleCkHidden(
                                                                                        formValues[name].fieldtype
                                                                                    )
                                                                                    : true
                                                                                : true
                                                                        }
                                                                    >
                                                                        {formValues ? (
                                                                            formValues[name]?.fieldtype ? (
                                                                                <TreeTransfer
                                                                                    form={form}
                                                                                    type={removeRef(
                                                                                        formValues[name].fieldtype
                                                                                    )}
                                                                                    refresh={true}
                                                                                />
                                                                            ) : null
                                                                        ) : null}
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        name={[name, "getValueOf"]}
                                                                        label="Pesquise"
                                                                        tooltip="Campo chave que será usado na pesquisa para verificar os dados selecionados."
                                                                        fieldKey={[fieldKey, "getValueOf"]}
                                                                        hidden={
                                                                            formValues
                                                                                ? formValues[name]?.fieldtype
                                                                                    ? handleLkHidden(
                                                                                        formValues[name].fieldtype
                                                                                    )
                                                                                    : true
                                                                                : true
                                                                        }
                                                                    >
                                                                        {formValues ? (
                                                                            formValues[name]?.fieldtype ? (
                                                                                <TreeTransferLookup
                                                                                    form={form}
                                                                                    formName={decodeURIComponent(
                                                                                        objFormName
                                                                                    )}
                                                                                    type={removeLk(
                                                                                        formValues[name].fieldtype
                                                                                    )}
                                                                                    single={true}
                                                                                    lookup={true}
                                                                                    refresh={true}
                                                                                />
                                                                            ) : null
                                                                        ) : null}
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name={[name, "checkValueIn"]}
                                                                        label="Retorne"
                                                                        tooltip="Valor que será retornado na consulta."
                                                                        fieldKey={[fieldKey, "checkValueIn"]}
                                                                        hidden={
                                                                            formValues
                                                                                ? formValues[name]?.fieldtype
                                                                                    ? handleLkHidden(
                                                                                        formValues[name].fieldtype
                                                                                    )
                                                                                    : true
                                                                                : true
                                                                        }
                                                                    >
                                                                        {formValues ? (
                                                                            formValues[name]?.fieldtype ? (
                                                                                <TreeTransfer
                                                                                    form={form}
                                                                                    formName={decodeURIComponent(
                                                                                        objFormName
                                                                                    )}
                                                                                    type={removeRef(
                                                                                        formValues[name].fieldtype
                                                                                    )}
                                                                                    refresh={true}
                                                                                    removeSelected={true}
                                                                                />
                                                                            ) : null
                                                                        ) : null}
                                                                    </Form.Item>

                                                                    <Form.Item
                                                                        wrapperCol={{span: 24, offset: 5}}
                                                                        hidden={
                                                                            formValues
                                                                                ? formValues[name]?.fieldtype
                                                                                    ? handleRfHidden(
                                                                                        formValues[name].fieldtype
                                                                                    )
                                                                                    : true
                                                                                : true
                                                                        }
                                                                    >
                                                                        <CheckBoxForm fieldKey={name}/>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "marker"]}
                                                                        label="Etiqueta"
                                                                        tooltip="Destaca o campo na lista de lançamentos do aplicativo."
                                                                        fieldKey={[fieldKey, "marker"]}
                                                                        rules={
                                                                            formValues
                                                                                ? formValues[name]?.fieldtype
                                                                                    ? handleDtRules(
                                                                                        formValues[name].fieldtype
                                                                                    )
                                                                                    : handleDtRules()
                                                                                : handleDtRules()
                                                                        }
                                                                    >
                                                                        <Radio.Group
                                                                            disabled={
                                                                                formValues
                                                                                    ? formValues[name]?.fieldtype
                                                                                        ? handleDtHidden(
                                                                                            formValues[name].fieldtype
                                                                                        )
                                                                                        : null
                                                                                    : null
                                                                            }
                                                                        >
                                                                            <Radio value={true}>Sim</Radio>
                                                                            <Radio value={false}>Não</Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "autoFill"]}
                                                                        label="Preenchimento"
                                                                        tooltip="Campo será preenchido com último registro enviado!"
                                                                        fieldKey={[fieldKey, "autoFill"]}
                                                                        rules={[
                                                                            {
                                                                                required: formValues[name]?.fieldtype !== "formula",
                                                                                message: "Precisamos saber se você deseja que este campo preenchido com último registro enviado!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Radio.Group
                                                                            disabled={formValues[name]?.fieldtype === "formula"}>
                                                                            <Radio value={true}>Sim</Radio>
                                                                            <Radio value={false}>Não</Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "requiredField"]}
                                                                        label="Campo obrigatório"
                                                                        tooltip="Campo obrigatório para preenchimento no formulário enviado!"
                                                                        fieldKey={[fieldKey, "requiredField"]}
                                                                        rules={[
                                                                            {
                                                                                required: formValues[name]?.fieldtype !== "formula",
                                                                                message: "Precisamos saber se você deseja que este campo sejá obrigatório ou não!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Radio.Group
                                                                            disabled={formValues[name]?.fieldtype === "formula"}>
                                                                            <Radio value={true}>Sim</Radio>
                                                                            <Radio value={false}>Não</Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...tailFormItemLayout}
                                                                        wrapperCol={{span: 24, offset: 5}}
                                                                    >
                                                                        <Button
                                                                            type="danger"
                                                                            onClick={() => remove(name)}
                                                                            icon={<MinusCircleOutlined/>}
                                                                        >
                                                                            Remover campo
                                                                        </Button>
                                                                    </Form.Item>
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                        {
                                                            company && formDetails.length > 0 && checkPermission(permissionsFromForm, 'CREATE_FIELD_IN_FORM', company.unitId, 'form', formDetails[0]._id) && (
                                                                <List.Item
                                                                    style={{
                                                                        paddingTop: "1rem",
                                                                        paddingBottom: "1rem",
                                                                        marginLeft: "1.5rem",
                                                                        marginRight: "1.5rem",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() => {
                                                                            add();
                                                                            setaddisVisible(true);
                                                                            scrollToBottom();
                                                                        }}
                                                                        block
                                                                        icon={<PlusOutlined/>}
                                                                    >
                                                                        Adicionar campo
                                                                    </Button>
                                                                    <AlwaysScrollToBottom/>
                                                                </List.Item>
                                                            )
                                                        }

                                                    </>
                                                )}
                                            </Form.List>
                                            <List.Item
                                                style={
                                                    addisVisible === false
                                                        ? {display: "none"}
                                                        : {display: "flex", paddingTop: 0}
                                                }
                                            >
                                                <Button
                                                    style={{
                                                        marginBottom: ".5rem",
                                                        marginLeft: "1.5rem",
                                                        marginRight: "1.5rem",
                                                    }}
                                                    block
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Adicionar campos ao formulário
                                                </Button>
                                            </List.Item>
                                            {/* Edição do campo */}
                                            <Modal
                                                width={900}
                                                okButtonProps={{
                                                    disabled: formEditHasError
                                                }}
                                                title="Editar campo"
                                                visible={isEditing}
                                                cancelText="Cancelar"
                                                onCancel={() => {
                                                    setisEditing(false);
                                                    // limpa os campos
                                                    form.setFieldsValue({checkBox: [], refOpt: []}); // Clear the form list
                                                    formEdit.setFieldsValue({checkBox: [], refOpt: []});
                                                    setEditingField(null);
                                                }}
                                                okText="Salvar"
                                                htmlType="submit"
                                                onOk={() => {
                                                    formEdit.submit();
                                                }}
                                            >
                                                <Form
                                                    form={formEdit}
                                                    name="basicCheckbox"
                                                    onFinish={() => {
                                                        sendEditField();
                                                    }}
                                                    onFieldsChange={() => {
                                                        let errors = formEdit.getFieldsError();
                                                        let hasErrors = errors.some(fieldError => !!fieldError.errors.length);
                                                        setFormEditHasError(hasErrors)
                                                    }}
                                                >
                                                    <p>Nome do campo</p>
                                                    <Form.Item
                                                        name={"field"}
                                                        style={{width: "100%", marginBottom: 0}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Por favor insira um nome para este campo!",
                                                            },
                                                            {
                                                                pattern: /[^\s]$/,
                                                                message: "O nome não deve terminar com um espaço!",
                                                            },
                                                            {
                                                                pattern: /^[^.%]*$/,
                                                                message:
                                                                    "Este campo não pode conter o caractere ponto (.) ou porcentagem (%)",
                                                            },
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (
                                                                        value &&
                                                                        value.includes("(Arquivado)")
                                                                    ) {
                                                                        return Promise.reject(
                                                                            "(Arquivado) não é permitido no nome do campo."
                                                                        );
                                                                    }
                                                                    if (
                                                                        value &&
                                                                        value.includes("(arquivado)")
                                                                    ) {
                                                                        return Promise.reject(
                                                                            "(arquivado) não é permitido no nome do campo."
                                                                        );
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                            {
                                                                validator: (rule, value) => {
                                                                    if (!_.isEmpty(value) && _.isEmpty(value.trim())) {
                                                                        return Promise.reject(
                                                                            "Este campo não pode ter o nome vazio ou somente espaços!"
                                                                        );
                                                                    }
                                                                    if (data.filter(item => item.field.toLowerCase() == value.toLowerCase() && item._id != editingField._id).length > 0) {
                                                                        return Promise.reject(
                                                                            "Este campo não pode ter o nome repetido!"
                                                                        );
                                                                    }
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{marginBottom: 15}}
                                                            value={editingField?.field}
                                                            onChange={(e) => {
                                                                setEditingField((prev) => ({
                                                                    ...prev,
                                                                    field: e.target.value,
                                                                }));
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <p>Dica</p>
                                                    <Input
                                                        style={{marginBottom: 15}}
                                                        value={editingField?.dica}
                                                        onChange={(e) => {
                                                            setEditingField((pre) => {
                                                                return {...pre, dica: e.target.value};
                                                            });
                                                        }}
                                                        disabled={editingField?.fieldtype === "formula"}
                                                    />
                                                    <p>Sufixo</p>
                                                    <Input
                                                        style={{marginBottom: 15}}
                                                        value={editingField?.sufixo}
                                                        onChange={(e) => {
                                                            setEditingField((pre) => {
                                                                return {...pre, sufixo: e.target.value};
                                                            });
                                                        }}
                                                        disabled={editingField?.fieldtype === "formula"}
                                                    />
                                                    <p>Tipo do campo</p>
                                                    <Select
                                                        style={{width: "100%", marginBottom: 15}}
                                                        value={editingField?.fieldtype}
                                                        onChange={(e) => {
                                                            handleEditChange(e);
                                                        }}
                                                        placeholder="Selecione tipo do campo"
                                                        disabled={true}
                                                    >
                                                        <Option value="Text">Texto</Option>
                                                        <Option value="Numeric">Númerico</Option>
                                                        <Option value="multipleSelection">
                                                            Seleção Multipla
                                                        </Option>
                                                        <Option value="refMultipleSelection">
                                                            Seleção Multipla Referenciada
                                                        </Option>
                                                        <Option value="refUniqueSelection">
                                                            Seleção Única Referenciada
                                                        </Option>
                                                        <Option value="uniqueSelection">Seleção Única</Option>
                                                        <Option value="Date">Seleção de Data</Option>
                                                        <Option value="Time">Seleção de tempo</Option>
                                                        <Option value="refLookup">Consulta</Option>
                                                        <Option value="formula">Fórmula</Option>
                                                    </Select>
                                                    <p>Tipo de Retorno</p>
                                                    <Select
                                                        style={{width: "100%", marginBottom: 15}}
                                                        placeholder="Selecione tipo de retorno do campo"
                                                        disabled={true}
                                                        value={editingField?.returntype}
                                                    >
                                                        <Option value="Text">
                                                            Texto
                                                        </Option>
                                                        <Option value="Numeric">
                                                            Númerico
                                                        </Option>
                                                    </Select>
                                                    <p>Fórmula</p>
                                                    <Form.Item
                                                        name={"formula"}
                                                        style={{width: "100%"}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                        hidden={editingField?.fieldtype !== "formula"}
                                                        rules={[
                                                            {
                                                                required: editingField?.fieldtype === "formula",
                                                                message: "Por favor insira uma fórmula para este campo!",
                                                            },
                                                            {
                                                                validateTrigger: 'onSubmit',
                                                                validator: async function (_, value) {
                                                                    if (editingField?.fieldtype === "formula") {
                                                                        const data = await validateFormula(value, decodeURIComponent(objFormName));
                                                                        if (data.error) {
                                                                            let errors = [data.error];
                                                                            if (data.data) {
                                                                                errors = [...errors, data.data.join(", ")];
                                                                            }
                                                                            return Promise.reject(errors);
                                                                        }
                                                                    }

                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                        ]
                                                        }
                                                    >
                                                        <Formula
                                                            form={formEdit}
                                                            code={editingField?.formula}
                                                            onChange={(value) => {
                                                                setEditingField((pre) => {
                                                                    return {...pre, formula: value};
                                                                });
                                                            }}
                                                            formName={decodeURIComponent(
                                                                objFormName
                                                            )}
                                                            field={editingField?.field}
                                                            fields={data.map(item => ({
                                                                field: item.field,
                                                                fieldtype: getFieldType(item.fieldtype)
                                                            }))}
                                                            getFieldType={getFieldType}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{width: "100%", marginBottom: 0}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                        hidden={!ischeckbox}
                                                    >
                                                        <Form.List name="checkBox">
                                                            {(checkBox, {add, remove}) => {
                                                                return (
                                                                    <div>
                                                                        {checkBox.map((currentValue, index2) => (
                                                                            <Space
                                                                                key={currentValue.key}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    marginBottom: 8,
                                                                                }}
                                                                                align="start"
                                                                            >
                                                                                <Form.Item
                                                                                    {...currentValue}
                                                                                    name={[
                                                                                        currentValue.name,
                                                                                        "currentValue",
                                                                                    ]}
                                                                                    fieldKey={[
                                                                                        currentValue.fieldKey,
                                                                                        "currentValue",
                                                                                    ]}
                                                                                    key={index2}
                                                                                    // noStyle
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: "Este campo é obrigatório",
                                                                                        },
                                                                                        {
                                                                                            validator: (_, value) => {
                                                                                                if (
                                                                                                    value &&
                                                                                                    value.includes(",")
                                                                                                ) {
                                                                                                    return Promise.reject(
                                                                                                        "O texto não pode conter vírgulas!"
                                                                                                    );
                                                                                                }
                                                                                                return Promise.resolve();
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            validator: (_, value) => {
                                                                                                if (
                                                                                                    value &&
                                                                                                    value.includes(";")
                                                                                                ) {
                                                                                                    return Promise.reject(
                                                                                                        "O texto não pode conter pontos e vírgulas!"
                                                                                                    );
                                                                                                }
                                                                                                return Promise.resolve();
                                                                                            },
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Input/>
                                                                                </Form.Item>
                                                                                <MinusCircleOutlined
                                                                                    style={{
                                                                                        paddingTop: 8,
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        remove(currentValue.name)
                                                                                    }
                                                                                />
                                                                            </Space>
                                                                        ))}
                                                                        <Form.Item>
                                                                            <Button type="dashed" onClick={() => add()}>
                                                                                <PlusOutlined/> Adicionar item
                                                                            </Button>
                                                                        </Form.Item>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Form.List>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="refOpt"
                                                        style={{width: "100%"}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                        hidden={!isref}
                                                    >
                                                        {isref && (
                                                            <TreeTransfer
                                                                form={form}
                                                                type={`${editingField?.fieldtype
                                                                    .substring(3)
                                                                    .charAt(0)
                                                                    .toLowerCase()}${editingField?.fieldtype
                                                                    .substring(3)
                                                                    .substring(1)}`}
                                                                selected={editingField?.refFields}
                                                                refresh={true}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={"getValueOf"}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                        hidden={
                                                            editingField
                                                                ? editingField?.fieldtype
                                                                    ? handleLkHidden(editingField?.fieldtype)
                                                                    : true
                                                                : true
                                                        }
                                                    >
                                                        {editingField ? (
                                                            editingField?.fieldtype ? (
                                                                <TreeTransferLookup
                                                                    form={form}
                                                                    formName={decodeURIComponent(objFormName)}
                                                                    type={removeLk(editingField.fieldtype)}
                                                                    selected={editingField?.getValueOf}
                                                                    single={true}
                                                                    lookup={true}
                                                                    refresh={true}
                                                                />
                                                            ) : null
                                                        ) : null}
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="checkValueIn"
                                                        style={{width: "100%"}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                        hidden={
                                                            editingField
                                                                ? editingField?.fieldtype
                                                                    ? handleLkHidden(editingField?.fieldtype)
                                                                    : true
                                                                : true
                                                        }
                                                    >
                                                        {editingField ? (
                                                            editingField?.fieldtype ? (
                                                                <TreeTransfer
                                                                    form={form}
                                                                    formName={decodeURIComponent(objFormName)}
                                                                    type={removeRef(editingField.fieldtype)}
                                                                    selected={editingField?.checkValueIn}
                                                                    refresh={true}
                                                                    removeSelected={true}
                                                                />
                                                            ) : null
                                                        ) : null}
                                                    </Form.Item>
                                                    <p>Etiqueta</p>
                                                    <Form.Item
                                                        name={"marker"}
                                                        style={{width: "100%", marginBottom: 0}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                    >
                                                        <Radio.Group
                                                            style={{width: "100%", marginBottom: 15}}
                                                            onChange={(e) => {
                                                                setEditingField((pre) => {
                                                                    return {...pre, marker: e.target.value};
                                                                });
                                                            }}
                                                            disabled={(editingField?.fieldtype === "Date") || (editingField?.fieldtype === "Time")}
                                                        >
                                                            <Radio value={true}>Sim</Radio>
                                                            <Radio value={false}>Não</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <p>Preenchimento</p>
                                                    <Form.Item
                                                        name={"autoFill"}
                                                        style={{width: "100%", marginBottom: 0}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                    >
                                                        <Radio.Group
                                                            style={{width: "100%", marginBottom: 15}}
                                                            onChange={(e) => {
                                                                setEditingField((pre) => {
                                                                    return {...pre, autoFill: e.target.value};
                                                                });
                                                            }}
                                                            disabled={editingField?.fieldtype === "formula"}
                                                        >
                                                            <Radio value={true}>Sim</Radio>
                                                            <Radio value={false}>Não</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <p>Campo obrigatório</p>
                                                    <Form.Item
                                                        name={"requiredField"}
                                                        style={{width: "100%", marginBottom: 0}}
                                                        wrapperCol={{span: 24, offset: 0}}
                                                    >
                                                        <Radio.Group
                                                            style={{width: "100%", marginBottom: 15}}
                                                            onChange={(e) => {
                                                                setEditingField((pre) => {
                                                                    return {...pre, requiredField: e.target.value};
                                                                });
                                                            }}
                                                            disabled={editingField?.fieldtype === "formula"}
                                                        >
                                                            <Radio value={true}>Sim</Radio>
                                                            <Radio value={false}>Não</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Form>
                                            </Modal>
                                        </Form>
                                        {/* Edição do formulário */}
                                        <Modal
                                            title="Editar formulário"
                                            visible={isEditingForm}
                                            cancelText="Cancelar"
                                            onCancel={(e) => {
                                                tryGetCategories();
                                                setisEditingForm(false);
                                            }}
                                            footer={null}
                                        >
                                            <p>Categoria</p>
                                            <Select
                                                mode="multiple"
                                                defaultValue={category?.map(a => ({
                                                    value: a._id.toString(),
                                                    label: a.name
                                                }))}
                                                onSelect={(id) => {
                                                    pushCategoryInObj({
                                                        formName: decodeURIComponent(name),
                                                        categories: [id],
                                                        updatedBy: user.sub
                                                    });
                                                }}
                                                onDeselect={(id) => {
                                                    pullCategoryInObj({
                                                        formName: decodeURIComponent(name),
                                                        categories: [id],
                                                        updatedBy: user.sub
                                                    });
                                                }}
                                                style={{width: "100%", marginBottom: 15}}
                                                tagRender={categoryRender}
                                                placeholder="Selecione categoria do formulário"
                                            >
                                                {categories.map((category) => (
                                                    <Option value={category._id}>{category.name}</Option>
                                                ))}
                                            </Select>
                                            <p>Confirmaçāo</p>
                                            <Switch
                                                defaultChecked={formDetails[0]?.needConfirmation}
                                                onChange={(checked) => {
                                                    changeConfirmationInObj({
                                                        id: formDetails[0]._id,
                                                        needConfirmation: checked,
                                                        updatedBy: user.sub
                                                    }).then(() => {
                                                        // atualiza
                                                        tryGetCategories();
                                                        setFormDetails([
                                                            {
                                                                ...formDetails[0],
                                                                needConfirmation: checked,
                                                            },
                                                        ]);
                                                        // setisEditingForm(false);
                                                    });
                                                }}
                                            />
                                        </Modal>
                                    </Form.Provider>
                                </Col>
                            </Row>
                            {/* Importações */}
                            <Modal
                                title="Importações"
                                visible={isImportsForm}
                                cancelText="Cancelar"
                                onCancel={(e) => {
                                    setIsImportsForm(false);
                                }}
                                footer={null}
                            >
                                <Row>
                                    <Col span={24}>
                                        {filesHistory.length > 0 ? (
                                            <List
                                                style={{marginTop: "-.5rem"}}
                                                itemLayout="horizontal"
                                                dataSource={filesHistory.map((item, index) => ({
                                                    // Swap item and index parameters
                                                    key: index,
                                                    _id: item._id,
                                                    title: `Arquivo CSV ${
                                                        index > 0 ? `(${index + 1})` : ""
                                                    }`,
                                                    description: moment(item.createdAt).format("LLL"),
                                                }))}
                                                renderItem={(
                                                    item // Remove index parameter
                                                ) => (
                                                    <List.Item
                                                        actions={[
                                                            <Button
                                                                type="primary"
                                                                onClick={() => {
                                                                    history.push(
                                                                        `/formularios/importar/detalhes/${item._id}?form=${objFormName}`
                                                                    );
                                                                }}
                                                            >
                                                                Visualizar
                                                            </Button>,
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    icon={<CloudSyncOutlined/>}
                                                                    style={{
                                                                        backgroundColor: "#12b398",
                                                                    }}
                                                                />
                                                            }
                                                            title={
                                                                <a href="https://ant.design">{item.title}</a> // Access title property
                                                            }
                                                            description={item.description} // Access description property
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <Empty description="Nada importado até o momento..."/>
                                        )}
                                    </Col>
                                </Row>
                            </Modal>
                            <Modal
                                width={850}
                                title={
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <WarningOutlined
                                            style={{marginRight: '8px', fontSize: '20px', color: '#faad14'}}/>
                                        <span style={{marginLeft: 'auto', marginRight: 'auto'}}>Você não pode excluir esse {(fieldOrFOrmCanNotDelete === 1) ? "campo" : "formulário"}!</span>
                                    </div>
                                }
                                closable={false}
                                cancelButtonProps={{hidden: true}}
                                okText={"Confirmar"}
                                visible={isModalCanNotDeleteVisible}
                                onOk={() => {
                                    setModalCanNotDeleteVisible(false);
                                    setFieldCanNotDelete(null);
                                    setFieldOrFOrmCanNotDelete(1);
                                }}>
                                <div>

                                    {(fieldOrFOrmCanNotDelete === 1) ?
                                        <p>Este campo está relacionado com outros campos de outros formulários. Para
                                            arquivar, você deve
                                            desfazer as relações antes de excluir esse campo!</p> :
                                        <p>Este formulário possui campo ou campos que estão relacionado com outros
                                            campos de outros formulários. Para arquivar, você deve
                                            desfazer as relações antes de excluir esse campo!</p>}

                                    {
                                        <Table columns={[
                                            {
                                                align: 'center',
                                                title: 'Campo deste Formulário',
                                                dataIndex: 'fieldOrigin',
                                                key: 'fieldOrigin',
                                                responsive: ['md'],
                                            },
                                            {
                                                align: 'center',
                                                title: 'Formulário de Relacionamento',
                                                dataIndex: 'form',
                                                key: 'form',
                                                render: (text, record) => (
                                                    <a href={`/formularios/detalhes/${text}`}
                                                       target={"_blank"}>{decodeURIComponent(text).replace("obj_", "")}</a>
                                                ),
                                                responsive: ['md'],
                                            },
                                            {
                                                align: 'center',
                                                title: 'Campo',
                                                dataIndex: 'field',
                                                key: 'field',
                                                responsive: ['md'],
                                            },
                                            {
                                                align: 'center',
                                                title: 'Tipo de Campo',
                                                dataIndex: 'fieldType',
                                                key: 'fieldType',
                                                render: (text, record) => {
                                                    return getFieldType(text)
                                                },
                                                responsive: ['md'],
                                            },
                                        ]} dataSource={fieldCanNotDelete} pagination={false} scroll={true}/>
                                    }

                                </div>
                            </Modal>
                        </Skeleton>
                    </Layout>
                </Layout>
            </LayoutTopSide>
            <ToastContainer/>
        </Layout>
    );
}
