import ptBRIntl from "antd/lib/locale/pt_BR";
import {Button, ConfigProvider, message, Modal} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import React, {useRef, useState} from "react";
import {ProCard, ProTable} from "@ant-design/pro-components";
import {userApi} from "../../../services/actions/users";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";
import {RoleDetails} from "../../../components/RoleDetails";
import NewRoleModal from "../../../components/NewRoleModal";

const Role = ({}) => {
    const actionRef = useRef();
    const {company} = useSelector(state => state.user);
    const columns = [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            width: "auto",
            fieldProps: {
                placeholder: "",
            },
            sorter: true,
            ellipsis: true,
        },
        {
            title: "Atualizado",
            dataIndex: "createdAt",
            key: "createdAt",
            width: "auto",
            ellipsis: true,
            sorter: true,
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
                }
            },
            valueType: "dateTimeRange",
            fieldProps(form, config) {
                return {
                    ...config,
                    format: "DD/MM/YYYY HH:mm",
                    placeholder: "",
                };
            },

        },
        {
            title: "Criado",
            dataIndex: "updatedAt",
            key: "updatedAt",
            width: "auto",
            sorter: true,
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
                }
            },
            valueType: "dateTimeRange",
            fieldProps(form, config) {
                return {
                    ...config,
                    format: "DD/MM/YYYY HH:mm",
                    placeholder: "",
                };
            },
            ellipsis: true,
        },
        {
            title: "Ações",
            render: (dom,{id}) => {
                return [
                    <React.Fragment>
                        <Button onClick={() => fetchRoleDetails(id)}>Detalhes</Button>
                        &nbsp;
                        &nbsp;
                        <Button icon={<DeleteOutlined/>} onClick={() => handleRemoveRole(id)} type={"danger"}>Excluir Papel</Button>
                    </React.Fragment>
                ];
            },
            search: false,
            width: "auto",
            ellipsis: true,
        },

    ];

    const [role, setRole] = useState(null);
    const [open, setOpen] = useState(false);
    const [openModal,setOpenModal] = useState(false);

    const handleCloseDrawer = () => {
        setOpen(false);
        setRole(null);
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        actionRef.current?.reload();
    }


    const fetchRoleDetails = (id) => {
        setRole(id)
        setOpen(true);
    }

    const handleRemoveRole = async (roleId) => {
        Modal.confirm({
            title: `Você realmente deseja excluir esse papel?`,
            icon: <ExclamationCircleOutlined />,
            content: "Confirme a exclusão antes de continuar.",
            okText: "Confirmar",
            onOk : async () =>  {
                try {
                    await userApi.removeRole(roleId,company.unitId);
                    message.success("Papel removido com sucesso!");
                    actionRef.current?.reload();
                } catch (error) {
                    message.error(error.message || "Erro ao remover o papel.");
                }
            }
        })

    };

    return (
        <ConfigProvider locale={ptBRIntl}>
            {role && <RoleDetails roleId={role} isOpen={open} closeDrawer={handleCloseDrawer}/>}
            <NewRoleModal isOpen={openModal} closeModal={handleCloseModal}/>
            <ProCard extra={<Button icon={<PlusCircleOutlined/>} onClick={() => setOpenModal(true)}> Novo Papel</Button>}>
                <ProTable
                    actionRef={actionRef}
                    request={async (params = {}, sorter, filter) => {
                        const {
                            name = null,
                            createdAt = null,
                            updatedAt = null,
                            current: page,
                            pageSize: perPage
                        } = params;


                        const data = {
                            unitId: company.unitId,
                            page,
                            perPage
                        };

                        if(Object.keys(sorter).length > 0){
                            const field = Object.keys(sorter)[0]
                            data['orderByField'] =field;
                            data['orderBySort'] = sorter[field] === 'ascend' ? 'ASC' : 'DESC';
                        }


                        if(name){
                            data['name'] = name;
                        }

                        if(createdAt){
                            data['createdAtStart'] = moment( createdAt[0], "DD/MM/YYYY HH:mm",true).toISOString();
                            data['createdAtEnd'] = moment( createdAt[1], "DD/MM/YYYY HH:mm",true).toISOString();
                        }

                        if(updatedAt){
                            data['updatedAtStart'] = moment( updatedAt[0], "DD/MM/YYYY HH:mm",true).toISOString();
                            data['updatedAtEnd'] = moment( updatedAt[1], "DD/MM/YYYY HH:mm",true).toISOString();
                        }

                        const response = await userApi.getRoles(data);
                        return {
                            data: response.data.items,
                            success: true,
                            total: response.data.total,
                        };
                    }}
                    columns={columns}
                    rowKey="id"
                />
            </ProCard>
        </ConfigProvider>
    )
}
export default Role
