import React, {useEffect, useState} from 'react';
import {Alert, Button, Input, Spin, Table, Tooltip} from "antd";
import {CopyOutlined, SyncOutlined, LoadingOutlined} from "@ant-design/icons";
import getApiUrl from "../../../../../services/getApiUrl";
import {automationAPI} from "../../../../../services/actions/automations";
import uuid from "react-uuid";
import { JsonViewer } from '@textea/json-viewer'

export default function Webhook({automation, trigger, onChange}) {

    const fetchDataWebhook = async () => {
        if (loading) return;
        try {
            const id = (automation && automation.id) ? automation.id : null;
            setError(null)
            setLoading(true);
            const {status, data: dataResp} = await automationAPI.getWebhookDataModel(id);
            if (status === 200) {
                setData(dataResp)
                onChange({
                    webhookData: dataResp.schema,
                })
            }
            setLoading(false)
        } catch (ex) {
            setError(ex.message);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchDataWebhook();
    }, []);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    const automationId = (automation && automation.id) ? automation.id : null;
    const path = `${process.env.REACT_APP_API || ""}/automations/${automationId}/webhook`;
    const urlScheme = `${path}/scheme`;
    const urlTrigger = `${path}/trigger`;

    const handleCopy = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };

    return (
        <>
            <p>Url de modelo de entrada</p>
            <Input
                disabled={true}
                value={urlScheme}
                suffix={
                    [
                        <Tooltip title="Copiar URL de modelo de entrada" key={uuid()}>
                            <Button
                                icon={<CopyOutlined/>}
                                onClick={() => handleCopy(urlScheme)}
                            />
                        </Tooltip>,
                        <Tooltip title={`${(loading) ? 'Atulizando' : "Atualizar"} modelo de entrada enviado`} key={uuid()}>
                            <Button
                                icon={(loading) ? <LoadingOutlined/> : <SyncOutlined/>}
                                onClick={() => fetchDataWebhook()}
                            />
                        </Tooltip>,

                    ]}
            />
            <p>URL de gatilho</p>
            <Input
                disabled={true}
                value={urlTrigger}
                suffix={<Tooltip title="Copiar URL de Gatilho">
                    <Button
                        icon={<CopyOutlined/>}
                        onClick={() => handleCopy(urlTrigger)}
                    />
                </Tooltip>}
            />
            <br/>
            <br/>
            <h3>Modelo de entrada</h3>

            {
                loading ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Spin key={uuid()} tip="Carregando Dados..."/>
                    </div>
                ) : (
                    error ? (
                        <Alert key={uuid()} type={"error"} message={error}></Alert>
                    ) : (
                        <JsonViewer  key={uuid()} value={data.schema ?? {}}/>
                    )
                )
            }
        </>)
}
