import React, {useEffect, useRef, useState} from "react";
import {
    ConfigProvider,
    Select,
    Empty,
    Tooltip,
    DatePicker,
    Space,
    Modal,
    notification,
} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import ptBRIntl from "antd/lib/locale/pt_BR";
import uuid from "react-uuid";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import {
    allCategoriesObj,
    allCategoriesOfObj,
    allFormsOfSavedObjects,
    allFormsOfSavedObjectsWithFields,
    savedDataInObj,
    allFieldsByName,
    deleteRecords,
    getRefFieldsById,
    getRefFieldsByData,
    restoreRecords,
} from "auth/actions/objActions";
import {useSelector} from "react-redux";

/**
 * Misc
 */
const {Option, OptGroup} = Select;
const {RangePicker} = DatePicker;
const {confirm} = Modal;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
    },
};

function convertToIndex(str) {
    const invalidChars = ["\\", "/", "*", "?", '"', "<", ">", "|", " ", ","];
    let result = "";

    for (let i = 0; i < str.length; i++) {
        const char = str[i];

        if (invalidChars.includes(char)) {
            result += "-";
        } else {
            result += char.toLowerCase();
        }
    }

    return result;
}

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function BinByForm(props) {
    const mounted = useRef(true);
    const {name, ids, useMatchAll, useBin, user, hasPermissionToEdit = false, hasPermissionToDelete = false} = props;

    const [alert, setAlert] = useState(false);
    const [cleanTable, setCleanTable] = useState(null);
    const [current, setCurrent] = useState([]);
    const [currentClean, setCurrentClean] = useState([]);
    const [old, setOld] = useState([]);
    const [oldByDate, setOldByDate] = useState([]);
    console.log(current)
    const [form, setForm] = useState([]);
    const [forms, setForms] = useState([]);
    const [category, setCategory] = useState([]);
    const [categories, setCategories] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [searchBy, setSearchBy] = useState("");
    const [searchIn, setSearchIn] = useState("");
    const [hideSearchBy, setHideSearchBy] = useState(true);
    const [isDataRange, setIsDataRange] = useState(true);

    const [refFieldsData, setRefFieldsData] = useState([]);
    const [allRefFields, setAllRefFields] = useState([]);

    const company = useSelector((state) => state.user.company);

    /**
     * Obtém todos dados salvos
     * @param {*} param0
     * @returns
     */
    const get = async ({forms, ids, useMatchAll, useBin}) => {
        return savedDataInObj({
            forms,
            ids,
            useMatchAll,
            useBin,
        }).then((items) => {
            // verifica se form está vazio
            if (
                Array.isArray(items.message) &&
                items.message.length === 0 &&
                Array.isArray(items.message[0])
            ) {
                return;
            }
            var flattens = _.uniq(_.flatten(items.message)),
                keys = [];
            // salva os objetos originais
            setCurrentClean(flattens);
            // organiza os nomes dos campos
            _.mapKeys(_.uniq(_.flatten(items.message)), (value, key) => {
                keys.push(Object.keys(value));
            });
            // organiza e combina os campos
            const mergeObjs = (data) => {
                return data
                    .reduce((result, item) => {
                        // pega o campo _id e adiciona um novo objeto
                        if (result.indexOf(item._id) < 0) {
                            result.push(item._id);
                        }
                        return result;
                    }, [])
                    .reduce((result, _id) => {
                        // pegue os dados com o mesmo _id e adicione um novo campo
                        const children = data.filter((item) => item._id === _id);
                        result = result.concat(
                            children.map((item, index) => {
                                const newObj = [];
                                _.mapKeys(item, (value, key) => {
                                    if (
                                        value &&
                                        value.length > 0 &&
                                        Array.isArray(value) &&
                                        value[0].hasOwnProperty("value")
                                    ) {
                                        // simples
                                        newObj.push({
                                            title: key,
                                            dataIndex: key,
                                            key: key,
                                        });
                                    } else {
                                        if (
                                            !value.hasOwnProperty("value") &&
                                            typeof value !== "string"
                                        ) {
                                            newObj.push({
                                                title: key,
                                                dataIndex: key,
                                                key: key,
                                            });
                                        } else {
                                            if (typeof value === "string") {
                                                newObj.push({
                                                    title: key,
                                                    dataIndex: key,
                                                    key: key,
                                                });
                                            } else {
                                                // data e tempo
                                                newObj.push({
                                                    title: key,
                                                    dataIndex: key,
                                                    key: key,
                                                });
                                                //console.log(key, value)
                                            }
                                        }
                                    }
                                });
                                return newObj;
                            })
                        );
                        return result;
                    }, []);
            };
            // prepara as colunas da tabela
            var mergeds = mergeObjs(flattens),
                all = _.uniqWith(_.uniq(_.flatten(mergeds)), _.isEqual).map((x) => {
                    switch (x.title) {
                        case "_id":
                            return {
                                title: (
                                    <>
                                        ID
                                        <Tooltip
                                            placement="top"
                                            title="Identificação do registro salvo"
                                        >
                                            <QuestionCircleOutlined style={{marginLeft: 4}}/>
                                        </Tooltip>
                                    </>
                                ),
                                dataIndex: "_id",
                                key: "_id",
                            };
                        case "createdAt":
                            return {
                                title: (
                                    <>
                                        Criado em
                                        <Tooltip placement="top" title="Data do registro salvo">
                                            <QuestionCircleOutlined style={{marginLeft: 4}}/>
                                        </Tooltip>
                                    </>
                                ),
                                dataIndex: "createdAt",
                                key: "createdAt",
                                sorter: (a, b) =>
                                    moment(a.createdAt, "DD/MM/YYYY HH:mm:ss").unix() -
                                    moment(b.createdAt, "DD/MM/YYYY HH:mm:ss").unix(),
                            };
                        case "updatedAt":
                            return {
                                title: (
                                    <>
                                        Atualizado em
                                        <Tooltip
                                            placement="top"
                                            title="Data do registro atualizado"
                                        >
                                            <QuestionCircleOutlined style={{marginLeft: 4}}/>
                                        </Tooltip>
                                    </>
                                ),
                                dataIndex: "updatedAt",
                                key: "updatedAt",
                                sorter: (a, b) =>
                                    moment(a.updatedAt, "DD/MM/YYYY HH:mm:ss").unix() -
                                    moment(b.updatedAt, "DD/MM/YYYY HH:mm:ss").unix(),
                            };
                        default:
                            return {
                                title: x.title,
                                dataIndex: x.title,
                                key: x.title,
                                children: x.hasOwnProperty("children") ? x.children : [],
                            };
                    }
                });
            var nColumns = all,
                sColumns = new Array();
            console.log(
                "🚀 ~ file: index.js:279 ~ returnsavedDataInObj ~ nColumns:",
                nColumns,
                mergeds,
                flattens
            );
            // tenta criar sort da tabela
            allFieldsByName({formName: name}).then((items) => {
                console.log(
                    "🚀 ~ file: index.js:279 ~ allFieldsByName ~ items:",
                    items
                );
                if (items.message) {
                    const dField = _.filter(nColumns, (o) => _.includes(["_id"], o.key)),
                        iField = _.filter(nColumns, (o) =>
                            _.includes(["createdAt", "updatedAt"], o.key)
                        );
                    sColumns = nColumns
                        .filter(
                            (y) => !_.includes(["_id", "createdAt", "updatedAt"], y.key)
                        )
                        .map((y) => {
                            var field = items.message.filter((cl) => cl.field === y.key);
                            if (field.length > 0) {
                                switch (field[0].fieldtype) {
                                    case "Text":
                                        return {
                                            ...y,
                                            sorter: (a, b) => {
                                                if (a[field[0].field] && b[field[0].field]) {
                                                    return a[field[0].field].localeCompare(
                                                        b[field[0].field]
                                                    );
                                                }
                                                return 0;
                                            },
                                        };
                                    case "Numeric":
                                        return {
                                            ...y,
                                            sorter: (a, b) => {
                                                if (a[field[0].field] && b[field[0].field]) {
                                                    return a[field[0].field] - b[field[0].field];
                                                }
                                                return 0;
                                            },
                                        };
                                    case "Date":
                                        return {
                                            ...y,
                                            sorter: (a, b) => {
                                                if (a[field[0].field] && b[field[0].field]) {
                                                    return (
                                                        moment(a[field[0].field], "DD/MM/YYYY").unix() -
                                                        moment(b[field[0].field], "DD/MM/YYYY").unix()
                                                    );
                                                }
                                                return 0;
                                            },
                                        };
                                    case "Time":
                                        return {
                                            ...y,
                                            sorter: (a, b) => {
                                                if (a[field[0].field] && b[field[0].field]) {
                                                    return (
                                                        moment(a[field[0].field], "HH:mm").unix() -
                                                        moment(b[field[0].field], "HH:mm").unix()
                                                    );
                                                }
                                                return 0;
                                            },
                                        };
                                    case "uniqueSelection":
                                    case "refUniqueSelection":
                                        return {
                                            ...y,
                                            sorter: (a, b) => {
                                                if (a[field[0].field] && b[field[0].field]) {
                                                    const aValue = parseFloat(a[field[0].field]);
                                                    const bValue = parseFloat(b[field[0].field]);
                                                    if (!isNaN(aValue) && !isNaN(bValue)) {
                                                        return bValue - aValue;
                                                    }
                                                    return a[field[0].field].localeCompare(
                                                        b[field[0].field]
                                                    );
                                                }
                                                return 0;
                                            },
                                        };
                                    case "multipleSelection":
                                    case "refMultipleSelection":
                                        return {
                                            ...y,
                                            sorter: (a, b) => {
                                                if (a[field[0].field] && b[field[0].field]) {
                                                    const aValue = a[field[0].field].toString();
                                                    const bValue = b[field[0].field].toString();
                                                    const aValueWithoutCommas = aValue.replace(/,/g, "");
                                                    const bValueWithoutCommas = bValue.replace(/,/g, "");
                                                    const aValueIsNumber = !isNaN(
                                                        parseFloat(aValueWithoutCommas)
                                                    );
                                                    const bValueIsNumber = !isNaN(
                                                        parseFloat(bValueWithoutCommas)
                                                    );

                                                    if (aValueIsNumber && bValueIsNumber) {
                                                        return (
                                                            parseFloat(bValueWithoutCommas) -
                                                            parseFloat(aValueWithoutCommas)
                                                        );
                                                    } else if (aValueIsNumber) {
                                                        return -1;
                                                    } else if (bValueIsNumber) {
                                                        return 1;
                                                    } else {
                                                        return aValue.localeCompare(bValue);
                                                    }
                                                }
                                                return 0;
                                            },
                                        };
                                    default:
                                        if (
                                            field[0].fieldtype !== "Text" &&
                                            field[0].fieldtype !== "Numeric" &&
                                            field[0].fieldtype !== "Date" &&
                                            field[0].fieldtype !== "Time" &&
                                            field[0].fieldtype !== "uniqueSelection" &&
                                            field[0].fieldtype !== "refUniqueSelection" &&
                                            field[0].fieldtype !== "multipleSelection" &&
                                            field[0].fieldtype !== "refMultipleSelection"
                                        ) {
                                            return {
                                                ...y,
                                            };
                                        }
                                }
                            }
                            return y;
                        });
                    // adiciona campos no inicio e final
                    sColumns = sColumns.concat(iField);
                    sColumns.unshift(...dField);
                    // atualiza colunas
                    setColumns(sColumns);
                }
            });

            const tableKeys = all.map((t) => t.title),
                output = [];
            // prepara os dados da tabela
            flattens.forEach((obj) => {
                const data = [];
                Object.keys(obj)
                    .filter((key) => tableKeys.includes(key))
                    .forEach((key) => {
                        if (
                            obj[key] &&
                            obj[key].length > 0 &&
                            Array.isArray(obj[key]) &&
                            obj[key][0].hasOwnProperty("value")
                        ) {
                            if (obj[key].length > 0) {
                                // referenciado
                                const mergedValue = obj[key]
                                    .map((item) => item.value)
                                    .filter((value) => value !== undefined)
                                    .join(", ");
                                data.push({[key]: mergedValue});
                            } else {
                                // simples
                                data.push({[key]: obj[key][0].value});
                            }
                        } else {
                            if (
                                !obj[key].hasOwnProperty("value") &&
                                typeof obj[key] !== "string"
                            ) {
                                data.push({[key]: _.toString(obj[key])});
                            } else {
                                // data e tempo
                                var date = moment(obj[key].value, "DD/MM/YYYY", true).isValid(),
                                    time = moment(obj[key].value, "HH:mm", true).isValid();
                                if (date) {
                                    data.push({
                                        [key]: obj[key].value,
                                    });
                                }
                                if (time) {
                                    data.push({[key]: obj[key].value});
                                }
                            }
                        }
                    });
                // adicione o id e atualize os objetos coletados
                output.push({
                    _id: obj._id,
                    ...data.reduce((map, elem) => ({...map, ...elem}), {}),
                    createdAt: obj.createdAt[0].value,
                    updatedAt: obj.updatedAt ? obj.updatedAt[0].value : [{}],
                });
            });
            // retorna os dados da tabela
            return output;
        });
    };

    useEffect(() => {
        if (forms.length === 0 && categories.length === 0) {
            /**
             * Pesquisa em dados salvos
             * @param {*} location.state.typed
             */
            allFormsOfSavedObjects(null, company.unitId).then((items) => {
                setForms(items.message);
                // Tenta obter os dados salvos
                get({
                    forms: [useBin ? `bin_${name.replace("obj_", "")}` : name],
                    ids,
                    useMatchAll,
                    useBin,
                }).then((results) => {
                    if (results !== undefined) {
                        setCurrent(results);
                        setOld(results);
                    }
                });
            });
            /**
             * Campos do formulario selecionado
             */
            allFieldsByName({formName: name}).then((items) => {
                var cleaned = items.message?.map((obj) => ({
                    _id: obj._id,
                    key: obj.field,
                    name: obj.field,
                    fieldtype: obj.fieldtype,
                    checkBox: obj.checkBox,
                    refFields: obj.refFields,
                }));
                // verifica
                if (cleaned) {
                    var filtered = _(cleaned).flatMap("refFields").value();
                    if (filtered.length > 0) {
                        loadRefFields(_(cleaned).flatMap("refFields").value(), cleaned);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forms, categories, category]);

    useEffect(() => {
        if (alert) {
            setTimeout(() => {
                if (mounted.current) {
                    setAlert(false);
                }
            }, 1000);
        }
    }, [alert]);

    useEffect(() => {
        if (columns.length > 0) {
            /**
             * Todas categorias
             */
            allCategoriesObj({
                companyId: company.companyId,
                unitId: company.unitId
            }).then((items) => {
                setCategories(items.message);
            });
            /**
             * Formularios detalhado
             */
            allFormsOfSavedObjectsWithFields().then((items) => {
                var cols = new Array();
                columns.forEach((x) => {
                    return items.message.forEach((h) => {
                        if (h.fields.length > 0) {
                            // match entre categorias e colunas
                            var all = _.find(h.fields, {field: x.title});
                            if (all) {
                                cols.push(all);
                                // seta categorias selecionadas
                                tryGetCategories(h.name);
                                // seta forms selecionadas
                                setForm(h.name);
                            }
                        }
                    });
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    function tryGetCategories(name) {
        allCategoriesOfObj({
            formName: name,
        }).then((values) => setCategory(values.message));
    }

    /**
     * Monta os campos de referência com seus valores atualizados.
     * @param {string} arrayName - O nome do array que contém os campos de referência.
     * @param {string} key - A chave que identifica o campo de referência.
     * @param {string} newValue - O novo valor a ser atribuído ao campo de referência.
     */
    const mountRefFields = (arrayName, key, newValue) => {
        setAllRefFields((prevData) => {
            const newData = {...prevData};
            const currentArray = newData[arrayName] || [];

            // Verifica se o campo de referência já existe no array
            const itemIndex = currentArray.findIndex((item) => item.key === key);

            if (itemIndex !== -1) {
                // Se o campo de referência existe, atualiza seu valor
                const newArray = [...currentArray];
                newArray[itemIndex] = {...newArray[itemIndex], value: newValue};
                newData[arrayName] = newArray;
            } else {
                // Se o campo de referência não existe, adiciona-o ao array
                newData[arrayName] = [...currentArray, {key, value: newValue}];
            }

            return newData;
        });
    };

    /**
     * Carrega os campos de referência com seus dados associados.
     * @param {Array} fields - Os campos de referência a serem carregados.
     * @param {Array} origFields - Os campos originais.
     */
    const loadRefFields = (fields, origFields) => {
        // Obtém os campos de referência pelo ID
        getRefFieldsById({fields}).then((items) => {
            if (items.message.length > 0) {
                // Obtém os campos de referência pelos dados
                getRefFieldsByData({
                    fields: items.message.map((obj) => ({
                        form: obj.form,
                        field: obj._id,
                        name: obj.field,
                    })),
                }).then((allRecords) => {
                    origFields.map((obj) => {
                        // Obtém as colunas dos campos de referência filtrados
                        var columns = _.map(
                            _.filter(items.message, (item) =>
                                _.includes(_.map(obj.refFields, "field"), item._id)
                            ) || [],
                            "field"
                        );

                        // Filtra os registros por colunas e adiciona o _id
                        const filteredArray = _.map(allRecords.message, (obj) => {
                            const filteredObj = _.pick(obj, columns);
                            filteredObj._id = obj._id;
                            return filteredObj;
                        });

                        // Define os dados dos campos de referência
                        setRefFieldsData(filteredArray);

                        filteredArray.map((c) => {
                            Object.keys(c).forEach((key) => {
                                if (
                                    c[key] &&
                                    c[key].length > 0 &&
                                    Array.isArray(c[key]) &&
                                    c[key][0].hasOwnProperty("value")
                                ) {
                                    // Trata campos simples
                                    mountRefFields(
                                        obj.name,
                                        `${c._id}_ref_${uuid()}`,
                                        c[key][0].value
                                    );
                                } else {
                                    if (
                                        !c[key].hasOwnProperty("value") &&
                                        typeof c[key] !== "string"
                                    ) {
                                        // Trata campos não-string
                                        mountRefFields(
                                            obj.name,
                                            `${c._id}_ref_${uuid()}`,
                                            _.toString(c[key])
                                        );
                                    } else {
                                        // Trata campos de data e tempo
                                        var date = moment(
                                            c[key].value,
                                            "DD/MM/YYYY",
                                            true
                                        ).isValid();
                                        var time = moment(c[key].value, "HH:mm", true).isValid();

                                        if (date) {
                                            // Trata campos de data
                                            mountRefFields(
                                                obj.name,
                                                `${c._id}_ref_${uuid()}`,
                                                c[key].value
                                            );
                                        }
                                        if (time) {
                                            // Trata campos de tempo
                                            mountRefFields(
                                                obj.name,
                                                `${c._id}_ref_${uuid()}`,
                                                c[key].value
                                            );
                                        }
                                    }
                                }
                            });
                        });
                    });
                });
            }
        });
    };

    /**
     * Remove os registros
     */
    const deleteConfirm = () => {
        var ids = selectedRows.map((values) => values._id) || [];
        confirm({
            title: "Deseja excluir esses registros?",
            icon: <ExclamationCircleOutlined/>,
            content: "Se confirmado essa açāo é irreversível.",
            onOk() {
                deleteRecords({
                    formName: name,
                    ids,
                    useBin: true,
                    createdBy: user.sub,
                })
                    .then(async (res) => {
                        notification["success"]({
                            message: "Registros",
                            description: res.message,
                        });
                        await sleep(1000);
                        window.location.reload(false);
                    })
                    .catch((err) =>
                        notification["error"]({
                            message: "Registros",
                            description: "Algo está errado, tente novamente!",
                        })
                    );
            },
        });
    };

    /**
     * Restaurar registros
     */
    const restoreConfirm = () => {
        var ids = selectedRows.map((values) => values._id) || [];
        confirm({
            title: "Deseja excluir esses registros?",
            icon: <ExclamationCircleOutlined/>,
            content: "Se confirmado essa açāo é irreversível.",
            onOk() {
                restoreRecords({
                    formName: name,
                    ids,
                    createdBy: user.sub,
                })
                    .then(async (res) => {
                        // Exibe uma notificação de sucesso
                        notification["success"]({
                            message: "Registros",
                            description: res.message,
                        });
                        // Aguarda 1 segundo antes de recarregar a página
                        await sleep(1000);
                        window.location.reload(false);
                    })
                    .catch((err) =>
                        // Exibe uma notificação de erro em caso de falha na adição dos registros
                        notification["error"]({
                            message: "Registros",
                            description: "Algo está errado, tente novamente!",
                        })
                    );
            },
        });
    };

    return (
        <ConfigProvider locale={ptBRIntl}>
            <ProTable
                headerTitle={`${current.length} ${
                    current.length === 1 ? "registro" : "registros"
                } ${current.length === 1 ? "encontrado" : "encontrados"}`}
                tooltip="Resultados encontrados no formulário que você selecionou"
                cardBordered
                columns={columns}
                dataSource={current}
                rowKey="_id"
                search={false}
                dateFormatter={"number"}
                scroll={{x: true}}
                style={{padding: 0}}
                rowSelection={{}}
                tableAlertRender={(hasPermissionToEdit || hasPermissionToDelete) ? ({
                                                                                        selectedRowKeys,
                                                                                        selectedRows,
                                                                                        onCleanSelected,
                                                                                    }) => {
                    setSelectedRows(selectedRows);
                    // Verifica se a função já foi atribuída ao estado cleanTable
                    if (cleanTable === null) {
                        setCleanTable(() => onCleanSelected);
                    }
                    return (
                        <Space size={24}>
              <span>
                Selecionado {selectedRowKeys.length} registros
                <a
                    style={{marginInlineStart: 8}}
                    onClick={() => {
                        setSelectedRows([]);
                        onCleanSelected();
                    }}
                >
                  cancelar seleção
                </a>
              </span>
                        </Space>
                    );
                } : {}}
                tableAlertOptionRender={() => {
                    return (
                        <Space size={16}>
                            {hasPermissionToEdit && (<a href={() => false} onClick={restoreConfirm}>
                                Restaurar
                            </a>)}

                            {hasPermissionToEdit && (
                                <a href={() => false} onClick={deleteConfirm}>
                                    Excluir
                                </a>
                            )}

                        </Space>
                    );
                }}
                columnsState={{
                    persistenceKey: "clm-pro-table-all-records",
                    persistenceType: "localStorage",
                }}
                options={{
                    reload: false,
                }}
                locale={{
                    emptyText: <Empty description="Nenhum registro adicionado."/>,
                    triggerDesc: "Ordem decrescente",
                    triggerAsc: "Ordem crescente",
                }}
            />
        </ConfigProvider>
    );
}

export default BinByForm;
