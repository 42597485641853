import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { login } from "../../utils/user-manager";

const Login = () => {
    const authenticated = useSelector((state) => state.session.authenticated);

    if (authenticated) {
        return <Redirect to="/bem-vindo" />;
    } else {
        login();
    }

    return null;
};

export default Login;
