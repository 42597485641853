import React, { useEffect, useState } from "react";
import {
    Layout,
    Button,
    Form,
    Input,
    Row,
    Col,
    Avatar,
    Upload,
    message,
    Space,
} from "antd";
import { ProCard } from "@ant-design/pro-components";
import { userApi } from "../../services/actions/users";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { UploadOutlined } from "@ant-design/icons";
import { LayoutTopSide } from "../../components/NavBar/styles";
import TopBar from "../../components/NavBar/TopBar";
import DrawerSider from "../../components/NavBar/DrawerSider";
import { updateUrlAvatar } from "../../auth/actions/userActions";

const { Content } = Layout;

const Profile = () => {
    const userId = useSelector((state) => state.session.user.sub);
    const avatarUrl = useSelector((state) => state.user.avatar);
    const company = useSelector((state) => state.user.company);
    const dispatch = useDispatch();
    const [formUser] = Form.useForm();
    const [formPassword] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
        try {
            setLoading(true);
            const response = await userApi.getById(userId, company.unitId);
            if (response.status === 200) {
                setUser(response.data);
            }
        } catch (error) {
            message.error("Erro ao carregar informações do usuário.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (submitFunction, data, successMessage) => {
        try {
            await submitFunction(data);
            message.success(successMessage);
            fetchUser();
        } catch (error) {
            message.error("Erro ao salvar alterações.");
            console.error(error);
        }
    };

    const handleUpload = async (file) => {
        try {
            setUploading(true);
            await dispatch(updateUrlAvatar(userId, file));
        } catch (error) {
            console.error(error);
        } finally {
            setUploading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, [userId]);

    return (
        <Layout>
            <LayoutTopSide>
                <TopBar />
                <Layout>
                    <DrawerSider />
                    <Layout style={{ padding: "5px 24px 24px", minHeight: "100vh" }}>
                        <Row gutter={24} style={{ minHeight: "90vh" }}>
                            <Col span={5}>
                                <ProCard
                                    colSpan={24}
                                    style={{ height: "100%" }}
                                    bodyStyle={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Avatar
                                        size={200}
                                        src={avatarUrl || "https://via.placeholder.com/200"}
                                    />
                                    <br />
                                    <br />
                                    <Upload
                                        accept={"image/jpeg"}
                                        showUploadList={false}
                                        beforeUpload={(file) => {
                                            handleUpload(file);
                                            return false;
                                        }}
                                    >
                                        <Button icon={<UploadOutlined />} loading={uploading}>
                                            Alterar Foto
                                        </Button>
                                    </Upload>
                                </ProCard>
                            </Col>

                            <Col span={19} style={{ height: "100%" }}>
                                {loading || !userId ? (
                                    <Loader type="Circles" />
                                ) : user ? (
                                    <ProCard colSpan={20} tabs={{ tabPosition: "top", type: "card" }}>
                                        <ProCard.TabPane tab={"Dados pessoais"} key="tab1">
                                            <Form
                                                form={formUser}
                                                scrollToFirstError
                                                initialValues={{
                                                    firstName: user.firstName,
                                                    lastName: user.lastName,
                                                    username: user.username,
                                                    email: user.email,
                                                }}
                                                onFinish={(data) =>
                                                    handleSubmit(
                                                        (data) =>
                                                            userApi.update(userId, {
                                                                ...data,
                                                                unitId: company.unitId,
                                                            }),
                                                        data,
                                                        "Dados atualizados com sucesso!"
                                                    )
                                                }
                                            >
                                                <Form.Item
                                                    name="firstName"
                                                    label="Nome"
                                                    rules={[{ required: true, message: "Por favor insira o nome!" }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    name="lastName"
                                                    label="Sobrenome"
                                                    rules={[{ required: true, message: "Por favor insira o sobrenome!" }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    name="username"
                                                    label="Usuário"
                                                    rules={[{
                                                        required: true,
                                                        message: "Por favor insira o nome de acesso!",
                                                    }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    name="email"
                                                    label="E-mail"
                                                    rules={[
                                                        { type: "email", message: "E-mail inválido!" },
                                                        { required: true, message: "Por favor insira o e-mail!" },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Salvar Alterações
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </ProCard.TabPane>

                                        <ProCard.TabPane tab={"Recuperação de Senha"} key="tab2">
                                            <Form
                                                form={formPassword}
                                                scrollToFirstError
                                                onFinish={(data) =>
                                                    handleSubmit(
                                                        (data) =>
                                                            userApi.updatePassword(
                                                                userId,
                                                                data.password,
                                                                data.confirmpassword,
                                                                company.unitId
                                                            ),
                                                        data,
                                                        "Senha atualizada com sucesso!"
                                                    )
                                                }
                                            >
                                                <Form.Item
                                                    name="password"
                                                    label="Senha"
                                                    rules={[{ required: true, message: "Por favor insira uma senha!" }]}
                                                    hasFeedback
                                                >
                                                    <Input.Password placeholder="*********" />
                                                </Form.Item>
                                                <Form.Item
                                                    name="confirmpassword"
                                                    label="Confirme a Senha"
                                                    dependencies={["password"]}
                                                    hasFeedback
                                                    rules={[
                                                        { required: true, message: "Por favor, confirme a senha!" },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue("password") === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(
                                                                    new Error("As senhas não coincidem!")
                                                                );
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password placeholder="*********" />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Salvar Senha
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </ProCard.TabPane>
                                    </ProCard>
                                ) : (
                                    <p>Erro ao recuperar informações do perfil</p>
                                )}
                            </Col>
                        </Row>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
};

export default Profile;
