import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import {
  ConfigProvider,
  Layout,
  PageHeader,
  Row,
  Col,
  Empty,
  Space,
  Modal,
  notification,
  Spin,
  Card,
  Breadcrumb,
  Statistic,
  Result,
  Button,
} from "antd";
import { ProTable, BetaSchemaForm } from "@ant-design/pro-components";
import {
  ExclamationCircleOutlined,
  FileSyncOutlined,
  FileProtectOutlined,
  FileExclamationOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import datePickerLocale from "antd/lib/locale/pt_BR";
import ptBRIntl from "antd/lib/locale/pt_BR";
import uuid from "react-uuid";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";

/**
 * Actions
 */
import {
  addRefFieldsFormData,
  deleteAllFormData,
  deleteAllRefFieldsFormData,
  fetchImportFormData,
} from "ducks/dataOfForm";
import {
  filesHistoryById,
  filesHistoryErrorRemove,
} from "auth/actions/importActions";
import { addRecords } from "auth/actions/objActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";

/**
 * Utils
 */
import buildColumns, {
  buildSchemaColumns,
  getLookupData,
} from "components/Utils/formColumns";
import buildTableData from "components/Utils/formData";
import sleep from "components/Utils/sleep";
import Notification from "components/Notifications";
import { showNotification } from "ducks/notification";
import mountRecords from "components/Utils/buildRecords";
import removeUndefined from "components/Utils/removeUndefined";
import { getFieldValueForTable } from "components/Utils/getFieldValue";
import getApiUrl from "services/getApiUrl";
import socketInstance from "../../../../services/socket";

/**
 * Misc
 */
const { confirm } = Modal;
const routes = [
  {
    breadcrumbName: "formulários",
    href: "/formularios/todos",
  },
  {
    breadcrumbName: "importaçåo",
  },
  {
    breadcrumbName: "status",
  },
];

function ImportStatus(props) {
  // console.log("🚀 ~ file: index.js:125 ~ ImportStatus ~ props:", props);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const [fieldWithErrosMutipleEdit, setFieldWithErrosMutipleEdit] = useState([]);
  const [queue, setQueue] = useState(null);
  const [queueStatus, setQueueStatus] = useState(null); // Socket-IO status

  const name = queryParams.get("form");
  const currentForm = decodeURIComponent(name);

  // Tabela
  const tableRef = useRef();
  const columns = useMemo(
    () =>
      buildColumns(
        props.formData.columns,
        props.formData.fields,
        props.formData.refFields
      ),
    [
      props.formData.columns,
      props.formData.fields,
      props.formData.refFields,
      props.formData.queueStatus,
    ]
  );
  const [checkedRowsData, setCheckedRowsData] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkedRowsRef, setCheckedRowsRef] = useState({});

  // Formulário
  const recordFormRef = useRef();
  const [recordFormAction, setRecordFormAction] = useState("add");
  const recordFormColumns = useMemo(
    () =>
      buildSchemaColumns(
        props.formData.columns,
        props.formData.fields,
        props.formData.refFields,
        checkedRowsRef,
        recordFormRef,
        recordFormAction,
        true,
        dispatch
      ),
    [
      props.formData.columns,
      props.formData.fields,
      props.formData.refFields,
      checkedRowsRef,
      props.formData.queueStatus,
    ]
  );

  let recordFormColumnsFinal;
  if (recordFormAction !== "multipleEdit") {
    recordFormColumnsFinal = recordFormColumns;
  } else {
    recordFormColumnsFinal = [
      ...recordFormColumns.map(field => {
        if (_.isObject(field.formItemProps) && _.isArray(field.formItemProps.rules)) {

          for (let i = 0; i < field.formItemProps.rules.length; i++) {
            if (field.formItemProps.rules[i].hasOwnProperty('required')) {
              field.formItemProps.rules[i].required = false;
            }
          }
        }
        return field;
      }),
      ...[
        {
          key: "acceptEmptyValues",
          name: "acceptEmptyValues",
          title: "Considerar valores vazios para atualização de campos dos registros selecionado?",
          valueType: "switch"
        }
      ]
    ];

  }

  // Edição
  const [openForm, setOpenForm] = useState(false);
  const socketRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtém os detalhes da fila
        const detailsOfImport = await filesHistoryById(id);
        // Verifica se encontrou os detalhes da fila
        if (detailsOfImport && _.size(detailsOfImport.message) > 0) {
          setQueue(detailsOfImport.message[0]);
        }
      } catch (error) {
        // console.error("Erro ao carregar dados:", error);
        dispatch(
            showNotification(
                "Não foi possível obter os detalhes da importação, atualize a página e tente novamente!",
                "error"
            )
        );
      }
    };

    const initializeSocket = async () => {
      if (!socketRef.current) {
        socketRef.current = await socketInstance();

        // Tenta obter status da fila em tempo real
        socketRef.current.on(`${id}:queueStatus`, async (data) => {
          // console.log("Status da fila recebida:", data);
          if (data.totalItems === 0 && data.errorCount === 0) {
            await sleep(2000);
            // Atualiza a tabela após finalizar a fila
            dispatch(fetchImportFormData(currentForm, id));
          } else if (data.totalItems === 0 && data.errorCount > 0) {
            await sleep(2000);
            window.location.reload();
          } else if (data.totalItems === 0) {
            await sleep(2000);
            // Atualiza a tabela após finalizar a fila
            try {
              dispatch(fetchImportFormData(currentForm, id));
              // Limpa o cache do status da fila
              setQueueStatus(null);
            } catch (error) {
              // console.error("Erro ao atualizar dados:", error);
              dispatch(
                  showNotification(
                      "Ao finalizar e tentar atualizar a tabela algo de errado aconteceu, atualize a página e tente novamente!",
                      "error"
                  )
              );
            }
          } else {
            // Mantém em cache o status da fila
            setQueueStatus(data);
          }
        });
      }
    };

    // Tenta obter os detalhes da fila
    fetchData();
    initializeSocket();

    // Limpa conexão do SocketIO ao desmontar o componente
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  /**
   * Remove registros
   */
  const deleteConfirm = () => {
    var ids = checkedRows.map((values) => values._id) || [];
    confirm({
      title: "Deseja excluir esses registros?",
      icon: <ExclamationCircleOutlined />,
      content: "Se confirmado essa açāo é irreversível.",
      onOk() {
        filesHistoryErrorRemove({
          id,
          formName: name,
          errorIds: ids,
        })
          .then(async (res) => {
            var newRecords = res.message || [];
            if (newRecords.length > 0) {
              await sleep(800);
              filesHistoryById(id).then((items) => {
                setQueue(items.message[0]);
                setQueueStatus(null);
              });
              await sleep(200);
              // Limpa itens selecionados na tabela
              notification["success"]({
                message: "Registros",
                description: "Removido com sucesso!",
              });
              setCheckedRows([]);
              // Atualiza a tabela
              tableRef.current.reloadAndRest();
            } else {
              // Limpa itens selecionados na tabela
              notification["success"]({
                message: "Registros",
                description: "Removido com sucesso!",
              });
              setCheckedRows([]);
              await sleep(800);
              window.location.reload();
            }
          })
          .catch((err) =>
            dispatch(
              showNotification("Algo está errado, tente novamente!", "error")
            )
          );
      },
    });
  };

  const checkIfIsEmpty  = (data) => {
    return data && _.every(data, (item) => _.isEmpty(item.id) && (_.isNil(item.value) || (!_.isNumber(item.value) && _.isEmpty(item.value.trim()))))
  }
  /**
   * Salva registros
   * @param {*} data
   * @param {*} action
   */
  const trySaveRecords = async (data, action) => {
    // Combina resultados buscando campos de retorno com nomes "duplicados"
    const keys = Object.keys(data);
    const uniqueKeys = new Set(keys);
    const haveDuplicateField = keys.length !== uniqueKeys.size;
    if (data && !_.isEmpty(data)) {
      if (haveDuplicateField) {
        dispatch(
            showNotification(
                "Não é permitido anexar campos de retornos com nomes duplicados. Por favor, remova os campos com nome duplicados dos campos de consulta.",
                "error"
            )
        );
      } else {
        if (action && action === "edit") {
          // Envia os registros para adição
          addRecords({
            formName: currentForm,
            record: data,
            createdBy: props.user.sub,
            amount: checkedRows.length
          })
              .then(async (res) => {
                notification["success"]({
                  message: "Registros",
                  description: res.message,
                });
                await sleep(800);
                // Tenta obter os detalhes atualizado da fila
                filesHistoryById(id).then((items) => {
                  setQueue(items.message[0]);
                });
                await sleep(400);
                filesHistoryErrorRemove({
                  id,
                  formName: currentForm,
                  errorIds: [checkedRows[0]._id],
                });
                await sleep(25);
                // Limpa os campos referenciados e lookups
                dispatch(deleteAllRefFieldsFormData());
                // Limpa os campos de retorno
                dispatch(deleteAllFormData());
                // Limpa seleção do registro para edição
                if (recordFormAction === "edit") {
                  setCheckedRowsData([]);
                }
                await sleep(25);
                // Fecha o formulário
                setOpenForm(false);
                // Atualiza a tabela
                tableRef.current.reloadAndRest();
              })
              .catch((err) => {
                dispatch(
                    showNotification("Algo está errado, tente novamente!", "error")
                );
              });
        }
        else if (action && action === "multipleEdit") {
          const fields = _.keyBy(props.formData.fields, 'field');
          let ids = checkedRows.map((values) => values._id) || [];
          const currentErrs = _.keyBy(props.formData.queueErrorsMsg.filter(item => ids.includes(item._id)), '_id');
          const rowsData = props.formData.records.filter(item => ids.includes(item._id));

          let canUpdate = true;
          const records = [];
          const fieldsWithError = [];

          const fieldsLookUpchildrens = Object.values(fields)
              .filter(value => value.fieldtype === 'refLookup')
              .flatMap(field => field.getValueOf.map(item => item.fieldName));

          for (let i = 0; i < rowsData.length; i++) {
            const record = rowsData[i];
            const entriesData = Object.entries(data);
            for1:for (let j = 0; j < entriesData.length; j++) {
              const [key, value] = entriesData[j];
              if (key === "acceptEmptyValues" || fieldsLookUpchildrens.includes(key)) continue;
              let originValue = "import";
              if (data.acceptEmptyValues) {
                if(currentErrs[record._id]?.[key] !== undefined && checkIfIsEmpty(data[key]) ){
                  canUpdate = false;
                  fieldsWithError.push(`O campo ${key} está valores inválidos para inserção`)
                  break for1;
                }else if(checkIfIsEmpty(data[key]) && !!fields[key].requiredField){
                  originValue = "import";
                  record[key] = record[key];
                }else{
                  originValue = "form";
                  record[key] = data[key]
                }
              } else{
                if(currentErrs[record._id]?.[key] !== undefined && checkIfIsEmpty(data[key]) ){
                  canUpdate = false;
                  fieldsWithError.push(`O campo ${key} está valores inválidos para inserção`)
                  break for1;
                }else if(checkIfIsEmpty(data[key])){
                  if(record[key] === undefined){
                    originValue = "form";
                    record[key] = data[key];
                  }else{
                    originValue = "import";
                    record[key] = record[key];
                  }
                }else{
                  originValue = "form";
                  record[key] = data[key];
                }
              }

              if(fields[key].fieldtype === 'refLookup'){
                const refs = fields[key].getValueOf.map(item => item.fieldName);
                for (let k = 0; k < refs.length; k++) {
                  const ref = refs[k];
                  if(originValue === "form"){
                    record[ref] = data[key];
                  }else if(originValue === "import"){
                    record[ref] = record[key];
                  }
                }

              }
            }
            records.push(record);
          }
          if (!canUpdate) {
            dispatch(
                showNotification(_.uniq(fieldsWithError).join(";"), "error")
            );
            return false;
          } else {
            // Envia os registros para adição
            addRecords({
              formName: currentForm,
              record: records,
              createdBy: props.user.sub,
              acceptEmptyValues: !!data.acceptEmptyValues
            })
                .then(async (res) => {
                  notification["success"]({
                    message: "Registros",
                    description: res.message,
                  });
                  await sleep(800);
                  // Tenta obter os detalhes atualizado da fila
                  filesHistoryById(id).then((items) => {
                    setQueue(items.message[0]);
                  });
                  await sleep(400);
                  filesHistoryErrorRemove({
                    id,
                    formName: currentForm,
                    errorIds: checkedRows.map(item => item._id),
                  });
                  await sleep(25);
                  // Limpa os campos referenciados e lookups
                  dispatch(deleteAllRefFieldsFormData());
                  // Limpa os campos de retorno
                  dispatch(deleteAllFormData());
                  // Limpa seleção do registro para edição
                  if (recordFormAction === "edit") {
                    setCheckedRowsData([]);
                  }
                  await sleep(25);
                  // Fecha o formulário
                  setOpenForm(false);
                  // Atualiza a tabela
                  tableRef.current.reloadAndRest();
                })
                .catch((err) => {
                  dispatch(
                      showNotification("Algo está errado, tente novamente!", "error")
                  );
                });
          }

        } else {
          dispatch(
              showNotification(
                  "Algo está errado, atualize a página e tente novamente!",
                  "error"
              )
          );
        }
      }
    } else {
      // Notificação de erro se nenhum campo for preenchido
      dispatch(
          showNotification(
              "Você precisa preencher algum dos campos para confirmar o envio.",
              "error"
          )
      );
    }
  };

  /**
   * Formata tempo decorrido da fila
   * @param {*} elapsedTime
   * @returns
   */
  function formatElapsedTime(elapsedTime) {
    if (!elapsedTime) {
      return "0:00";
    }

    const { minutes, seconds } = elapsedTime;
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  }

  return (
    <Layout>
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 53px 53px", minHeight: "100vh" }}>
            <PageHeader
              style={{ paddingLeft: 0, margin: "16px 0 0 0" }}
              title="Status da importaçāo"
              subTitle="Acompanhe o progresso dos registros importados para o seu formulário."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Registros na fila"
                    value={
                      queue === null && queueStatus === null
                        ? 0
                        : queue?.status === "finished"
                        ? queue.totalItems
                        : queue?.status === "error"
                        ? queue.totalItems
                        : queueStatus && queueStatus.totalItems
                    }
                    prefix={<FileSyncOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Registros com erro"
                    value={
                      queue === null && queueStatus === null
                        ? 0
                        : queue?.status === "finished"
                        ? queue.errorCount
                        : queue?.status === "error"
                        ? queue.errorCount
                        : queueStatus && queueStatus.errorCount
                    }
                    valueStyle={{
                      color: "#cf1322",
                    }}
                    prefix={<FileExclamationOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Registros salvos"
                    value={
                      queue === null && queueStatus === null
                        ? 0
                        : queue?.status === "finished"
                        ? queue.successCount
                        : queue?.status === "error"
                        ? queue.successCount
                        : queueStatus && queueStatus.successCount
                    }
                    valueStyle={{
                      color: "#3f8600",
                    }}
                    prefix={<FileProtectOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Tempo decorrido"
                    value={
                      queue === null && queueStatus === null
                        ? "0:00"
                        : queue?.status === "finished"
                        ? formatElapsedTime(queue.elapsedTime)
                        : queue?.status === "error"
                        ? formatElapsedTime(queue.elapsedTime)
                        : queueStatus &&
                          formatElapsedTime(queueStatus.elapsedTime)
                    }
                    prefix={<ClockCircleOutlined />}
                  />
                </Card>
              </Col>
            </Row>
            <Card
              style={{
                marginTop: "16px",
              }}
            >
              {(queue && queue?.status === "error") ||
              queueStatus?.status === "error" ? (
                <ConfigProvider locale={ptBRIntl}>
                  {/* Todos os registros */}
                  <ProTable
                    actionRef={tableRef}
                    rowKey="_id"
                    type="table"
                    request={async (params = {}, sorter, filter) => {
                      // Dispara a ação de busca dos dados do formulário
                      dispatch(
                        fetchImportFormData(
                          currentForm, // nome do formuláro
                          id // id da fila
                        )
                      );

                      // Aguarda um curto período de tempo para garantir que os dados estejam disponíveis
                      await new Promise((resolve) => setTimeout(resolve, 500));

                      // Constroi dados para tabela com props.formData.records
                      return buildTableData(props.formData.records);
                    }}
                    columns={columns}
                    dataSource={buildTableData(props.formData.records)}
                    toolbar={null}
                    headerTitle={`${props.formData.total} ${
                      props.formData.total === 0 ? "registro" : "registros"
                    } ${
                      props.formData.total === 0 ? "encontrado" : "encontrados"
                    }`}
                    tooltip="Resultados encontrado no formulário que você selecionou"
                    options={{
                      reload: true,
                    }}
                    tableAlertRender={({
                      selectedRowKeys,
                      selectedRows,
                      onCleanSelected,
                    }) => {
                      setCheckedRows(selectedRows);
                      return (
                        <Space size={24}>
                          <span>
                            Selecionado {selectedRowKeys.length} registros
                            <a
                              style={{ marginInlineStart: 8 }}
                              onClick={() => {
                                onCleanSelected();
                              }}
                            >
                              cancelar seleção
                            </a>
                          </span>
                        </Space>
                      );
                    }}
                    tableAlertOptionRender={() => {
                      return (
                        <Space size={16}>
                          <a
                            href={() => false}
                            onClick={async () => {
                              // Limpa os campos
                              recordFormRef.current.resetFields();
                              // Verifica se é seleção única
                              if (checkedRows.length === 1) {
                                // Tenta obter uuid do registro
                                const recordId = checkedRows[0]._id;
                                // Ação (CRUD)
                                setRecordFormAction("edit");
                                // Tenta obter o registro
                                const nativeSelectedRecord = _.filter(
                                  props.formData.records,
                                  { _id: recordId }
                                );
                                const selectedRecordMessage = nativeSelectedRecord[0] || {};
                                // Verifica se encontrou o registro selecionado
                                if (selectedRecordMessage) {
                                  // Salva o registro em cache para ser utilizado em mountRecords()
                                  setCheckedRowsData(selectedRecordMessage);
                                }
                                // Procura por campos referenciados e lookups
                                const onlyRefLkpFields =
                                  props.formData.fields.filter(
                                    (e) =>
                                      e.fieldtype === "refLookup" ||
                                      e.fieldtype === "refUniqueSelection" ||
                                      e.fieldtype === "refMultipleSelection"
                                  );
                                const onlyLkpFields =
                                  props.formData.fields.filter(
                                    (e) => e.fieldtype === "refLookup"
                                  ) || [];
                                // Remove campos referenciados e lookups
                                const withoutRefLkp =
                                  _.omit(
                                    selectedRecordMessage,
                                    _.map(onlyRefLkpFields, "field")
                                  ) || {};
                                // Verifica se registro não foi excluido
                                if (
                                  selectedRecordMessage &&
                                  !_.isEmpty(selectedRecordMessage)
                                ) {
                                  const onlyRefLkp = _.pick(
                                    selectedRecordMessage,
                                    _.map(onlyRefLkpFields, "field")
                                  );
                                  const transformedOnlyRefLkp = {};
                                  // Intera sobre os campos referenciados e lookups
                                  for (const key in onlyRefLkp) {
                                    if (onlyRefLkp.hasOwnProperty(key)) {
                                      if (onlyRefLkp[key].length > 0) {
                                        const genId = `${onlyRefLkp[key]
                                          .map((obj) => obj._id)
                                          .join(",")}_rng@record_${uuid()}`;
                                        const currentValue = onlyRefLkp[key]
                                          .map((obj) => obj.value)
                                          .join(", ");
                                        // Verifica se existe valor no campo
                                        if (!genId.startsWith("_r")) {
                                          // Extrai as propriedades _id e value, une com vírgulas e armazena
                                          transformedOnlyRefLkp[key] = [
                                            {
                                              _id: genId,
                                              value: currentValue,
                                            },
                                          ];
                                          dispatch(
                                            addRefFieldsFormData(key, [
                                              {
                                                value: genId,
                                                label: currentValue,
                                              },
                                            ])
                                          );
                                          // Tenta preencher o formulário com os registros de campos referenciados e lookups
                                          recordFormRef.current.setFieldsValue({
                                            [key]: genId,
                                          });
                                        }
                                      }
                                    }
                                  }
                                  // Prepara o cache para os campos referenciados e lookups
                                  setCheckedRowsRef(transformedOnlyRefLkp);
                                  // Prepara os dados para ser preenchido na tabela
                                  let recordForEdit = getFieldValueForTable(
                                    [withoutRefLkp],
                                    _.concat(
                                      props.formData.fields,
                                      props.formData.refFields
                                    ).filter((y) => y.isReadOnly === undefined)
                                  );
                                  // Verifica se existe campos de lookups
                                  if (
                                    onlyLkpFields &&
                                    onlyLkpFields.length > 0
                                  ) {
                                    onlyLkpFields.forEach(async (obj) => {
                                      if (
                                        selectedRecordMessage[obj.field] &&
                                        selectedRecordMessage[obj.field]
                                          .length > 0
                                      ) {
                                        if (
                                          selectedRecordMessage[obj.field][0]
                                            ._id !== ""
                                        ) {
                                          // Obtém os dados do campo de retorno e preenche o formulário
                                          await getLookupData({
                                            tableRef: recordFormRef,
                                            selectedField: obj,
                                            selectedOpts: `${
                                              selectedRecordMessage[
                                                obj.field
                                              ][0]._id
                                            }_rng@record_${uuid()}`,
                                            dispatch,
                                          });
                                        }
                                      }
                                    });
                                  }
                                  // Tenta preencher os campos simples
                                  recordFormRef.current.setFieldsValue(
                                    // Remove campos referenciados e lookups
                                    _.omit(
                                      recordForEdit,
                                      _.map(onlyRefLkpFields, "field")
                                    )
                                  );
                                }
                                // Espera alguns instantes
                                await sleep(700);
                                // Abre formulário
                                setOpenForm(true);
                              }else{
                                await sleep(700);
                                setRecordFormAction("multipleEdit");
                                setOpenForm(true);
                              }
                            }}
                          >
                            Editar
                          </a>
                          <a href={() => false} onClick={deleteConfirm}>
                            Excluir
                          </a>
                        </Space>
                      );
                    }}
                    locale={{
                      emptyText: (
                        <Empty description="Nenhum registro adicionado." />
                      ),
                      triggerDesc: "Ordem decrescente",
                      triggerAsc: "Ordem crescente",
                    }}
                    dateFormatter={"number"}
                    scroll={{ x: true }}
                    style={{ padding: 0 }}
                    rowSelection={{}}
                    search={false}
                    cardBordered={true}
                  />
                  {/* Formulário */}
                  <BetaSchemaForm
                    formRef={recordFormRef}
                    visible={openForm}
                    layoutType={"ModalForm"}
                    modalProps={{
                      onCancel: async () => {
                        // Limpa os campos referenciados e lookups
                        dispatch(deleteAllRefFieldsFormData());
                        // Limpa os campos de retorno
                        dispatch(deleteAllFormData());
                        if (recordFormAction === "edit" || recordFormAction ==="multipleEdit") {
                          // Limpa seleção do registro para edição
                          setCheckedRowsData([]);
                        }
                        // Fecha o formulário
                        setOpenForm(false);
                        setRecordFormAction('add')
                      },
                      okText: "Salvar registro",
                    }}
                    submitter={{
                      render: (fProps, defaultDoms) => {
                        if (checkedRows.length === 1) {
                          const cacheRecordId = checkedRows[0]._id;
                          // Tenta obter os erros de cada campo
                          const currentErr = _.find(
                              props.formData.queueErrorsMsg,
                              {
                                _id: cacheRecordId,
                              }
                          );
                          // Espera os campos renderizar antes de alterar cada campo
                          if (
                              cacheRecordId &&
                              currentErr &&
                              !_.isEmpty(currentErr) &&
                              !_.isEmpty(fProps.form.getFieldsValue())
                          ) {
                            // Remove os campos _id, createdAt e updatedAt
                            const currentErrCopy = {...currentErr};
                            delete currentErrCopy._id;
                            delete currentErrCopy.createdAt;
                            delete currentErrCopy.updatedAt;
                            // Obtém todos os campos restantes do registro
                            const keys = Object.keys(
                                _.omitBy(currentErrCopy, _.isNil)
                            );
                            // Verifica se existe campos
                            if (keys && keys.length > 0) {
                              // Preenche formulário com os erros do campo
                              keys.map((key) => {
                                fProps.form.setFields([
                                  {
                                    name: key,
                                    errors: [currentErrCopy[key]],
                                  },
                                ]);
                              });
                            }
                          }
                        }else{
                          let ids = checkedRows.map((values) => values._id) || [];
                          const currentErrs = props.formData.queueErrorsMsg.filter(item => ids.includes(item._id));
                          if(currentErrs.length > 0){
                            const erros = {};
                            currentErrs.forEach(errors => {
                              const keys = Object.keys(
                                  _.omitBy(errors, _.isNil)
                              );
                              keys.forEach((key) => {
                                if(erros[key] === undefined && !['createdAt','_id'].includes(key)){
                                  erros[key] = "Existem valores invalidos para este campo nos registros selecionados"
                                }
                              })
                            })
                            const errorsMsgs =  Object.entries(erros).map(([key,value]) => {
                                  return ({
                                    name: key,
                                    errors: [value],
                                  });
                                }
                            );
                            fProps.form.setFields(errorsMsgs);
                          }
                        }
                        return [
                          ...defaultDoms,
                        ];
                      }
                    }}
                    columns={recordFormColumnsFinal}
                    labelCol={{
                      span: 24,
                    }}
                    onFinish={async (formValues) => {
                      // Mantém os dados do formulário em cache
                      let submitedData = formValues;
                      // Filtra campos por tipo
                      const onlyFieldsName =
                        _.map(
                          props.formData.fields.filter(
                            (e) =>
                              e.fieldtype === "Text" ||
                              e.fieldtype === "Numeric" ||
                              e.fieldtype === "Date" ||
                              e.fieldtype === "Time" ||
                              e.fieldtype === "uniqueSelection" ||
                              e.fieldtype === "multipleSelection" ||
                              e.fieldtype === "refUniqueSelection" ||
                              e.fieldtype === "refMultipleSelection" ||
                              e.fieldtype === "refLookup"
                          ),
                          "field"
                        ) || [];
                      // Verifica se existe campos no formulário
                      if (onlyFieldsName && onlyFieldsName.length > 0) {
                        // Se encontrar tenta fixar erro do onClear()
                        onlyFieldsName.forEach((item) => {
                          // Verifica se o campo não existe
                          if (!submitedData.hasOwnProperty(item)) {
                            // Adiciona campo com valor vazio
                            submitedData[item] = "";
                          }
                        });
                      }
                      // Tenta converter os dados para API
                      const result = await mountRecords(
                        currentForm,
                        submitedData,
                        props.formData.fields,
                        props.formData.refValues,
                        props.formData.lkpValues,
                        checkedRowsData,
                        recordFormAction
                      );
                      // Remove campos vazios
                      var finalResult = removeUndefined(_.assign({}, ...result)) || {};
                      // Tenta enviar dados para API
                      const resp = await trySaveRecords(finalResult, recordFormAction);
                      if(_.isBoolean(resp)){
                        return resp;
                      }
                    }}
                  />
                </ConfigProvider>
              ) : null}
              {(queue?.status === "finished" ||
                queueStatus?.status === "finished") && (
                <Card style={{ height: "100vh" }}>
                  <Result
                    status="success"
                    title="Importação concluída!"
                    subTitle="Todos os dados foram processados e armazenados com sucesso."
                  />
                </Card>
              )}
              {/* {queue?.status !== "finished" &&
                queue?.status !== "error" &&
                queueStatus && (
                  <Card style={{ height: "100vh" }}>
                    <Result
                      icon={<Spin size="large" />}
                      title="Processando dados..."
                      subTitle="Organizando os dados. Se houver campos referenciados, essa etapa pode levar um pouco mais de tempo. Aguarde um momento, por favor."
                    />
                  </Card>
                )} */}
              {!queue && !queueStatus && (
                <Card style={{ height: "100vh" }}>
                  <Result
                    icon={<Spin size="large" />}
                    title="Processando dados..."
                    subTitle="Organizando os dados. Se houver campos referenciados, essa etapa pode levar um pouco mais de tempo. Aguarde um momento, por favor."
                  />
                </Card>
              )}
            </Card>
          </Layout>
          <Notification />
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}

const mapStateToProps = (all) => {
  return {
    user: all.session.user,
    formData: all.records,
  };
};

export default connect(mapStateToProps)(ImportStatus);
