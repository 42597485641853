import React, {useState} from "react";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";
import { createStandaloneToast } from "@chakra-ui/react";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import { QuickStartDecorator, PageHeaderDecorator } from "assets/icons";
import { Docs } from "assets/icons";
import {userApi} from "../../../services/actions/users";
import {useSelector} from "react-redux";


/**
 * Misc
 */
const { Content } = Layout;
const { Paragraph } = Typography;
const { ToastContainer } = createStandaloneToast();
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function NewUser() {
  const {company} = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState(null);
  const [form] = Form.useForm();
  const history = useHistory();

  /**
   * Cria novo usuário
   * @param {*} values
   */
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const {data,status} = await userApi.create({
        ...values,
        companyId: company.companyId,
        unitId:company.unitId
      })
      if(status === 201){
        history.push(`/usuarios/editar/${data.id}`);
      }
    }catch (error) {
      setError(error || "Erro ao tentar criar usuário");
    }finally {
      setLoading(false);
    }

  };

  const IconLink = ({ Src, text, link }) => {
    return (
        <a
            style={{ display: "inline-flex", verticalAlign: "middle",marginTop: -3, marginRight: 16, lineHeight: 2.5, cursor:"pointer"}}
            href={link}
            target="_blank"
            rel="noreferrer"
        >
          {typeof Src === "string" ? (
              <img
                  className="example-link-icon"
                  src={Src}
                  alt={text}
                  style={{ marginRight: 8 }}
                  width={24}
              />
          ) : (
              <Src className="example-link-icon" alt={text} style={{ marginRight: 8 }} width={24} />
          )}

          <label style={{cursor:"pointer"}}>{text}</label>
        </a>
    );
  };


  const routes = [
    {
      breadcrumbName: "usuários",
      href: "/usuarios/todos",
    },
    {
      breadcrumbName: "novo",
    },
  ];

  const content = (
    <>
      <Paragraph style={{ paddingBottom: "2rem" }}>
        Crie usuários capazes de operar, gerenciar e personalizar o sistema de
        acordo suas necessidades.
      </Paragraph>
      <div>
        <IconLink
          Src={QuickStartDecorator}
          text="Como começar"
          link="https://cantoporto.gitbook.io/sac/guides/criando-seu-primeiro-formulario"
        />
        <IconLink
          Src={Docs}
          text="Documentação"
          link={`${process.env.REACT_APP_API_PROD}/docs`}
        />
      </div>
    </>
  );

  const ContentAlternative = ({ children, extraContent }) => (
    <Row>
      <div style={{ flex: 1 }}>{children}</div>
      <div className="image">{extraContent}</div>
    </Row>
  );

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", minHeight: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0 24px 0", background: "#fff" }}
              title="Novo usuário"
              subTitle="Usuários responsáveis para operar e gerenciar o sistema."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a, index) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={index}>
                        <a
                          href={a.href ? a.href : undefined}
                          onClick={() => history.push(a.href)}
                        >
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item key={index}>
                        {a.breadcrumbName}
                      </Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            >
              <ContentAlternative
                extraContent={<PageHeaderDecorator alt="content" width="100%"/>}
              >
                {content}
              </ContentAlternative>
            </PageHeader>
            <Content
              className="site-layout-background"
              style={{
                padding: 46,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="firstName"
                      label="Nome"
                      rules={[
                        {
                          required: true,
                          message: "Por favor insira o primeiro nome!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      label="Sobrenome"
                      rules={[
                        {
                          required: true,
                          message: "Por favor coloque o sobrenome!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="username"
                      label="Nome de usuário"
                      rules={[
                        {
                          required: true,
                          message: "Por favor insira seu nome de usuário!",
                          whitespace: true,
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: "email",
                          message:
                            "E-mail que você digitou não é um e-mail válido!",
                        },
                        {
                          required: true,
                          message: "Por favor insira seu e-mail!",
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Senha"
                      rules={[
                        {
                          required: true,
                          message: "Por favor insira sua senha!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="passwordConfirm"
                      label="Confirme a senha"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Por favor, confirme sua senha!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "As duas senhas que você digitou não coincidem!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                      <Button type="primary" htmlType="submit" loading={loading}>
                        { loading ? 'Carregando...' : 'Cadastrar usuário'}
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
      <ToastContainer />
    </Layout>
  );
}
