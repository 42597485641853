import {ProCard} from "@ant-design/pro-components";
import {Alert, Button, Drawer, message, Modal, Select, Space, Spin, Table} from "antd";
import React, {useEffect, useState} from "react";
import {userApi as api, userApi} from "../../services/actions/users";
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {PermissionConfig} from "../PermissionConfig";

export const GroupDetails = ({groupId, isOpen, closeDrawer}) => {
    const {company} = useSelector(state => state.user);
    const [tab, setTab] = useState('description');
    const [openModalAddUserToGroup, setOpenModalAddUserToGroup] = useState(false);
    const [userToAdd, setUserToAdd] = useState(null);
    const [listUserToAdd, setListUserToAdd] = useState([]);

    const [openModalAddRoleToGroup, setOpenModalAddRoleToGroup] = useState(false);
    const [roleToAdd, setRoleToAdd] = useState(null);
    const [listRoleToAdd, setListRoleToAdd] = useState([]);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchGroupDetails = async () => {
        if (groupId) {
            try {
                setError(null);
                setData(null);
                setLoading(true);
                const response = await userApi.getGroupDetails(groupId,company.unitId);
                setData(response.data);
            } catch (error) {
                setError(error.message || "Erro ao carregar detalhes do grupo");
            } finally {
                setLoading(false);
            }
        }
    };

    const fetchPermissions = async () => {
        if (groupId) {
            try {
                const response = await userApi.getGroupDetails(groupId);
                setData(response.data);
            } catch (error) {
                setError(error.message || "Erro ao carregar detalhes do grupo");
            }
        }
    }

    useEffect(() => {
        fetchGroupDetails();
    }, [groupId]);

    const {group = null} = data || {};

    const userColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (dom, record) => `${record.firstName} ${record.lastName}`,
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
        },
        {
            title: 'Remover',
            render: (dom, record) => <Button icon={<DeleteOutlined/>} type={"danger"}
                                             onClick={() => handleConfirmRemoveUserFromGroup(record.firstName, record.id)}>Remover</Button>
        },
    ];

    const roleColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            render: (dom, record) => `${record.description.length > 40 ?  record.description.substring(0,40) + "..." : record.description}`,
        },
        {
            title: 'Remover',
            render: (dom, record) => <Button icon={<DeleteOutlined/>} type={"danger"} onClick={() => handleConfirmRemoveRoleFromGroup(record.name, record.id)}>Remover</Button>
        },
    ];

    const handleConfirmRemoveUserFromGroup = (firstName, userId) => {
        if (group) {
            Modal.confirm({
                title: "Remover Usuário",
                icon: <ExclamationCircleOutlined/>,
                content: <p>Tem certeza que deseja remover este usuário <b>{firstName}</b> do grupo <b>{group.name}</b>?
                </p>,
                onOk: async () => {
                    const response = await userApi.removeUserFromGroup(userId, group.id, null, company.unitId);
                    if (response.status === 201) {
                        fetchGroupDetails()
                    }
                },
            });
        }


    }

    const handleConfirmRemoveRoleFromGroup = (roleName, roleId) => {
        if (group) {
            Modal.confirm({
                title: "Remover Papel",
                icon: <ExclamationCircleOutlined/>,
                content: <p>Tem certeza que deseja remover este papel <b>{roleName}</b> do grupo <b>{group.name}</b>?
                </p>,
                onOk: async () => {
                    const response = await userApi.removeRoleFromGroup(roleId, group.id, company.unitId);
                    if (response.status === 201) {
                        fetchGroupDetails()
                    }
                },
            });
        }


    }

    const handleAddUserToGroup = async () => {
        if (company) {
            try {
                const response = await api.getUsersByUnit(company.unitId,company.unitId);
                const dataUser = response.data;
                if (dataUser) {
                    const idUsers = data.users.map(item => item.id);
                    const list = dataUser.filter(item => {
                        return !idUsers.includes(item.id);
                    }).map(item => {
                        return {
                            label: `${item.firstName} ${item.lastName} (${item.email})`,
                            value: item.id,
                        };
                    })
                    setListUserToAdd(list);
                    setUserToAdd(null)
                    setOpenModalAddUserToGroup(true)
                }
            } catch (e) {
               console.log(e)
            }


        }
    }

    const handleCancelAddUserToGroup = () => {
        setListUserToAdd([]);
        setUserToAdd(null)
        setOpenModalAddUserToGroup(false);
    }

    const handleOkAddUserToGroup = async () => {
        if(userToAdd === null){
            return message.info("Selecione uma opção")
        }

        try {
            setLoading(true)
            await userApi.addUserToGroup(userToAdd,groupId, null, company.unitId);
            setUserToAdd(null)
            setListUserToAdd([])
            setLoading(false);
            setOpenModalAddUserToGroup(false);
            fetchGroupDetails();
        }catch (error) {
            message.error(error.message || "Erro ao tentar adicionar usuario ao grupo!");
        }finally {
            setLoading(false);
        }

    }

    const handleOkAddRoleToGroup = async () => {
        if(roleToAdd === null){
            return message.info("Selecione uma opção")
        }

        try {
            setLoading(true)
            await userApi.addRoleToGroup(roleToAdd,groupId, company.unitId);
            setRoleToAdd(null)
            setListRoleToAdd([])
            setLoading(false);
            setOpenModalAddRoleToGroup(false);
            fetchGroupDetails();
        }catch (error) {
            message.error(error.message || "Erro ao tentar adicionar papel ao grupo!");
        }finally {
            setLoading(false);
        }

    }

    const handleAddRoleToGroup = async () => {
        if (company) {
            try {
                const response = await api.getRolesByUnit(company.unitId);
                const dataRoles = response.data;
                if (dataRoles) {
                    const idRoles = data.roles.map(item => item.id);
                    const list = dataRoles.filter(item => {
                        return !idRoles.includes(item.id);
                    }).map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    })
                    setListRoleToAdd(list);
                    setRoleToAdd(null)
                    setOpenModalAddRoleToGroup(true)
                }
            } catch (e) {
                console.log(e)
            }


        }
    }

    const handleCancelAddRoleToGroup = async () =>{
        setListRoleToAdd([]);
        setRoleToAdd(null)
        setOpenModalAddRoleToGroup(false);
    }

    const renderContent = () => {
        if (loading) {
            return <Spin tip="Carregando..."/>;
        }

        if (error) {
            return <Alert message={error} type="error" showIcon/>;
        }

        if (!group) {
            return <Alert message="Grupo não encontrado" type="warning" showIcon/>;
        }

        return (
            <ProCard tabs={{tabPosition: "top", type: "card",activeKey: tab, onChange: (key) => {setTab(key)}}}>
                <ProCard.TabPane tab={'Descrição'} key="description">
                    {group.description || 'Sem descrição disponível.'}
                </ProCard.TabPane>
                <ProCard.TabPane tab={'Usuários'} key="users">
                    <Button type={"primary"} style={{width: "100%"}} onClick={() => handleAddUserToGroup()}>Adicionar
                        Usuario</Button>
                    <br/>
                    <br/>
                    <Table dataSource={data.users} columns={userColumns}/>
                </ProCard.TabPane>
                <ProCard.TabPane tab={'Papeis'} key="roles">
                    <Button type={"primary"} style={{width: "100%"}} onClick={() => handleAddRoleToGroup()}>Adicionar papel</Button>
                    <br/>
                    <br/>
                    <Table dataSource={data.roles} columns={roleColumns}/>
                </ProCard.TabPane>
                <ProCard.TabPane tab={'Permissões'} key="permissions">
                  <PermissionConfig type={'group'} id={groupId} assignedPermissions={data.permissions} reload={fetchPermissions}/>
                </ProCard.TabPane>
            </ProCard>
        );
    };

    return (
        <React.Fragment>
            {/*Modal para usuario para o grupo*/}
            <Modal
                title={`Adicionar usuario ao grupo ${group && group.name ? group.name : null}:`}
                visible={openModalAddUserToGroup}
                onOk={handleOkAddUserToGroup}
                onCancel={handleCancelAddUserToGroup}
                okText="Adicionar"
                cancelText="Cancelar"
                zIndex={1001}
                okButtonProps={{
                    loading: loading,
                }}
            >
                <Select
                    value={userToAdd}
                    onChange={(value) => setUserToAdd(value)}
                    showSearch={true}
                    placeholder={"Selecione um usuario"}
                    style={{width: '100%'}}
                    options={listUserToAdd}
                />
                <br/>
            </Modal>

            {/*Modal para papel para o grupo*/}
            <Modal
                title={`Adicionar papel ao grupo ${group && group.name ? group.name : null}:`}
                visible={openModalAddRoleToGroup}
                onOk={handleOkAddRoleToGroup}
                onCancel={handleCancelAddRoleToGroup}
                okText="Adicionar"
                cancelText="Cancelar"
                zIndex={1001}
                okButtonProps={{
                    loading: loading,
                }}
            >
                <Select
                    value={roleToAdd}
                    onChange={(value) => setRoleToAdd(value)}
                    showSearch={true}
                    placeholder={"Selecione um papel"}
                    style={{width: '100%'}}
                    options={listRoleToAdd}
                />
                <br/>
            </Modal>
            <Drawer
                destroyOnClose
                title={group ? group.name : "Detalhes do Grupo"}
                placement={'right'}
                closable={true}
                visible={isOpen}
                width={"70%"}
                onClose={closeDrawer}
            >
                {renderContent()}
            </Drawer>
        </React.Fragment>
    );
};
