import React, {useEffect, useState, useCallback} from "react";
import {PermissionConfig} from "../../../components/PermissionConfig";
import {userApi} from "../../../services/actions/users"; // Certifique-se de importar o serviço correto
import {Spin, Alert} from "antd";

export function Permissions({user}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [assignedPermissions, setAssignedPermissions] = useState([]);

    const fetchUserPermissions = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await userApi.getPermissionsByUser(user.uuid);
            setAssignedPermissions(response.data);
        } catch (error) {
            setError("Erro ao carregar permissões do usuário.");
        } finally {
            setLoading(false);
        }
    }, [user.id]);

    useEffect(() => {
        fetchUserPermissions();
    }, [fetchUserPermissions]);

    if (loading) {
        return <Spin tip="Carregando permissões..." />;
    }

    if (error) {
        return <Alert message={error} type="error" showIcon />;
    }

    return (
        <PermissionConfig
            assignedPermissions={assignedPermissions}
            id={user.uuid}
            type="user"
        />
    );
}
