import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasAnyPermission } from "../../utils/permission";
import PermissionWrapper from "../PermissionWrapper";

const AuthRoute = ({ permissions = null, auth = false, component: Component, ...rest }) => {
    const location = useLocation();
    const authenticated = useSelector((state) => state.session.authenticated);

    if (location.pathname === "/") {
        const to = authenticated ? "/bem-vindo" : "/login";
        return <Redirect to={to} />;
    }


    else if (!auth) {
        return <Route {...rest} component={Component} />;
    }


    else if (auth && authenticated) {
        if (permissions) {
            return (
                <Route
                    {...rest}
                    render={(props) => (
                        <PermissionWrapper permissions={permissions} {...props}>
                            <Component {...props} />
                        </PermissionWrapper>
                    )}
                />
            );
        } else {
            return <Route {...rest} component={Component} />;
        }
    } else {
        return <Redirect to="/login" />;
    }
};

export default AuthRoute;
