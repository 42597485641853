// Core
import Welcome from "pages/Welcome";
import Dashboard from "pages/Dashboard";

// Users
import NewUser from "pages/Users/New";
import EditUser from "pages/Users/Edit";

// Forms
import NewForm from "pages/Forms/New";
import DetailsForm from "pages/Forms/Details";
import AllForms from "pages/Forms/All";
import RecordForms from "pages/Forms/Records";
import BinOfForm from "pages/Forms/Records/Bin";
import ImportStatus from "pages/Forms/Import/Status";

// Reports
import EditReport from "pages/Reports/Edit";
import ViewReport from "pages/Reports/View";
import AllReports from "pages/Reports/All";

// Activities
import AllActivities from "pages/Activities/All";

// Search
import NewFormCategory from "pages/Forms/Category/New";
import AllFormCategories from "pages/Forms/Category/All";
import FormSearchResults from "pages/Search/Results";
import EditFormCategory from "pages/Forms/Category/Edit";

// Misc
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "theme.css";
import AllActivitiesForms from "./pages/ActivitiesForms/All";
import AllAutomations from "./pages/Automations/All";
import NewAutomation from "./pages/Automations/New";
import EditAutomation from "./pages/Automations/Edit";
import Login from "./pages/Login/index";
import Callback from "./pages/Login/Callback";
import Profile from "./pages/Profile";
import UserAndPermission from "./pages/Users";
import LoginApp from "./pages/LoginApp";
import CallbackApp from "./pages/LoginApp/Callback";
import Logout from "./pages/Logout";
import ForbiddenPage from "./pages/ForbiddenPage/ForbiddenPage";
import RefreshToken from "./pages/LoginApp/RefreshToken";

// Definindo as rotas
export const routes = [
    {
        path: "/",
        component: Login,
        exact: true,
        auth: false
    },
    {
        path: "/login",
        component: Login,
        exact: true,
        auth: false
    },
    {
        path: "/logout",
        component: Logout,
        exact: true,
        auth: false
    },
    {
        path: "/login/callback",
        component: Callback,
        exact: true,
        auth: false
    },
    {
        path: "/login-app",
        component: LoginApp,
        exact: true,
        auth: false
    },
    {
        path: "/login-app/callback",
        component: CallbackApp,
        exact: true,
        auth: false
    },
    {
        path: "/login-app/refresh",
        component: RefreshToken,
        exact: true,
        auth: false
    },
    {
        path: "/bem-vindo",
        component: Welcome,
        auth: true
    },
    {
        path: "/resultados/pesquisa/formularios",
        component: FormSearchResults, auth: true
    },
    {
        path: "/dashboard/resumo",
        component: Dashboard,
        auth: true,
        permission: ["VIEW_MAIN_DASHBOARD"]
    },
    {
        path: "/perfil", component: Profile, auth: true
    },
    {
        path: "/usuarios-e-permissoes",
        component: UserAndPermission,
        auth: true,
        permissions: [
            "CREATE_USER",
            "MANAGE_ROLES",
            "DELETE_USER",
            "UPDATE_USER",
            "RESET_PASSWORD",
            "REQUEST_PASSWORD_CHANGE"
        ]
    },
    {
        path: "/usuarios/editar/:id",
        component: EditUser,
        auth: true,
        permissions: [
            "UPDATE_USER",
            'RESET_PASSWORD',
            'REQUEST_PASSWORD_CHANGE',
            'MANAGE_ROLES'
        ]
    },
    {
        path: "/usuarios/novo",
        component: NewUser,
        auth: true,
        permissions: ["CREATE_USER"]
    },
    {
        path: "/formularios/novo",
        component: NewForm,
        auth: true,
        permissions: ["CREATE_FORM"]
    },
    {
        path: "/formularios/detalhes/:name",
        component: DetailsForm,
        auth: true,
        permissions: [
            'EDIT_FORM',
            'DELETE_FORM',
            'CREATE_FIELD_IN_FORM',
            'EDIT_FIELD_IN_FORM',
            'DELETE_FIELD_IN_FORM',
            'ORDER_FIELDS_IN_FORM'
        ]
    },
    {
        path: "/formularios/todos",
        component: AllForms,
        auth: true,
        permissions: [
            "CREATE_FORM",
            "EDIT_FORM",
            "DELETE_FORM",
            "CREATE_FIELD_IN_FORM",
            "EDIT_FIELD_IN_FORM",
            "DELETE_FIELD_IN_FORM",
            "ORDER_FIELDS_IN_FORM",
            "INSERT_DATA_IN_FORM",
            "EDIT_DATA_IN_FORM",
            "DELETE_DATA_IN_FORM",
            "VIEW_DATA_FROM_FORM",
            "IMPORT_DATA_IN_FORM",
            "VIEW_DATA_ARCHIVED_FROM_FORM"
        ]
    },
    {
        path: "/formularios/registros/:name",
        component: RecordForms,
        auth: true
    },
    {
        path: "/formularios/lixeira/:name",
        component: BinOfForm,
        auth: true,
        permissions: ["VIEW_DATA_ARCHIVED_FROM_FORM"]
    },
    {
        path: "/formularios/categoria/nova",
        component: NewFormCategory,
        auth: true,
        permissions: ["CREATE_CATEGORY"]},
    {
        path: "/formularios/categoria/todas",
        component: AllFormCategories,
        auth: true,
        permissions: ["CREATE_CATEGORY", "UPDATE_CATEGORY", "DELETE_CATEGORY"]
    },
    {
        path: "/formularios/categoria/editar/:id",
        component: EditFormCategory,
        auth: true,
        permissions: ["UPDATE_CATEGORY"]
    },
    {
        path: "/formularios/importar/detalhes/:id",
        component: ImportStatus,
        auth: true,
        permissions: ['IMPORT_DATA_IN_FORM']
    },
    {
        path: "/automacoes/nova",
        component: NewAutomation,
        auth: true,
        permissions: ['CREATE_AUTOMATION']
    },
    {
        path: "/automacoes/editar/:id/:tab?",
        component: EditAutomation,
        auth: true,
        permissions: [
            'EDIT_AUTOMATION',
            'DELETE_AUTOMATION',
            'VIEW_LOG_AUTOMATION'
        ]
    },
    {
        path: "/automacoes",
        component: AllAutomations,
        auth: true,
        permissions: [
            'CREATE_AUTOMATION',
            'EDIT_AUTOMATION',
            'DELETE_AUTOMATION',
            'VIEW_LOG_AUTOMATION'
        ]
    },
    {
        path: "/relatorios/editar/:id",
        component: EditReport,
        auth: true,
        // permissions: [
        //     'EDIT_REPORT',
        // ]
    },
    {
        path: "/relatorios/visualizar/:id",
        component: ViewReport,
        auth: true,
        // permissions: ["VIEW_REPORT_EMBED"]
    },
    {
        path: "/relatorios",
        component: AllReports,
        auth: true,
        permissions: [
            "VIEW_REPORT_EMBED",
        //     'DELETE_REPORT',
        //     'EDIT_REPORT',
        //     'CREATE_REPORT'
        ]
    },
    {
        path: "/atividades/todas",
        component: AllActivities,
        permission: ["VIEW_ACTIVITY_LOG"],
        auth: true
    },
    {
        path: "/atividades/formularios/todas",
        component: AllActivitiesForms,
        permission: ["VIEW_ACTIVITY_LOG"],
        auth: true
    },
    {
        path: '/403',
        component: ForbiddenPage,
        auth: false
    }
];
