import axios from "axios";
import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";

// Services
import getApiUrl from "services/getApiUrl";

// Actions
import { showNotification } from "./notification";

// Utils
import { toKeywordConvert } from "utils/convert-to-keyword";

// Types
const FETCH_FORM_EXPORT_DATA_REQUEST = "core/form/FETCH_FORM_EXPORT_DATA_REQUEST";
const FETCH_FORM_DATA_EXPORT_SUCCESS = "core/form/FETCH_FORM_DATA_EXPORT_SUCCESS";
const FETCH_FORM_DATA_EXPORT_FAILURE = "core/form/FETCH_FORM_DATA_EXPORT_FAILURE";

// Reducer
const initialState = {
  loading: true,
  dialogText: "",
};

export default function exportFormReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORM_EXPORT_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_FORM_DATA_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        dialogText: action.payload.dialogText,
      };
    case FETCH_FORM_DATA_EXPORT_FAILURE:
      return {
        ...state,
        loading: false,
        dialogText: action.payload,
      };
    default:
      return state;
  }
}

// Actions
export const exportFormData = (
  form,
  page,
  pageSize,
  createdAt,
  queryParams,
  createdBy,
  sorter = null
) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_FORM_EXPORT_DATA_REQUEST });
    try {
      const fields = await getApiUrl.get(`/objetos/${form}/campos/todos?withRef=true`);
      const { message: fieldsMessage } = fields.data;
      const sortBy = [];
      if (sorter && Object.entries(sorter).length > 0) {
        for (const key in sorter) {
          let index = fieldsMessage.findIndex(item => item.field === key);
          if (index !== -1) {
            sortBy.push({
              field: fieldsMessage[index]['field'],
              sort: sorter[key],
              fieldType: fieldsMessage[index]['fieldtype']
            });
          }else{
            sortBy.push({
              field: key,
              sort: sorter[key]
            });
          }
        }
      }
      const query = [];

      // Verifica se existe campos
      if (fieldsMessage) {
        // Array com nome de todos os campos
        const fieldNames = fieldsMessage.map((y) => y.field);

        // Verifica se está filtrando a tabela
        if (Object.keys(queryParams).length > 0) {
          fieldsMessage.map(async (data) => {
            const { field: fieldName, fieldtype: fieldType } = data;
            if (queryParams[fieldName]) {
              let fieldMapping;
              switch (fieldType) {
                case "Text":
                case "Time":
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value.keyword`]: queryParams[fieldName] ?? "",
                        },
                      },
                    },
                  }
                  break;
                case "Numeric":
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value`]: queryParams[fieldName] ?? "",
                        },
                      },
                    },
                  }
                  break
                case "Date":
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value.keyword`]: moment(queryParams[fieldName],'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00[Z]') ?? "",
                        },
                      },
                    },
                  }
                  break;
                case "refLookup":
                  if(_.isString(queryParams[fieldName]) || _.isNumber(queryParams[fieldName])){
                    const value = moment(queryParams[fieldName],'DD/MM/YYYY',true).isValid() ? moment(queryParams[fieldName],'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00[Z]') : queryParams[fieldName] ?? "";
                    fieldMapping = {
                      nested: {
                        path: fieldName,
                        query: {
                          match: {
                            [`${fieldName}.value.keyword`]:value,
                          },
                        },
                      },
                    }
                  }else if(_.isArray(queryParams[fieldName])){
                    fieldMapping = {
                      nested: {
                        path: fieldName,
                        query: {
                          terms: {
                            [`${fieldName}.value`]: queryParams[fieldName],
                          },
                        },
                      },
                    }
                  }

                  break;
                case "uniqueSelection":
                case "multipleSelection":
                  let terms = queryParams[fieldName];
                  if(terms.length > 0){
                    fieldMapping = {
                      nested:{
                        path: fieldName,
                        query:   {
                          terms: {
                            [`${fieldName}.value.keyword`]: terms,
                          },
                        },
                      },
                    };
                  }else {
                    return;
                  }

                  break;
                case "refUniqueSelection":
                case "refMultipleSelection":
                  const value = moment(queryParams[fieldName],'DD/MM/YYYY',true).isValid() ? moment(queryParams[fieldName],'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00[Z]') : queryParams[fieldName] ?? "";
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value.keyword`]: value,
                        },
                      },
                    },
                  };
                  break;
                default:
                  fieldMapping = {}
              }
              query.push(fieldMapping);
            }
          });
        }

        const startExport = await getApiUrl.post(
          `/exportar/registros/csv?index=${form}&page=${page}&pageSize=${pageSize}&isFilter=${(Object.keys(queryParams).length > 0 || createdAt)}`,
          {
            formName: form,
            query,
            createdAt,
            showOnly: [...fieldNames, "createdAt",'updatedAt'],
            createdBy,
            sorter:sortBy
          }
        );

        if (startExport && startExport.data.success) {
          const { message: dialogMessage } = startExport.data;

          dispatch({
            type: FETCH_FORM_DATA_EXPORT_SUCCESS,
            payload: {
              dialogText: dialogMessage,
            },
          });
          dispatch(showNotification(dialogMessage, "success"));

        } else {
          dispatch({
            type: FETCH_FORM_DATA_EXPORT_FAILURE,
            payload: "Nada encontrado, tente novamente!",
          });
        }
      } else {
        dispatch({
          type: FETCH_FORM_DATA_EXPORT_FAILURE,
          payload: "Nada encontrado, tente novamente!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_FORM_DATA_EXPORT_FAILURE, payload: error.message });
    }
  };
};
