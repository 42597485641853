export const sendEventLoginData = async (user) => {
    const payload = {
        user: user,
        token: user.access_token,
    };

    try {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({ action: 'logged_in', payload })
            );
        } else {
            console.error("Não foi possível enviar evento: 'ReactNativeWebView' não existe");
        }
    } catch (error) {
        console.error("Erro ao enviar evento para o ReactNativeWebView:", error);
    }
};
export const sendEvenRefreshTokenData = async (user) => {
    const payload = {
        user: user,
        token: user.access_token,
    };

    try {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({ action: 'refresh_token', payload })
            );
        } else {
            console.error("Não foi possível enviar evento: 'ReactNativeWebView' não existe");
        }
    } catch (error) {
        console.error("Erro ao enviar evento para o ReactNativeWebView:", error);
    }
};
