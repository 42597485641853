import {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {sendEvenRefreshTokenData} from "./sendEventUserData";
import {getUser,handleRefreshToken} from "../../utils/user-manager-app";


const RefreshToken = () => {
    const [authenticated,setAuthenticated] = useState(false);
    const [user,setUser] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser();
            if(user && user.expired){
                history.push("/login-app");
            }else{
                setAuthenticated(true);
                setUser(user);
            }
        }
        fetchUser();
    },[])

    useEffect(() => {
        const handleRefresh = async () => {
            if (authenticated) {
                handleRefreshToken().then(async (response) => {
                    await sendEvenRefreshTokenData(response)
                }).catch((err) => {
                    console.error("Erro ao lidar com refresh do token:", err);
                });
            }
        }
        handleRefresh();
    }, [authenticated, user]);

    return null;
};

export default RefreshToken;
