import ptBRIntl from "antd/lib/locale/pt_BR";
import {Button, ConfigProvider, message, Modal, Tooltip} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import React, {useRef, useState} from "react";
import {ProCard, ProTable} from "@ant-design/pro-components";
import {userApi} from "../../../services/actions/users";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import {GroupDetails} from "../../../components/GroupDetails";
import {useHistory} from "react-router-dom";
import NewGroupModal from "../../../components/NewGroupModal";

const Group = ({}) => {
    const actionRef = useRef();
    const history = useHistory();
    const {company} = useSelector(state => state.user);
    const columns = [
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            width: "auto",
            fieldProps: {
                placeholder: "",
            },
            sorter: true,
            ellipsis: true,
        },
        {
            title: "Descrição",
            dataIndex: "description",
            key: "description",
            search: false,
            width: "auto",
            sorter: true,
            ellipsis: true,
        },
        {
            title: "Atualizado",
            dataIndex: "createdAt",
            key: "createdAt",
            width: "auto",
            ellipsis: true,
            sorter: true,
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
                }
            },
            valueType: "dateTimeRange",
            fieldProps(form, config) {
                return {
                    ...config,
                    format: "DD/MM/YYYY HH:mm",
                    placeholder: "",
                };
            },

        },
        {
            title: "Criado",
            dataIndex: "updatedAt",
            key: "updatedAt",
            width: "auto",
            sorter: true,
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex], "YYYY-MM-DDTHH:mm:ss[Z]").format("DD/MM/YYYY HH:mm");
                }
            },
            valueType: "dateTimeRange",
            fieldProps(form, config) {
                return {
                    ...config,
                    format: "DD/MM/YYYY HH:mm",
                    placeholder: "",
                };
            },
            ellipsis: true,
        },
        {
            title: "Ações",
            render: (dom,{id}) => {
                return [
                    <React.Fragment>
                        <Button onClick={() => fetchGroupDetails(id)}>Detalhes</Button>
                        &nbsp;
                        &nbsp;
                        <Button icon={<DeleteOutlined/>} onClick={() => handleRemoveGroup(id)} type={"danger"}>Excluir Grupo</Button>
                    </React.Fragment>
                ];
            },
            search: false,
            width: "auto",
            ellipsis: true,
        },

    ];

    const [group, setGroup] = useState(null);
    const [open, setOpen] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const handleCloseDrawer = () => {
        setOpen(false);
        setGroup(null);
    }


    const fetchGroupDetails = (id) => {
        setGroup(id)
        setOpen(true);
    }

    const handleRemoveGroup = async (groupId) => {
        Modal.confirm({
            title: `Você realmente deseja excluir esse grupo?`,
            icon: <ExclamationCircleOutlined />,
            content: "Confirme a exclusão antes de continuar.",
            okText: "Confirmar",
            onOk : async () =>  {
                try {
                    await userApi.removeGroup(groupId,company.unitId);
                    message.success("Grupo removido com sucesso!");
                    actionRef.current?.reload();
                } catch (error) {
                    message.error(error.message || "Erro ao remover o grupo.");
                }
            }
        })

    }

    const handleCloseModal = () => {
        setOpenModal(false)
        actionRef.current?.reload();
    }



    return (
        <ConfigProvider locale={ptBRIntl}>
            {group && <GroupDetails groupId={group} isOpen={open} closeDrawer={handleCloseDrawer}/>}
            <NewGroupModal closeModal={handleCloseModal} isOpen={openModal} />
            <ProCard extra={<Button icon={<PlusCircleOutlined/>} onClick={() => setOpenModal(true)}> Novo Grupo</Button>}>
                <ProTable
                    actionRef={actionRef}
                    request={async (params = {}, sorter, filter) => {
                        const {
                            name = null,
                            createdAt = null,
                            updatedAt = null,
                            current: page,
                            pageSize: perPage
                        } = params;


                        const data = {
                            unitId: company.unitId,
                            page,
                            perPage
                        };

                        if(Object.keys(sorter).length > 0){
                            const field = Object.keys(sorter)[0]
                            data['orderByField'] =field;
                            data['orderBySort'] = sorter[field] === 'ascend' ? 'ASC' : 'DESC';
                        }


                        if(name){
                            data['name'] = name;
                        }

                        if(createdAt){
                            data['createdAtStart'] = moment( createdAt[0], "DD/MM/YYYY HH:mm",true).toISOString();
                            data['createdAtEnd'] = moment( createdAt[1], "DD/MM/YYYY HH:mm",true).toISOString();
                        }

                        if(updatedAt){
                            data['updatedAtStart'] = moment( updatedAt[0], "DD/MM/YYYY HH:mm",true).toISOString();
                            data['updatedAtEnd'] = moment( updatedAt[1], "DD/MM/YYYY HH:mm",true).toISOString();
                        }

                        const response = await userApi.getGroups(data);
                        return {
                            data: response.data.items,
                            success: true,
                            total: response.data.total,
                        };
                    }}
                    columns={columns}
                    rowKey="id"
                />
            </ProCard>
        </ConfigProvider>
    )
}

export default Group
