import React from "react";
import {connect, useSelector} from "react-redux";
import {
    Layout,
    PageHeader,
    Breadcrumb,
    Button,
    ConfigProvider,
    Card,
    Tooltip,
} from "antd";
import {ProTable} from "@ant-design/pro-components";
import {useHistory} from "react-router-dom";
import ptBRIntl from "antd/lib/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";


import {userApi} from "../../../services/actions/users";
import PermissionWrapperComponent from "../../../components/PermissionWrapperComponent";

function AllUsers({user}) {
    const history = useHistory();

    const columns = [
        {
            search: false,
            sorter: true,
            title: "ID",
            dataIndex: "uuid",
            key: "uuid",
            render: (dom, record, index, action, schema) => {
                const text = record[schema.dataIndex];
                if (text) {
                    return (
                        <Button
                            type="link"
                            style={{
                                color: "#12b398",
                            }}
                            onClick={() => history.push(`/usuarios/editar/${record.uuid}`)}
                        >
                            {text}
                        </Button>
                    );
                }

            },
            width: "auto",
            ellipsis: true,
            valueType: "text",
            fieldProps: {
                placeholder: "",
            },
        },
        {
            sorter: false,
            title: "Nome",
            dataIndex: "firstName",
            key: "firstName",
            width: "auto",
            ellipsis: true,
            valueType: "text",
            fieldProps: {
                placeholder: "",
            },
        },
        {
            sorter: false,
            title: "Sobrenome",
            dataIndex: "lastName",
            key: "lastName",
            width: "auto",
            ellipsis: true,
            valueType: "text",
            fieldProps: {
                placeholder: "",
            },
        },
        {
            sorter: false,
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            valueType: "text",
            fieldProps: {
                placeholder: "",
            },
            width: "auto",
            ellipsis: true,
        },
        {

            sorter: true,
            title: "Usuário",
            dataIndex: "username",
            key: "username",
            valueType: "text",
            fieldProps: {
                placeholder: "",
            },
            width: "auto",
            ellipsis: true
        },
        {
            sorter: false,
            title: "Criado em",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex]).format("DD/MM/YYYY HH:mm:ss");
                }
            },
            width: "auto",
            ellipsis: true,
            search: false,
        },
        {
            sorter: false,
            title: "Atualizado em",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (dom, entity, index, action, schema) => {
                if (action) {
                    return moment(entity[schema.dataIndex]).format("DD/MM/YYYY HH:mm:ss");
                }
            },
            width: "auto",
            ellipsis: true,
            search: false,
        },
    ];
    const company = useSelector(state => state.user.company);

    return (<React.Fragment>
        <Card>
            <ConfigProvider locale={ptBRIntl}>
                {company !== null && <ProTable
                    rowKey="_id"
                    type="table"
                    request={async (params = {}, sorter, filter) => {
                        const {
                            firstName = null,
                            lastName = null,
                            email = null,
                            username = null,
                        } = params
                        const data = {};
                        if (firstName) {
                            data['firstName'] = firstName;
                        }
                        if (lastName) {
                            data['lastName'] = lastName;
                        }
                        if (email) {
                            data['email'] = email;
                        }
                        if (username) {
                            data['username'] = username;
                        }

                        const response = await userApi.getAll({
                            ...data,
                            page: params.current,
                            perPage: params.pageSize,
                            unitId: company.unitId
                        });

                        return {
                            data: response.data.users,
                            success: true,
                            total: response.data.total,
                        };
                    }}
                    columns={columns}
                    dateFormatter={"number"}
                    scroll={{x: true}}
                    style={{padding: 0}}
                    toolbar={{
                        actions: [
                            <PermissionWrapperComponent permissions={['CREATE_USER']}>
                                <Button type="primary"
                                        onClick={() =>
                                            history.push(`/usuarios/novo`)
                                        }>
                                    Adicionar
                                </Button>
                            </PermissionWrapperComponent>

                        ],
                    }}
                />}
            </ConfigProvider>
        </Card>
    </React.Fragment>);
}

const mapStateToProps = ({session}) => ({
    user: session.user,
});

export default connect(mapStateToProps)(AllUsers);
