import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";

// Services
import getApiUrl from "services/getApiUrl";

// Utils
import { showNotification } from "./notification";
import { filesHistoryErrosMsgByForm } from "auth/actions/importActions";

// Types
const FETCH_FORM_DATA_REQUEST = "core/form/FETCH_FORM_DATA_REQUEST";
const FETCH_FORM_DATA_SUCCESS = "core/form/FETCH_FORM_DATA_SUCCESS";
const FETCH_FORM_DATA_FAILURE = "core/form/FETCH_FORM_DATA_FAILURE";
const FETCH_FORM_VALUE_ADD = "core/form/FETCH_FORM_VALUE_ADD";
const FETCH_FORM_VALUE_DELETE_ALL = "core/form/FETCH_FORM_VALUE_DELETE_ALL";
const FETCH_FORM_READONLY_ADD = "core/form/FETCH_FORM_READONLY_ADD";
const FETCH_FORM_READONLY_DELETE = "core/form/FETCH_FORM_READONLY_DELETE";
const FETCH_FORM_READONLY_DELETE_ALL =
  "core/form/FETCH_FORM_READONLY_DELETE_ALL";

// Reducer
const initialState = {
  loading: true,
  columns: [],
  records: [],
  fields: [],
  refFields: [],
  refValues: {},
  lkpValues: {},
  queueStatus: {},
  queueErrorsMsg: [],
  total: 0,
  totalInIndex: 0,
  error: "",
};

export default function formDataReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORM_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_FORM_DATA_SUCCESS:
      // console.log("FETCH_FORM_DATA_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        columns: action.payload.keys,
        records: action.payload.data,
        fields: action.payload.fields,
        refFields: action.payload.refFields,
        queueStatus: action.payload.queueStatus,
        queueErrorsMsg: action.payload.queueErrorsMsg,
        total: action.payload.total,
        totalInIndex: action.payload.totalInIndex,
        error: "",
      };
    case FETCH_FORM_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        columns: [],
        records: [],
        fields: [],
        refFields: [],
        refValues: {},
        lkpValues: {},
        queueStatus: {},
        queueErrorsMsg: [],
        total: 0,
        totalInIndex: 0,
        error: action.payload,
      };
    case FETCH_FORM_VALUE_ADD:
      // console.log("FETCH_FORM_VALUE_ADD", action.payload);
      const newRefFieldsLabelsValues = {
        ...state.refValues,
        [action.payload.fieldName]: action.payload.refData,
      };
      return {
        ...state,
        refValues: newRefFieldsLabelsValues,
      };
    case FETCH_FORM_VALUE_DELETE_ALL:
      return {
        ...state,
        refValues: {},
      };
    case FETCH_FORM_READONLY_ADD:
      // console.log("FETCH_FORM_READONLY_ADD", action.payload);
      const newLkpValues = {
        ...state.lkpValues,
        [action.payload.fieldName]: [
          ...(state.lkpValues[action.payload.fieldName] || []),
          action.payload.lkpData,
        ],
      };
      return {
        ...state,
        lkpValues: newLkpValues,
      };
    case FETCH_FORM_READONLY_DELETE:
      // console.log("FETCH_FORM_READONLY_DELETE", action.payload);
      const newLkpRemoved = _.omit(state.lkpValues, action.payload.forRemove);
      return {
        ...state,
        lkpValues: newLkpRemoved,
      };
    case FETCH_FORM_READONLY_DELETE_ALL:
      return {
        ...state,
        lkpValues: {},
      };
    default:
      return state;
  }
}

// Actions
export const fetchFormData = (form, page, pageSize, createdAt, queryParams,sorter = null) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_FORM_DATA_REQUEST });
    try {
      const fields = await getApiUrl.get(
        `/objetos/${form}/campos/todos?withRef=true`
      );
      const { message: fieldsMessage } = fields.data;
      const sortBy = [];
      if (sorter && Object.entries(sorter).length > 0) {
        for (const key in sorter) {
          let index = fieldsMessage.findIndex(item => item.field === key);
          if (index !== -1) {
            sortBy.push({
              field: fieldsMessage[index]['field'],
              sort: sorter[key],
              fieldType: fieldsMessage[index]['fieldtype'],
              returnType: fieldsMessage[index]['returntype']
            });
          }else{
            sortBy.push({
              field: key,
              sort: sorter[key]
            });
          }
        }
      }
      const query = [];
      // Verifica se existe campos
      if (fieldsMessage) {
        // Array com nome de todos os campos
        const fieldNames = fieldsMessage
          .sort((a, b) => a.index - b.index)
          .map((y) => y.field);
        // Array com todos os campos referenciados e consulta
        const onlyRefFields = _.flatMap(fieldsMessage, "refFields");
        const onlyLkpFields = _.flatMap(fieldsMessage, "getValueOf");
        // Verifica se está filtrando a tabela
        if (Object.keys(queryParams).length > 0) {
          fieldsMessage.map(async (data) => {
            const { field: fieldName, fieldtype: fieldType, returntype= null } = data;
            if (queryParams[fieldName]) {
              let fieldMapping;
              switch (fieldType) {
                case "Date":
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value.keyword`]: moment(queryParams[fieldName],'DD/MM/YYYY').startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') ?? "",
                        },
                      },
                    },
                  }
                  break;
                case "refLookup":
                  if(_.isString(queryParams[fieldName]) || _.isNumber(queryParams[fieldName])){
                    const value = moment(queryParams[fieldName],'DD/MM/YYYY',true).isValid() ? moment(queryParams[fieldName],'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00[Z]') : queryParams[fieldName] ?? "";
                    fieldMapping = {
                      nested: {
                        path: fieldName,
                        query: {
                          match: {
                            [`${fieldName}.value.keyword`]:value,
                          },
                        },
                      },
                    }
                  }else if(_.isArray(queryParams[fieldName])){
                    fieldMapping = {
                      nested: {
                        path: fieldName,
                        query: {
                          terms: {
                            [`${fieldName}.value`]: queryParams[fieldName],
                          },
                        },
                      },
                    }
                  }

                  break;
                case "Numeric":
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value`]: queryParams[fieldName] ?? "",
                        },
                      },
                    },
                  }
                  break
                case 'formula':
                  fieldMapping = (returntype === "Numeric") ? {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value`]: queryParams[fieldName] ?? "",
                        },
                      },
                    },
                  } : {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value.keyword`]: queryParams[fieldName] ?? "",
                        },
                      },
                    },
                  }
                  break
                case "Text":
                case "Time":
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value.keyword`]: queryParams[fieldName] ?? "",
                        },
                      },
                    },
                  }
                  break;
                case "refUniqueSelection":
                case "refMultipleSelection":
                  const value = moment(queryParams[fieldName],'DD/MM/YYYY',true).isValid() ? moment(queryParams[fieldName],'DD/MM/YYYY').format('YYYY-MM-DDT00:00:00[Z]') : queryParams[fieldName] ?? "";
                  fieldMapping = {
                    nested: {
                      path: fieldName,
                      query: {
                        match: {
                          [`${fieldName}.value.keyword`]: value,
                        },
                      },
                    },
                  };
                  break;
                case "uniqueSelection":
                case "multipleSelection":
                  let terms = queryParams[fieldName];
                  if(terms.length > 0){
                    fieldMapping = {
                      nested:{
                        path: fieldName,
                        query:   {
                          terms: {
                            [`${fieldName}.value.keyword`]: terms,
                          },
                        },
                      },
                    };
                  }else {
                    return;
                  }

                  break;
                default:
                  fieldMapping = {};
              }
              query.push(fieldMapping);
            }
          });
        }
        const refFields = await getApiUrl.post(
          `/objetos/campos/pesquisar/id`,
          {
            fields: _.compact(_.concat(onlyRefFields, onlyLkpFields)),
          }
        );
        const records = await getApiUrl.post(
          `/objetos/formularios/dados/paginado?index=${form}&page=${page}&pageSize=${pageSize}&isFilter=${
            Object.keys(queryParams).length > 0 || createdAt ? true : false
          }`,
          {
            query,
            createdAt,
            showOnly: [...fieldNames, "createdAt", "updatedAt"],
            sorter:sortBy
          }
        );
        if (records && records.data.success) {
          const {
            message: recordsMessage,
            total: totalMessage,
            totalInIndex: totalInIndexMessage,
          } = records.data;
          // Obtém as chaves de cada campo
          const allKeys = _.map(fieldsMessage, "field");
          dispatch({
            type: FETCH_FORM_DATA_SUCCESS,
            payload: {
              keys: allKeys,
              data: recordsMessage,
              fields: fieldsMessage.sort((a, b) => a.index - b.index),
              refFields: refFields?.data.message || [],
              total: totalMessage || 0,
              totalInIndex: totalInIndexMessage || 0,
            },
          });
        } else {
          dispatch({
            type: FETCH_FORM_DATA_FAILURE,
            payload:
              "Nada encontrado ao tentar obter o histórico do formulário, atualize a página e tente novamente!",
          });
        }
      } else {
        dispatch({
          type: FETCH_FORM_DATA_FAILURE,
          payload:
            "Não encontramos nenhum campo no formulário, atualize a página e tente novamente!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_FORM_DATA_FAILURE, payload: error.message });
    }
  };
};

export const fetchImportFormData = (form, queueId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_FORM_DATA_REQUEST });
    try {
      const fields = await getApiUrl.get(
        `/objetos/${form}/campos/todos?withRef=true`
      );
      const { message: fieldsMessage } = fields.data;
      // Verifica se existe campos
      if (fieldsMessage) {
        // Array com todos os campos referenciados e consulta
        const onlyRefFields = _.flatMap(fieldsMessage, "refFields");
        const onlyLkpFields = _.flatMap(fieldsMessage, "getValueOf");
        const refFields = await getApiUrl.post(`/objetos/campos/pesquisar/id`,
          {
            fields: _.compact(_.concat(onlyRefFields, onlyLkpFields)),
          }
        );
        // Array com todos os registros da importação
        const importRecords = await getApiUrl.get(
          `/importar/registros/historico/formulario?id=${queueId}`
        );
        // Array com todos os erros encontrados durante a importação
        const importRecordsErrors = await getApiUrl.get(
          `/importar/registros/historico/formulario/erros/mensagens?formName=${form}&queueId=${queueId}`
        );
        if (
          importRecords &&
          importRecordsErrors &&
          importRecords.data.success
        ) {
          const { message: recordsMessage } = importRecords.data;
          const { message: recordsErrorsMessage } = importRecordsErrors.data;
          // Separa os registros já reenviados
          const updatedRecords =
            recordsMessage[0].errorDocuments.filter(
              (errorDoc) => !recordsMessage[0].clearDocs.includes(errorDoc._id)
            ) || [];
          // Obtém as chaves de cada campo
          const allKeys = _.map(fieldsMessage, "field");
          // Mantém em cache os detalhes da importação
          dispatch({
            type: FETCH_FORM_DATA_SUCCESS,
            payload: {
              keys: allKeys,
              data: updatedRecords || [],
              fields: fieldsMessage.sort((a, b) => a.index - b.index),
              refFields: refFields?.data.message || [],
              queueStatus: recordsMessage[0] || {},
              queueErrorsMsg: recordsErrorsMessage || [],
              total: _.size(updatedRecords),
            },
          });
        } else {
          dispatch({
            type: FETCH_FORM_DATA_FAILURE,
            payload:
              "Nada encontrado ao tentar obter o histórico da importação, atualize a página e tente novamente!",
          });
        }
      } else {
        dispatch({
          type: FETCH_FORM_DATA_FAILURE,
          payload:
            "Não encontramos nenhum campo no formulário, atualize a página e tente novamente!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_FORM_DATA_FAILURE, payload: error.message });
    }
  };
};

export const addFormData = (fieldName, fieldValue) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_FORM_READONLY_ADD,
        payload: {
          fieldName: fieldName,
          lkpData: fieldValue,
        },
      });
    } catch (error) {
      dispatch(
        showNotification(
          `Algo deu errado ao tentar anexar os resultados do campo ${fieldName} ao formulário, tente novamente!`,
          "error"
        )
      );
    }
  };
};

export const deleteFormData = (fields) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_FORM_READONLY_DELETE,
      payload: {
        forRemove: fields,
      },
    });
  };
};

export const deleteAllFormData = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_FORM_READONLY_DELETE_ALL,
    });
  };
};

export const addRefFieldsFormData = (fieldName, fieldValue) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_FORM_VALUE_ADD,
        payload: {
          fieldName: fieldName,
          refData: fieldValue,
        },
      });
    } catch (error) {
      dispatch(
        showNotification(
          `Algo deu errado ao tentar anexar os resultados do campo ${fieldName} ao formulário, tente novamente!`,
          "error"
        )
      );
    }
  };
};

export const deleteAllRefFieldsFormData = () => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_FORM_VALUE_DELETE_ALL,
    });
  };
};

export const validateFormula = async (value, formName) => {
  return await getApiUrl
      .post(`/objetos/formularios/campos/formula/validar`, {
        formula: value,
        formName
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
}
