import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    Layout,
    PageHeader,
    Breadcrumb,
} from "antd";
import {useHistory, useParams} from "react-router-dom";
import "moment/locale/pt-br";
import "reactjs-popup/dist/index.css";
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import {LayoutTopSide} from "components/NavBar/styles";
import FlowBuilder from "./Flow";
import Loader from "react-loader-spinner";
import colors from "../../../components/Colors";
import {deleteAutomation, getAutomationById} from "../../../ducks/automation";
import {checkPermission, getAllPermissionsByUnit} from "../../../utils/permission";

function EditAutomation({automation}) {
    const permissions = useSelector((state) => state.user.permissions);
    const company = useSelector((state) => state.user.company);
    const [permissionEntity, setPermissionEntity] = useState(null)
    const {id, tab} = useParams();
    const {error,loading} = automation
    const history = useHistory();
    const dispatch = useDispatch();

    const routes = [
        {
            breadcrumbName: "Automações",
            href: "/automacoes",
        },
        {
            breadcrumbName: "Editar",
            href: `/automacoes/editar/${id}`,
        },
    ];

    useEffect(() => {
        dispatch(getAutomationById(id, history));
    },[id]);

    useEffect(() => {

        if (automation && automation.automation && permissions && permissions.length > 0) {
            const permissionEntityData = getAllPermissionsByUnit(
                permissions,
                company.unitId,
                ['EDIT_AUTOMATION', 'DELETE_AUTOMATION', 'VIEW_LOG_AUTOMATION']
            ).filter(
                item => {
                    return item.context.entity_type === "automation" &&
                        item.context.entity_id === automation.automation.id;
                }
            );
            if(permissionEntityData.length > 0) {
                setPermissionEntity(permissionEntityData);
            }else{
                history.push("/403")
            }

        }
    }, [company, permissions, automation]);

    const hasPermissionToEdit = automation?.automation && company && permissionEntity && checkPermission(permissionEntity, 'EDIT_AUTOMATION', company.unitId, 'automation', automation?.automation.id)
    const hasPermissionToDelete = automation?.automation && company && permissionEntity && checkPermission(permissionEntity, 'DELETE_AUTOMATION', company.unitId, 'automation',  automation?.automation.id)
    const hasPermissionToViewLog = automation?.automation && company && permissionEntity && checkPermission(permissionEntity, 'VIEW_LOG_AUTOMATION', company.unitId, 'automation',  automation?.automation.id)

    return (
        <Layout>
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout
                        style={{
                            padding: "0 24px 24px",
                            minHeight: "100vh",
                        }}
                    >
                        <PageHeader
                            style={{margin: "16px 0 5px 0"}}
                            title="Editar Automação"
                            onBack={() => window.history.back()}
                            breadcrumb={
                                <Breadcrumb>
                                    {routes.map((a) =>
                                        a.hasOwnProperty("href") ? (
                                            <Breadcrumb.Item key={Math.random()}>
                                                <a href onClick={() => history.push(a.href)}>
                                                    {a.breadcrumbName}
                                                </a>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                                        )
                                    )}
                                </Breadcrumb>
                            }
                        />
                        <div style={{
                            width: '100%',
                            height: '100%',
                            background: "#fff",
                        }}>
                            {(loading) && <Loader
                                type="ThreeDots"
                                color={colors.theme}
                                height={49}
                                width={100}
                            />}
                            {(error) && <div>Erro ao carregar dados: {error}</div>}
                            {(!error && !loading && automation) && <FlowBuilder tab={tab}
                                                                                hasPermissionToEdit={hasPermissionToEdit}
                                                                                hasPermissionToDelete={hasPermissionToDelete}
                                                                                hasPermissionToViewLog={hasPermissionToViewLog}
                            />}
                        </div>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}

const mapStateToProps = ({automation}) => ({
    automation: automation,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps,mapDispatchToProps)(EditAutomation);
