import React, { useEffect } from "react";
import {
  Layout,
  PageHeader,
  Breadcrumb,
  Row,
  Col,
  Form,
  Input,
  Button,
  Modal,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { Colorpicker } from "antd-colorpicker";

/**
 * Actions
 */
import {
  allCategoriesObj,
  findCategoryObj,
  editCategoryObj,
} from "auth/actions/objActions";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import { LayoutTopSide } from "components/NavBar/styles";
import {useSelector} from "react-redux";
import {checkPermission, hasAnyPermission} from "../../../../utils/permission";

/**
 * Misc
 */
const { confirm } = Modal;

const { Content } = Layout;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function EditFormCategory() {
  const user = useSelector((state) => state.session.user);
  const permissions = useSelector((state) => state.user.permissions);
  const company = useSelector((state) => state.user.company);

  const history = useHistory();
  const [form] = Form.useForm();

  const { id } = useParams();

  const routes = [
    {
      breadcrumbName: "formulários",
      href: "/formularios/todos",
    },
    {
      breadcrumbName: "categorias",
      href: "/formularios/categoria/todas",
    },
    {
      breadcrumbName: "editar",
    },
  ];

  useEffect(() => {
   if(checkPermission(permissions,'UPDATE_CATEGORY', company.unitId,'category',id)){
     findCategoryObj({
       id: id,
     }).then((items) => {
       form.setFieldsValue({
         name: items.message.name,
         color: items.message.color,
       });
     });
   }else{
     if(permissions && company){
       history.push("/403")
     }
   }

  }, [permissions,company]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * Salva alterações na categoria
   * @param {*} values
   */
  const onFinish = (values, { newKey = Date.now().toString() } = {}) => {
    confirm({
      title: `Você realmente deseja editar a categoria?`,
      icon: <ExclamationCircleOutlined />,
      content: "Confirme as alterações antes de continuar.",
      okText: "Confirmar",
      onOk() {
        editCategoryObj({
          id: id,
          name: values.name,
          color: values.color,
          updatedBy: user.sub
        }).then(async () => {
          await sleep(500);
          allCategoriesObj({
            companyId: company.companyId,
            unitId: company.unitId
          }).then((items) => {
            console.log({
              categories: items.message,
            });
            history.push("/formularios/categoria/todas");
          });
        });
      },
      cancelText: "Cancelar",
    });
  };

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <LayoutTopSide>
        <TopBar />
        <Layout>
          <DrawerSider />
          <Layout style={{ padding: "0 24px 24px", height: "100vh" }}>
            <PageHeader
              style={{ margin: "16px 0 5px 0" }}
              title="Editar categoria"
              subTitle="Você pode editar qualquer categoria do formulário."
              breadcrumb={
                <Breadcrumb>
                  {routes.map((a) =>
                    a.hasOwnProperty("href") ? (
                      <Breadcrumb.Item key={Math.random()}>
                        <a href onClick={() => history.push(a.href)}>
                          {a.breadcrumbName}
                        </a>
                      </Breadcrumb.Item>
                    ) : (
                      <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                    )
                  )}
                </Breadcrumb>
              }
            />
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: "white",
              }}
            >
              <Row gutter={16}>
                <Col span={16}>
                  <Form
                    {...formItemLayout}
                    form={form}
                    name="register"
                    initialValues={{
                      color: {
                        hsl: {
                          h: 198.67219917012446,
                          s: 0.9757085020242916,
                          l: 0.4843137254901961,
                          a: 1,
                        },
                        hex: "#03a9f4",
                        rgb: {
                          r: 3,
                          g: 169,
                          b: 244,
                          a: 1,
                        },
                        hsv: {
                          h: 198.67219917012446,
                          s: 0.9877049180327869,
                          v: 0.9568627450980393,
                          a: 1,
                        },
                        oldHue: 122.42424242424241,
                        source: "hex",
                      },
                    }}
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="name"
                      label="Nome"
                      tooltip="Ex: Agro"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor insira um nome para este formulário!",
                          whitespace: true,
                        },
                      ]}
                      normalize={(value) =>
                        value
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (y) => y.charAt(0).toUpperCase() + y.substring(1)
                          )
                          .join(" ")
                      }
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="color" label="Cor" tooltip="Ex: #000">
                      <Colorpicker
                        picker={"SwatchesPicker"}
                        blockStyles={{
                          width: "30px",
                          height: "30px",
                        }}
                        popup
                      />
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                      <Button type="primary" htmlType="submit">
                        Salvar alterações
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </LayoutTopSide>
    </Layout>
  );
}
