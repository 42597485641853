import { Alert, Collapse, Spin, Table, Checkbox, message } from 'antd';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { userApi } from '../../services/actions/users';
import { useSelector } from 'react-redux';

const { Panel } = Collapse;

export const PermissionConfig = ({ id, type, assignedPermissions = [] }) => {
    const PermissionCheckbox = React.memo(({ checked, onChange }) => {
        return <Checkbox checked={checked} onChange={onChange} />;
    });

    const { company } = useSelector((state) => state.user);
    const [dataAssignedPermissions, setDataAssignedPermissions] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [contexts, setContexts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modifiedPermissions, setModifiedPermissions] = useState({});

    const fetchPermissions = async () => {
        try {
            const response = await userApi.getPermission();
            const initialValues = response?.data || [];

            const groupedPermissions = initialValues.reduce((acc, item) => {
                if (!acc[item.entity]) {
                    acc[item.entity] = {
                        entity: item.entity,
                        name: item.entityName,
                        items: [],
                    };
                }
                acc[item.entity].items.push(item);
                return acc;
            }, {});

            setPermissions(Object.values(groupedPermissions));
        } catch {
            setError('Erro ao carregar as permissões');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPermissions();
    }, [id, type]);

    useEffect(() => {
        const fetchContexts = async () => {
            try {
                const response = await userApi.getContexts(company.unitId);
                setContexts(response?.data || []);
            } catch (ex) {
                setError(`Erro ao carregar os contextos: ${ex.message}`);
            }
        };

        fetchContexts();
    }, [company.unitId]);

    useEffect(() => {
        setDataAssignedPermissions(assignedPermissions);
    }, [assignedPermissions]);

    const contextsByEntity = useMemo(() => {
        return contexts.reduce((acc, context) => {
            if (!acc[context.entity_type]) acc[context.entity_type] = [];
            acc[context.entity_type].push(context);
            return acc;
        }, {});
    }, [contexts]);

    const handlePermissionChange = async (record, checked) => {
        try {
            const method =
                checked && type === 'group'
                    ? 'addPermissionToGroup'
                    : !checked && type === 'group'
                        ? 'removePermissionFromGroup'
                        : checked && type === 'role'
                            ? 'addPermissionToRole'
                            : !checked && type === 'role'
                                ? 'removePermissionFromRole'
                                : checked && type === 'user'
                                    ? 'addPermissionToUser'
                                    : 'removePermissionFromUser';

            const params =
                type !== 'user'
                    ? [record.slug, id, company.unitId, record.context ? record.context.id : null]
                    : [record.slug, null, id, company.unitId, record.context ? record.context.id : null];

            await userApi[method](...params);

            const key = record.context ? `${record.slug}-${record.context.id}` : record.slug;
            setModifiedPermissions((prev) => ({
                ...prev,
                [key]: checked,
            }));
        } catch (ex) {
            message.error(`Erro ao atualizar permissão! (${ex.message})`);
        }
    };

    const isPermissionChecked = useCallback(
        (data) => {
            const key = data.context ? `${data.slug}-${data.context.id}` : data.slug;
            if (key in modifiedPermissions) return modifiedPermissions[key];

            return data.isContextual
                ? !!dataAssignedPermissions.find(
                    (item) => item.type === data.slug && data.context.id === item.context.id
                )
                : !!dataAssignedPermissions.find((item) => item.type === data.slug);
        },
        [dataAssignedPermissions, modifiedPermissions]
    );

    const getColumnsContext = (contextualItems, context) => {
        const allChecked = contextualItems.every((item) =>
            isPermissionChecked({ ...item, context })
        );


        const permissionsToProcess = contextualItems.filter((item) =>
            allChecked
                ? isPermissionChecked({ ...item, context }) // Desmarcar se todos estão marcados
                : !isPermissionChecked({ ...item, context }) // Marcar se nem todos estão marcados
        );

        return [
            {
                title: 'Nome',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Descrição',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: (
                    <>
                        Permissão{' '}
                        <Checkbox
                            checked={allChecked}
                            onChange={(e) =>
                                Promise.all(
                                    permissionsToProcess.map((record) =>
                                        handlePermissionChange(
                                            { ...record, context },
                                            e.target.checked
                                        )
                                    )
                                )
                            }
                        />
                    </>
                ),
                dataIndex: 'permission',
                key: 'permission',
                render: (_, record) => (
                    <PermissionCheckbox
                        checked={isPermissionChecked(record)}
                        onChange={(e) => handlePermissionChange(record, e.target.checked)}
                    />
                ),
            },
        ];
    };


    const columnsNoContext = [
        {
            title: 'Nome',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Permissão',
            dataIndex: 'permission',
            key: 'permission',
            render: (_, record) => (
                <PermissionCheckbox
                    checked={isPermissionChecked(record)}
                    onChange={(e) => handlePermissionChange(record, e.target.checked)}
                />
            ),
        },
    ];
    if (loading) return <Spin tip="Carregando..." />;
    if (error) return <Alert message={error} type="error" showIcon />;

    const items = permissions.map((entity) => {
        const entityContexts = contextsByEntity[entity.entity] || [];
        const nonContextualItems = entity.items.filter((item) => !item.isContextual);
        const contextualItems = entity.items.filter((item) => item.isContextual);

        const panels = entityContexts.map((context) => (
            <Panel header={`${entity.name}: ${context.entity_name}`} key={context.id}>
                <Table
                    columns={getColumnsContext(contextualItems, context)}
                    dataSource={contextualItems.map((item) => ({
                        ...item,
                        context,
                    }))}
                    pagination={false}
                    rowKey="id"
                />
            </Panel>
        ));

        return (
            <React.Fragment key={entity.entity}>
                <Panel header={entity.name}>
                    {nonContextualItems.length > 0 && (
                        <>
                            <h4>Configurações Gerais</h4>
                            <Table
                                columns={columnsNoContext}
                                dataSource={nonContextualItems}
                                pagination={false}
                                rowKey="id"
                            />
                        </>
                    )}
                    {panels.length > 0 && <Collapse>{panels}</Collapse>}
                </Panel>
            </React.Fragment>
        );
    });

    return <Collapse>{items}</Collapse>;
};
