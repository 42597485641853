
import { io } from 'socket.io-client';
import {getUser} from "../utils/user-manager";

const socketInstance = async () => {
    const extraHeaders = {};
    try {
        const user = await getUser();
        const token = user?.access_token;
        if (token) {
            extraHeaders.Authorization = `Bearer ${token}`;
        }
    } catch (error) {
        console.error(error);
    }
    return io(window.location.origin, { extraHeaders });
};

export default socketInstance;
