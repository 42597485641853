import getApiUrl from "services/getApiUrl";

export  const notificationAPI = {
    getAll: async (params) => getApiUrl.get('/notifications',{params}),

    getOne: async (id) =>  getApiUrl.get(`/notifications/${id}`),

    deleteOne: async (id) =>  getApiUrl.delete(`/notifications/${id}`),

    markAsRead: async (id) => getApiUrl.get(`/notifications/${id}/read`),

    markAsUnread: async (id) => getApiUrl.get(`/notifications/${id}/unread`),

    markAllAsRead: async (user_id) => getApiUrl.get(`/notifications/read-all/${user_id}`),

};


