import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const ForbiddenPage = () => {
    return (
        <div style={{ padding: "50px" }}>
            <Result
                status="403"
                title="403"
                subTitle="Desculpe, você não tem permissão para acessar esta página."
                extra={
                    <Button type="primary">
                        <Link to="/">Voltar para a página inicial</Link>
                    </Button>
                }
            />
        </div>
    );
};

export default ForbiddenPage;
