export const getGlobalPermissions = (permissions) => {
    return permissions.filter(permission => permission.isGlobal);
};
export const getUnitAccessPermissions = (permissions, unitId) => {
    return permissions.filter(permission => permission.unit?.id === unitId);
};

export const checkPermission = (permissions, permission, unitId, entityType = null, entityId = null, entityName = null) => {
    const permissionFromUser = [...getUnitAccessPermissions(permissions, unitId),...getGlobalPermissions(permissions)];
    if ((entityId || entityName) && entityType) {
        return permissionFromUser.some(item => {
                return item.type === permission &&
                    item.context && item.context.entity_type === entityType &&
                    (item.context.entity_id === entityId || item.context.entity_name === entityName);
            }
        );
    } else {
        return permissionFromUser.some(item => item.type === permission);
    }
};

export const checkPermissionsFromUser = (permissions, permissionsFromUser, unitId, entityType = null, entityId = null, entityName = null) => {
    return permissionsFromUser.some(permission =>
        checkPermission(permissions, permission, unitId, entityType, entityId, entityName)
    );
};

export const hasAnyPermission = (permissions, permissionsToCheck, unitId) => {
    const permissionFromUser = [
        ...getUnitAccessPermissions(permissions, unitId),
        ...getGlobalPermissions(permissions)
    ];

    return permissionsToCheck.some(permissionToCheck =>
        permissionFromUser.some(item => item.type === permissionToCheck)
    );
};

export const checkPermissions = (permissionsFromUser, company, permissions, children) => {
    if (!permissionsFromUser || !permissions || !company?.unitId) {
        return null;
    }

    return hasAnyPermission(permissionsFromUser, permissions, company.unitId) ? children : null;
};

export const getAllPermissionsByUnit = (permissions, unitId, permissionsToFilter  = []) =>{
    const unitPermissions = getUnitAccessPermissions(permissions, unitId);
    const globalPermissions = getGlobalPermissions(permissions);
    return [
        ...unitPermissions,
        ...globalPermissions
    ].filter(item => {
        if(permissionsToFilter.length === 0){
            return true;
        }else{
            return permissionsToFilter.includes(item.type)
        }
    });
}
