import getApiUrl from "../../services/getApiUrl";

/**
 * Pesquisa por automações
 * @returns
 * @param data
 */
export const getAutomations = async (data = {}) => {
    return await getApiUrl.get(`/automations`, {
        params:data
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

export const createAutomation = async (data = {}) => {
    return await getApiUrl.post(`/automations`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

export const getAutomation = async (id) => {
    return await getApiUrl.get(`/automations/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};
