import React, { useEffect, useState } from 'react';
import { Card, Table, Button, Space, Typography, Spin, Alert, Form, Input, Modal, message } from 'antd';
import { userApi } from "../../../services/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesFromUser } from "../../../auth/actions/userActions";

const { Title } = Typography;

const Unit = () => {
    const { company } = useSelector(state => state.user);
    const userId = useSelector(state => state.session.user.sub);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await userApi.getCompanyDetails(company.companyId);
            if (response.status === 200 || response.status === 201) {
                setCompanyData(response.data);
            }
        } catch (ex) {
            setError(ex.response?.data?.message || "Erro ao recuperar dados!");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [company]);

    const handleSaveUnit = async () => {
        try {
            const values = await form.validateFields();
            if (selectedUnit) {
                // Editar unidade existente
                await userApi.updateUnit(selectedUnit.id, values);
                message.success("Unidade atualizada com sucesso!");
            } else {
                // Adicionar nova unidade
                await userApi.createUnit({ ...values, companyId: companyData.id });
                message.success("Unidade criada com sucesso!");
            }
            fetchData();
            setIsModalVisible(false);
            form.resetFields();
            setSelectedUnit(null);
            dispatch(getCompaniesFromUser(userId));
        } catch (error) {
            message.error("Erro ao salvar unidade!");
        }
    };

    const confirmRemove = (record) => {
        Modal.confirm({
            title: `Tem certeza que deseja desativar a unidade "${record.name}"?`,
            onOk: () => handleRemoveUnit(record.id),
            okText: "Sim",
            cancelText: "Cancelar",
        });
    };

    const handleRemoveUnit = async (unitId) => {
        try {
            await userApi.deleteUnit(unitId);
            message.success("Unidade removida com sucesso!");
            fetchData();
        } catch (error) {
            message.error("Erro ao remover unidade!");
        }
    };

    const handleRestoreUnit = async (unitId) => {
        try {
            await userApi.restoreUnit(unitId);
            message.success("Unidade restaurada com sucesso!");
            fetchData();
        } catch (error) {
            message.error("Erro ao restaurar unidade!");
        }
    };

    const openEditModal = (record) => {
        setSelectedUnit(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const colunas = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Nome', dataIndex: 'name', key: 'name' },
        { title: 'Descrição', dataIndex: 'description', key: 'description' },
        {
            title: 'Atualizado em',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: 'Desativado em',
            dataIndex: 'deletedAt',
            key: 'deletedAt',
            render: (text) => text ? new Date(text).toLocaleString() : "-",
        },
        {
            title: 'Ações',
            key: 'acoes',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => openEditModal(record)}>Editar</Button>
                    {record.deletedAt ? (
                        <Button type="link" onClick={() => handleRestoreUnit(record.id)}>
                            Restaurar
                        </Button>
                    ) : (
                        <Button type="link" danger onClick={() => confirmRemove(record)}>
                            Excluir
                        </Button>
                    )}
                </Space>
            ),
        },
    ];

    if (loading) return <Spin tip="Carregando..." />;
    if (error) return <Alert message={error} type="error" showIcon />;

    return (
        <div style={{ padding: '24px' }}>
            <Card title={`Dados da Empresa - #${companyData?.id}`} bordered={false} style={{ marginBottom: '24px' }}>
                <p><strong>Nome:</strong> {companyData?.name}</p>
            </Card>

            <Card
                title={<Title level={4}>Gerenciar Unidades</Title>}
                extra={<Button type="primary" onClick={() => setIsModalVisible(true)}>Adicionar Unidade</Button>}
            >
                <Table dataSource={companyData?.units} columns={colunas} pagination={{ pageSize: 5 }} />
            </Card>

            <Modal
                title={selectedUnit ? "Editar Unidade" : "Adicionar Unidade"}
                visible={isModalVisible}
                onOk={handleSaveUnit}
                onCancel={() => {
                    setIsModalVisible(false);
                    form.resetFields();
                    setSelectedUnit(null);
                }}
                okText={selectedUnit ? "Atualizar" : "Adicionar"}
                cancelText="Cancelar"
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Nome da Unidade"
                        name="name"
                        rules={[{ required: true, message: "Por favor, insira o nome da unidade!" }]}
                    >
                        <Input placeholder="Nome da unidade" />
                    </Form.Item>
                    <Form.Item
                        label="Descrição"
                        name="description"
                        rules={[{ required: true, message: "Por favor, insira a descrição!" }]}
                    >
                        <Input.TextArea rows={4} placeholder="Descrição da unidade" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Unit;
