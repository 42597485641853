import React, {useState, useEffect} from "react";
import {Buffer} from "buffer";
import {
    Layout,
    PageHeader,
    Breadcrumb,
    Row,
    Col,
    Form,
    Input,
    Select,
    Button,
    Upload,
    Tabs, Space, Modal, ConfigProvider, message,
} from "antd";
import {useLocation, useHistory, useParams} from "react-router-dom";
import InputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";
import {useSelector} from "react-redux";

/**
 * Components
 */
import TopBar from "components/NavBar/TopBar";
import DrawerSider from "components/NavBar/DrawerSider";
import {LayoutTopSide} from "components/NavBar/styles";
import {ProCard, ProTable} from "@ant-design/pro-components";
import Loader from "react-loader-spinner";
import {userApi} from "../../../services/actions/users";
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import ptBRIntl from "antd/lib/locale/pt_BR";
import {Groups} from "./Groups";
import {Roles} from "./Roles";
import {Permissions} from "./Permissions";
import {Unit} from "./Unit";
import {checkPermissions} from "../../../utils/permission";
import PermissionWrapperComponent from "../../../components/PermissionWrapperComponent";

const {Content} = Layout;
const {confirm} = Modal;
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export default function EditUser() {
    const fetchUser = async () => {
        try {
            setLoading(true);
            const response = await userApi.getById(id,company.unitId);
            if (response.status === 200) {
                setUser(response.data);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    const [formUser] = Form.useForm();
    const [formPassword] = Form.useForm();
    const history = useHistory();


    const permissionsFromUser = useSelector((state) => state.user.permissions);
    const company = useSelector((state) => state.user.company);

    const routes = [
        {
            breadcrumbName: "Usuarios e Permissões",
            href: "/usuarios-e-permissoes",
        },
        {
            breadcrumbName: "editar",
        },
    ];

    const handleSubmitUpdateDataUser = async () => {
        confirm({
            title: "Deseja atualizar as informações desses usuario?",
            icon: <ExclamationCircleOutlined/>,
            content: "Veja se as informações está corretas",
            cancelText: "Cancelar",
            okText: "Sim",
            onOk: async () => {
                try {
                    setLoading(true)
                    const values = formUser.getFieldsValue();
                    const update = await userApi.update(id, {...values, unitId: company.unitId});
                } catch (e) {
                    setError(e.message);
                } finally {
                    fetchUser()
                    setLoading(false)
                }
            }
        });
    }

    const handleRequestNewPassword = async () => {
        try {
            setLoading(true);
            await userApi.requestNewPassword(id,company.unitId);
            message.info("Notificação de troca de senha foi enviada!");
        } catch (e) {
            setError(e.message);
            message.error("Erro ao enviar notificação de troca de senha!");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitUpdateDataPassword = async () => {
        confirm({
            title: "Deseja atualizar a senha desses usuario?",
            icon: <ExclamationCircleOutlined/>,
            cancelText: "Cancelar",
            okText: "Sim",
            onOk: async () => {
                try {
                    setLoading(true)
                    const {confirmPassword, password} = formPassword.getFieldsValue();
                    await userApi.updatePassword(id, password, confirmPassword, company.unitId);
                    message.info("A senha foi atualizado com sucesso");
                } catch (e) {
                    setError(e.message);
                } finally {
                    setLoading(false)
                    formPassword.resetFields();
                }
            }
        });
    }

    const handleDeleteUser = async () => {
        confirm({
            title: `Deseja atualizar a excluir este usuário ${user.firstName} ?`,
            icon: <ExclamationCircleOutlined/>,
            cancelText: "Cancelar",
            okText: "Sim",
            onOk: async () => {
                try {
                    setLoading(true)
                    await userApi.delete(id,company.unitId);
                    message.info("Usuário excluido com sucesso!");
                    history.push("/usuarios-e-permissoes")
                } catch (e) {
                    setError(e.message);
                } finally {
                    setLoading(false)
                }
            }
        });
    }

    const renderUnitTab = checkPermissions(
        permissionsFromUser,
        company,
        ['MANAGE_COMPANIES'],
        <Tabs.TabPane tab="Unidades" key="5">
            <Unit user={user}/>
        </Tabs.TabPane>
    );

    const renderPermisisonTab = checkPermissions(
        permissionsFromUser,
        company,
        ['MANAGE_ROLES'],
        <React.Fragment>
            <Tabs.TabPane tab="Grupos" key="2">
                <Groups user={user}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Papeis" key="3">
                <Roles user={user}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Permissões" key="4">
                <Permissions user={user}/>
            </Tabs.TabPane>
        </React.Fragment>
    );

    return (
        <Layout
            style={{
                height: "100vh",
            }}
        >
            <LayoutTopSide>
                <TopBar/>
                <Layout>
                    <DrawerSider/>
                    <Layout style={{padding: "0 24px 24px", height: "100vh"}}>
                        <PageHeader
                            style={{margin: "16px 0 5px 0"}}
                            title="Editar usuário"
                            subTitle="Você pode editar um perfil de usuário para cada colaborador com permissões especiais."
                            breadcrumb={
                                <Breadcrumb>
                                    {routes.map((a) =>
                                        a.hasOwnProperty("href") ? (
                                            <Breadcrumb.Item key={Math.random()}>
                                                <a href onClick={() => history.push(a.href)}>
                                                    {a.breadcrumbName}
                                                </a>
                                            </Breadcrumb.Item>
                                        ) : (
                                            <Breadcrumb.Item>{a.breadcrumbName}</Breadcrumb.Item>
                                        )
                                    )}
                                </Breadcrumb>
                            }
                        />
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                                background: "white",
                            }}
                        >
                            {loading ? <Loader type={'Circles'}/> : user ?
                                <Tabs tabPosition={'left'} defaultActiveKey="1">
                                    <Tabs.TabPane tab="Informações e Segurança" key="1">
                                        <ProCard colSpan={24} extra={
                                            <PermissionWrapperComponent  permissions={['DELETE_USER']}>
                                                <Button icon={<DeleteOutlined/>} onClick={() => handleDeleteUser()} type={"danger"}>Excluir usuário</Button>
                                            </PermissionWrapperComponent>
                                        }>
                                            <PermissionWrapperComponent permissions={['UPDATE_USER']}>
                                                <ProCard colSpan={12} title={"Dados pessoais"}>
                                                    <Form
                                                        onFinish={handleSubmitUpdateDataUser}
                                                        form={formUser}
                                                        scrollToFirstError
                                                        initialValues={{
                                                            firstName: user.firstName,
                                                            lastName: user.lastName,
                                                            username: user.username,
                                                            email: user.email,
                                                        }}
                                                    >
                                                        <Form.Item
                                                            style={{
                                                                marginTop: "-2%",
                                                                marginBottom: "2%",
                                                                marginLeft: "50%",
                                                                borderRadius: 90000,
                                                            }}
                                                            name="profilePic"

                                                        >
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="firstName"
                                                            label="Nome"
                                                            rules={[{
                                                                required: true,
                                                                message: "Por favor insira o nome!",
                                                                whitespace: false
                                                            }]}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="lastName"
                                                            label="Sobrenome"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Por favor insira o sobrenome!",
                                                                    whitespace: false,
                                                                },
                                                            ]}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="username"
                                                            label="Usuário"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Por favor insira o nome de acesso do usuário!",
                                                                    whitespace: false,
                                                                },
                                                            ]}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="email"
                                                            label="E-mail"
                                                            rules={[
                                                                {
                                                                    type: "email",
                                                                    message:
                                                                        "E-mail que você digitou não é um e-mail válido!",
                                                                },
                                                                {
                                                                    required: true,
                                                                    message: "Por favor insira o e-mail!",
                                                                },
                                                            ]}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                        <Form.Item>
                                                            <Button type="primary" htmlType="submit">
                                                                Salvar Alterações
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </ProCard>
                                            </PermissionWrapperComponent>
                                            <ProCard colSpan={12} title={"Notificar troca e recuperação de senha"}>
                                                <PermissionWrapperComponent permissions={["RESET_PASSWORD"]}>
                                                    <Form
                                                        form={formPassword}
                                                        scrollToFirstError
                                                        onFinish={handleSubmitUpdateDataPassword}
                                                    >
                                                        <Form.Item
                                                            name="password"
                                                            label="Senha"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Por favor insira uma senha!",
                                                                },
                                                                {
                                                                    min: 8,
                                                                    message: "A senha deve ter no mínimo 8 caracteres!",
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <Input.Password placeholder="*********"/>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="confirmPassword"
                                                            label="Confirme"
                                                            dependencies={["password"]}
                                                            hasFeedback
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Por favor, confirme a senha!",
                                                                },
                                                                ({getFieldValue}) => ({
                                                                    validator(_, value) {
                                                                        if (!value || getFieldValue("password") === value) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(
                                                                            new Error("As duas senhas que você digitou não coincidem!")
                                                                        );
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Input.Password placeholder="*********"/>
                                                        </Form.Item>

                                                        <Form.Item>
                                                            <PermissionWrapperComponent permissions={["RESET_PASSWORD"]}>
                                                            <Button type="primary" htmlType="submit">
                                                                Salvar Senha
                                                            </Button>
                                                            </PermissionWrapperComponent>
                                                            &nbsp;&nbsp;
                                                            <PermissionWrapperComponent
                                                                permissions={['REQUEST_PASSWORD_CHANGE']}>
                                                                <Button type="default"
                                                                        onClick={handleRequestNewPassword}>
                                                                    Enviar notificação de atualização de senha
                                                                </Button>
                                                            </PermissionWrapperComponent>
                                                        </Form.Item>
                                                    </Form>
                                                </PermissionWrapperComponent>

                                            </ProCard>
                                        </ProCard>
                                    </Tabs.TabPane>
                                    {renderPermisisonTab}
                                    {renderUnitTab}
                                </Tabs> : <p>Erro ao recuperar informações do usuário</p>}
                        </Content>
                    </Layout>
                </Layout>
            </LayoutTopSide>
        </Layout>
    );
}
