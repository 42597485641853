import "react-redux";

import getApiUrl from "services/getApiUrl";
import {
  GET_URL_AVATAR,
  UPDATE_AVATAR,
  ERROR_ON_UPDATE_AVATAR,
  GET_COMPANIES_DATA, ERROR_TO_GET_COMPANIES_DATA, CHANGE_COMPANY_SELECTION, GET_USER_PERMISSIONS, READY_APP
} from "../../ducks/user";
import {userApi} from "../../services/actions/users";
import {message} from "antd";


/**
 * Lista de todos usuários.
 * @returns
 */
export const getUsers = async (name = null, unitId) => {
  try {
    // Construindo os parâmetros da URL
    const params = new URLSearchParams();
    if (name) params.append('firstName', name);
    params.append('unitId', unitId);
    params.append('perPage', 10000);

    const response = await getApiUrl.get(`/auth/user?${params.toString()}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    throw error;
  }
};

export const syncReportUser = async (userId) => {
  try {
    return  getApiUrl.get(`/reports/config/user/${userId}`, {
      headers: { "Content-Type": "application/json" },
    });
  } catch (error) {
    throw error;
  }
};

export const getUrlAvatar = (userId) => async (dispatch) => {
  const data = await userApi.getUrlImageFromUser(userId);
    dispatch({
      type: GET_URL_AVATAR,
      payload: data,
    });
};

export const updateUrlAvatar = (userId,file) => async (dispatch) => {
  try {
    const response = await userApi.updateAvatarImageFromUser(userId,file);
    if (response.status === 200) {
      const data = await userApi.getUrlImageFromUser(userId);
      dispatch({
        type: UPDATE_AVATAR,
        payload:  data
      });
      message.success("Foto de perfil atualizada com sucesso!");
    }else{
      throw new Error(response.data);
    }
  } catch (error) {
    message.error("Falha ao atualizar foto de perfil.");
    dispatch({
      type: ERROR_ON_UPDATE_AVATAR,
      payload:  {
        error: error.response.data,
      },
    })
  }
};

export const getCompaniesFromUser = (userId) => async (dispatch) => {
  if (!userId) return;

  try {
    const response = await userApi.getCompaniesFromUser(userId);

    if (response.status === 200) {
      const companies = response.data;
      dispatch({ type: GET_COMPANIES_DATA, payload: companies });

      const savedCompanySelection = getLocalCompanySelection();

      const validSelection =
          savedCompanySelection &&
          companies.find(
              (item) =>
                  item.companyId === savedCompanySelection.companyId &&
                  item.unitId === savedCompanySelection.unitId
          );

      dispatch(changeCompanySelection(validSelection || companies[0]));
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    console.error("Erro ao obter empresas do usuário:", error);

    dispatch({
      type: ERROR_TO_GET_COMPANIES_DATA,
      payload: {
        error: error.response?.data || error.message,
      },
    });
  }
};


export const getPermissionFromUser = (userId) => async (dispatch) => {
  if (!userId) return;
  const response = await userApi.getAllPermissionsByUser(userId);
  if (response.status === 200) {
    dispatch({
      type: GET_USER_PERMISSIONS,
      payload: response.data
    });
  }
}

export const initializeApp = (userId) => async (dispatch) => {
  if (!userId) return;

  try {
    await Promise.all([
      dispatch(getCompaniesFromUser(userId)),
      dispatch(getPermissionFromUser(userId)),
    ]);

    dispatch({ type: READY_APP });
  } catch (error) {
    console.error('Erro ao inicializar o aplicativo:', error);
  }
};

export const changeCompanySelection = (company, history = null) => async (dispatch) =>{
  localStorage.setItem('company_selection', JSON.stringify(company));
  dispatch({
    type: CHANGE_COMPANY_SELECTION,
    payload: company,
  });
  if(history){
    history.push("/dashboard/resumo")
  }
}

export const getLocalCompanySelection = () => {
  try {
    const data = localStorage.getItem('company_selection');
    if (!data) return null;
    const parsedData = JSON.parse(data);
    if (parsedData && typeof parsedData === 'object') {
      return parsedData;
    }
    return null;
  } catch (ex) {
    return null;
  }
};



